<template>
  <div>
    <table class="order-page__table store-table">
      <thead class="order-page__thead">
        <tr>
          <th class="order-page__checkbx">
            <input type="checkbox" id="all" v-model="allInputs" @click="setSelected" />
            <label for="all"></label>
          </th>
          <th>№</th>
          <th>Бренд</th>
          <th :class="['articul-th', { active: orderBy.vendor_code }]" @click="sortBy">Артикул</th>
          <th class="photo-td"></th>
          <th class="desc">Описание</th>
          <th class="td-center">Кол-во</th>
          <th class="td-center">Бронь</th>
          <th class="td-center">В заказах</th>
          <th class="td-center">Цена, BYN</th>
          <th class="td-center">Цена общ., BYN</th>
          <th>
            <span style="opacity: 0" class="order-page__btn order-page__move">
              <img width="14" height="14" src="/images/share-icon.svg" alt="move" />
            </span>
            <span class="order-page__btn order-page__delete" @click="remove()">
              <img src="/images/delete-table.svg" alt="del" />
            </span>
          </th>
        </tr>
      </thead>
      <tbody class="order-page__tbody">
        <template v-if="items.warehouse_data">
          <tr class="order-page__tr" v-for="(item, name, index) in items" :key="item.id">
            <template v-if="name !== 'warehouse_data'">
              <td class="order-page__checkbx">
                <input type="checkbox" :id="item.id" :checked="allInputs" :value="item.id" v-model="selected" />
                <label :for="item.id"></label>
              </td>
              <td>{{ index + 1 }}</td>
              <td>{{ item.supplier_title }}</td>
              <td>
                <a :href="`${CLICKHOUSE_WEBSITE_URL}/product/${item.alias}`" target="_blank">
                  {{ item.vendor_code }}
                </a>
              </td>
              <td class="td-photo">
                <img :src="item.model.photo" :alt="item.name" v-if="item.model" />
              </td>
              <td>
                {{ item.name }} <br />
                {{ item.type }}
              </td>
              <td class="with-input">
                <input
                  type="number"
                  :value="item.quantity"
                  @focus="focusInput"
                  @blur="focusNone"
                  @change="changeQuantity($event, item.id)"
                  @keypress.enter="changeQuantity($event, item.id)"
                />
              </td>
              <td>
                <template v-if="item.orders_reserved_count">
                  {{ item.orders_reserved_count.orders_reserved_count || 0 }}
                </template>
                <template v-else>
                  <p>0</p>
                </template>
              </td>
              <td :class="['with-link']">
                <template v-if="item.orders_reserved_count">
                  <a :href="`/flights?search=${item.vendor_code}`" v-if="item.orders_count" target="_blank">
                    {{ item.orders_count.orders_count }}
                  </a>
                  <p v-else>{{ item.orders_count.orders_count || 0 }}</p>
                </template>
                <template v-else>
                  <p>0</p>
                </template>
              </td>
              <td class="with-input">
                <input
                  type="number"
                  :value="item.price"
                  @focus="focusInput"
                  @blur="focusNone"
                  @change="changePrice($event, item.id)"
                  @keypress.enter="changePrice($event, item.id)"
                />
              </td>
              <td>{{ item.overall_price || 0 }}</td>
              <td>
                <span class="order-page__btn order-page__move">
                  <a :href="item.model.link" target="_blank" v-if="item.model">
                    <img width="14" height="14" src="/images/share-icon.svg" alt="move" />
                  </a>
                  <a href="#" v-if="!item.model">
                    <img width="14" height="14" src="/images/share-icon.svg" alt="move" />
                  </a>
                </span>
                <span class="order-page__btn order-page__delete" @click="remove(item.id)">
                  <img src="/images/delete-table.svg" alt="del" />
                </span>
              </td>
            </template>
          </tr>
          <tr class="order-page__tbody-bottom">
            <td>
              <button class="create-btn" @click="openModal">
                <img src="/images/plus-white.svg" alt="plus" />
              </button>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td class="border-none"></td>
            <td class="total">Итого:</td>
            <td>{{ splitPrice(items.warehouse_data.quantity_sum) || 0 }}</td>
            <td>{{ splitPrice(items.warehouse_data.orders_reserved_count_sum) }}</td>
            <td>{{ splitPrice(items.warehouse_data.orders_count_sum) || 0 }}</td>
            <td>{{ splitPrice(items.warehouse_data.price_sum) || 0 }}</td>
            <td>{{ splitPrice(items.warehouse_data.overall_price_sum) || 0 }}</td>
            <td></td>
          </tr>
        </template>
      </tbody>
    </table>
    <Modal v-if="visible" @closePopup="closeModal">
      <CreateItem type="warehouse" @closeModal="closeModal" />
    </Modal>

    <Modal type="remove" v-if="showRemoveModal" @closePopup="closeModal">
      <RemoveModal
        type="warehouse"
        :removeId="removeId"
        :selected="selected"
        @closeRemoveModal="closeModal"
        @clearSelected="clearSelected"
      />
    </Modal>

    <div class="blocker" v-if="visible || showRemoveModal" @click="closeModal"></div>
  </div>
</template>

<script>
import { CLICKHOUSE_WEBSITE_URL } from '@/constants.js'
import Modal from '@/components/tools/Modal.vue'
import CreateItem from '@/components/tools/modals/CreateItem.vue'
import RemoveModal from '@/components/tools/modals/RemoveModal.vue'

export default {
  components: {
    Modal,
    CreateItem,
    RemoveModal,
  },

  props: {
    items: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    orderBy() {
      return this.$store.getters['orderBy']
    },
  },

  data: () => ({
    CLICKHOUSE_WEBSITE_URL,
    showRemoveModal: false,
    visible: false,
    allInputs: false,
    removeId: 0,
    selected: [],
  }),

  methods: {
    changeQuantity(e, id) {
      const quantity = e.target.value
      this.$store.dispatch('patchProduct', {
        id,
        params: { quantity },
      })
      e.target.blur()
    },

    sortBy() {
      this.$store.dispatch('sortBy', 'vendor_code')
    },

    changePrice(e, id) {
      const price = parseInt(e.target.value)
      this.$store.dispatch('patchProduct', {
        id,
        params: { price },
      })
      e.target.blur()
    },

    changeOrdersCount(e, id) {
      const quantity = e.target.value
      this.$store.dispatch('patchProduct', {
        id,
        params: { quantity },
      })
      e.target.blur()
    },

    focusInput(e) {
      const parent = e.target.offsetParent
      parent.classList.add('focus-on')
    },

    focusNone(e) {
      const parent = e.target.offsetParent
      parent.classList.remove('focus-on')
    },

    remove(id) {
      if (id) {
        this.showRemoveModal = true
        this.removeId = parseInt(id)
      }
      if (this.selected.length) {
        this.showRemoveModal = true
        this.removeId = 0
      }
    },

    clearSelected() {
      this.selected = []
      this.allInputs = false
    },

    setSelected() {
      if (!this.allInputs) {
        const products = Object.entries(this.items)
        products.forEach((product) => this.selected.push(product[1].id))
      } else {
        this.selected = []
      }
    },

    openModal() {
      this.visible = true
    },

    closeModal() {
      this.visible = false
      this.showRemoveModal = false
      this.$store.dispatch('clearVendorProduct')
    },

    splitPrice(x) {
      if (typeof x === 'string') {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return x
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.with-input {
  input {
    box-sizing: border-box;
    width: 60px;
    text-align: center;
  }
}

.store-table {
  .photo-td {
    min-width: 50px;
  }
  .border-none {
    border: 0;
  }

  .order-page__thead {
    th {
      text-align: left;
      &:first-child,
      &:nth-child(2),
      &:last-child {
        text-align: center;
      }
    }
  }

  .order-page__tr {
    td {
      &:first-child,
      &:nth-child(2) {
        text-align: center;
      }
    }
  }

  .order-page__tbody-bottom {
    td {
      &:first-child {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          margin-left: -5px;
        }
      }
    }
  }
}
td {
  text-align: center;
  &:nth-child(5) {
    text-align: left;
  }
  &:hover {
    text-decoration: none;
  }
  &.focus-on {
    position: relative;
    transition: all 0.2s ease-in-out;
    background-color: #fff;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid #0984e3;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.2s ease-in-out;
      cursor: text;
    }
  }
}

a {
  text-decoration: none;
}
.total {
  text-align: right !important;
}

.grayer {
  color: #828282;
}

.bluen {
  color: #0984e3;
}
.td-photo {
  padding: 0;
  width: 50px;
  height: 50px;
  img {
    object-fit: contain;
    width: 50px;
    height: 50px;
  }
}
.td-center {
  text-align: center !important;
}
.with-link {
  a {
    display: block;
    text-decoration: none;
    color: #0984e3;
  }
  p {
    color: #828282;
  }
}

.articul-th {
  position: relative;
  cursor: pointer;
  min-width: 100px;

  &.active {
    &::after {
      background-color: #0984e3;
    }
  }

  &::after {
    content: '';
    mask-image: url('/images/warehouse-sort.svg');
    mask-repeat: no-repeat;
    mask-size: contain;
    background-color: #000;
    position: absolute;
    width: 16px;
    height: 16px;
    right: 12px;
  }
}
</style>
