<template>
  <v-container fluid>
    <v-dialog v-model="dialogEdit" max-width="600px">
      <v-card>
        <v-card-title class="headline" primary-title>Изменить параметры акции</v-card-title>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field v-model="title" label="Название акции" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field v-model="show_order" type="number" label="Порядок" required></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" outline @click="closeEditDialog">Закрыть</v-btn>
          <v-btn color="info" @click="saveActionTitleAndOrder" :loading="loading">Сохранить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="600px">
      <v-card>
        <v-card-title class="headline" primary-title>Удалить акцию "{{ title }}"?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="info" outline @click="closeDeleteDialog">Закрыть</v-btn>
          <v-btn color="info" @click="deleteAction" :loading="loading">Удалить</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-layout>
      <v-dialog v-model="dialogNew" max-width="600px">
        <template v-slot:activator="{ on }">
          <v-btn outline color="success" class="ml-0 mb-3" v-on="on">Добавить акцию</v-btn>
        </template>
        <v-card>
          <v-card-title class="headline" primary-title>Создать новую акцию</v-card-title>
          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field label="Название" v-model="title" required></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="info" outline @click="newActionClose">Закрыть</v-btn>
            <v-btn color="info" @click="newAction" :loading="loading">Создать</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-layout>
    <v-layout>
      <v-expansion-panel>
        <v-expansion-panel-content v-for="(action, i) of actions" :key="i">
          <template v-slot:header>
            <div class="action-header" @click="openPanel(i)">
              <div class="action-title ml-3">{{ action.title }}</div>
              <div @click.stop>
                <v-layout row mb-0>
                  <v-flex>
                    <span class="grey--text mr-1">№</span>
                    <span>{{ action.show_order }}</span>
                    <v-btn flat icon :color="action.is_active ? 'green' : 'grey'" @click="toggleActionStatus(action, i)"
                      ><v-icon>circle</v-icon></v-btn
                    >
                    <v-btn flat icon color="blue" @click="openEditDialog(action, i)"><v-icon>edit</v-icon></v-btn>
                    <v-btn flat icon color="red" @click="openDeleteDialog(action, i)"><v-icon>delete</v-icon></v-btn>
                  </v-flex>
                </v-layout>
              </div>
            </div>
          </template>
          <v-card v-if="i === currentActionIndex">
            <v-card-text class="pb-2">
              <v-container fluid class="pa-0">
                <v-layout row>
                  <v-flex xs6 class="mr-3">
                    <div class="box">
                      <v-layout column>
                        <v-flex>
                          <strong>Баннер Десктоп</strong>
                        </v-flex>
                        <v-flex>
                          <v-btn
                            v-if="!imgDesktopSrc[i]"
                            outline
                            small
                            color="blue"
                            class="ml-0"
                            @click="openDesktopImageDialog(i)"
                            >Загрузить</v-btn
                          >
                          <v-btn
                            v-if="imgDesktopSrc[i]"
                            outline
                            small
                            color="red"
                            class="ml-0"
                            @click="deleteDesktopImage(i)"
                            >Удалить</v-btn
                          >
                          <input
                            v-show="false"
                            :ref="`desktop-img-${i}`"
                            type="file"
                            @change="setDesktopImage(i, $event)"
                          />
                        </v-flex>
                        <v-flex>
                          <v-img
                            v-if="imgDesktopSrc[i]"
                            :src="imgDesktopSrc[i]"
                            height="200"
                            class="grey darken-4"
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-flex>
                  <v-flex xs6>
                    <div class="box">
                      <v-layout column>
                        <v-flex>
                          <strong>Баннер Мобильный</strong>
                        </v-flex>
                        <v-flex>
                          <v-btn
                            v-if="!imgMobileSrc[i]"
                            outline
                            small
                            color="blue"
                            class="ml-0"
                            @click="openMobileImageDialog(i)"
                            >Загрузить</v-btn
                          >
                          <v-btn
                            v-if="imgMobileSrc[i]"
                            outline
                            small
                            color="red"
                            class="ml-0"
                            @click="deleteMobileImage(i)"
                            >Удалить</v-btn
                          >
                          <input
                            v-show="false"
                            :ref="`mobile-img-${i}`"
                            type="file"
                            @change="setMobileImage(i, $event)"
                          />
                        </v-flex>
                        <v-flex>
                          <v-img
                            v-if="imgMobileSrc[i]"
                            :src="imgMobileSrc[i]"
                            height="200"
                            class="grey darken-4"
                          ></v-img>
                        </v-flex>
                      </v-layout>
                    </div>
                  </v-flex>
                </v-layout>
              </v-container>
              <div class="title mb-3 mt-5">Настройки</div>
              <div class="box mb-5">
                <v-text-field v-model="action.seo_title" label="Title"></v-text-field>
                <v-text-field v-model="action.seo_breadcrumbs" label="Хлебные крошки"></v-text-field>
                <v-text-field v-model="action.seo_keywords" label="Keywords"></v-text-field>
                <v-text-field v-model="action.seo_description" label="Description"></v-text-field>
              </div>
              <div v-for="(content, i) of action.contents" :key="content.id">
                <template v-if="content.type === 1">
                  <div class="title mb-3">Текст</div>
                  <vue-editor
                    id="`editor-${action.id}-${content.id}`"
                    v-model="content.text"
                    :editor-toolbar="customToolbar"
                  ></vue-editor>
                </template>
                <template v-if="content.type === 3">
                  <div class="title mb-3">Промокод</div>
                  <div class="box">
                    <v-container fluid pa-0>
                      <v-layout row>
                        <v-flex xs4>
                          <v-select
                            v-model="content.promocode"
                            :items="promocodes"
                            label="Промокод"
                            item-text="code"
                            item-value="id"
                            return-object
                            required
                          ></v-select>
                        </v-flex>
                      </v-layout>
                    </v-container>
                    <template v-if="content.promocode">
                      <div v-if="content.promocode.discount">Скидка: {{ content.promocode.discount }}</div>
                      <div v-if="content.promocode.valid_from && content.promocode.valid_to">
                        Действует: с {{ content.promocode.valid_from }} по {{ content.promocode.valid_to }}
                      </div>
                      <div v-if="content.promocode.usage_limit">
                        Лимит по использованию: {{ content.promocode.usage_limit }}
                      </div>
                    </template>
                  </div>
                </template>
                <div class="text-xs-right">
                  <v-btn outline small color="error" class="mr-0" @click="deleteBlock(action, i)">Удалить</v-btn>
                </div>
              </div>
              <div class="text-xs-right mt-3">
                <v-btn outline small color="primary" class="mr-0" @click="addText(action)">Добавить текст</v-btn>
                <v-btn outline small color="primary" class="mr-0" @click="addPromocode(action)"
                  >Добавить промокод</v-btn
                >
              </div>
              <div class="title mb-3 mt-5">Товары</div>
              <div class="box">
                <v-text-field v-model="action.catalog_title" label="Наименование каталога"></v-text-field>
                <div class="subheading mb-1 mt-2">Добавить</div>
                <v-container fluid pa-0 grid-list-xl>
                  <v-layout row>
                    <v-flex xs4>
                      <v-autocomplete
                        v-model="model"
                        :items="foundProducts"
                        :loading="productsLoading"
                        :search-input.sync="search"
                        item-text="name"
                        item-value="alias"
                        label="Товар"
                        return-object
                        hide-no-data
                        hide-selected
                        no-filter
                        height="42px"
                      >
                        <template v-slot:item="data">
                          <v-list-tile class="pa-2">
                            <v-list-tile-avatar>
                              <img :src="data.item.photo" />
                            </v-list-tile-avatar>
                            <v-list-tile-content>
                              <v-list-tile-title v-html="data.item.name"></v-list-tile-title>
                              <v-list-tile-sub-title v-html="data.item.alias"></v-list-tile-sub-title>
                            </v-list-tile-content>
                            <v-list-tile-action>
                              <v-btn fab dark small color="primary" @click="addProduct(data.item)">
                                <v-icon dark>add</v-icon>
                              </v-btn>
                            </v-list-tile-action>
                          </v-list-tile>
                        </template>
                      </v-autocomplete>
                    </v-flex>
                    <v-flex xs4>
                      <category-autocomplete-multiple v-model="action.categories" @change="changeCategories(action)" />
                    </v-flex>
                    <v-flex xs4>
                      <v-select
                        v-model="action.brands"
                        :items="brands"
                        @change="changeBrands(action)"
                        item-value="id"
                        item-text="title"
                        attach
                        chips
                        label="Бренды"
                        multiple
                      ></v-select>
                    </v-flex>
                  </v-layout>
                </v-container>
                <v-container pa-0 grid-list-xl text-xs-center fluid>
                  <draggable
                    v-model="products"
                    @change="moveProduct($event.moved.element.id, $event.moved.newIndex)"
                    class="layout row wrap"
                  >
                    <v-flex v-for="p of products" :key="p.alias" xs3>
                      <v-card style="cursor: grab">
                        <v-img :src="p.photo" contain height="150px">
                          <div class="text-xs-right">
                            <v-btn flat icon @click="removeProduct(p)" color="error">
                              <v-icon>clear</v-icon>
                            </v-btn>
                          </div>
                        </v-img>
                        <v-card-text style="height: 75px">
                          <div class="subheading">{{ p.name }}</div>
                        </v-card-text>
                      </v-card>
                    </v-flex>
                  </draggable>
                </v-container>
              </div>
            </v-card-text>
            <v-card-actions class="pr-3">
              <v-spacer></v-spacer>
              <v-btn color="info" @click="saveAction(action, i)" :loading="loading">Сохранить</v-btn>
            </v-card-actions>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-layout>
  </v-container>
</template>

<script>
import { mapState } from 'vuex'
import { VueEditor } from 'vue2-editor'
import draggable from 'vuedraggable'
import CategoryAutocompleteMultiple from '@/components/inputs/CategoryAutocompleteMultiple'

export default {
  components: {
    VueEditor,
    draggable,
    CategoryAutocompleteMultiple,
  },

  data: () => ({
    loading: false,
    actions: [],
    customToolbar: [
      [{ size: [false, 'large', 'huge'] }],
      ['bold', 'italic'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: '' }, { align: 'center' }, { align: 'right' }],
      ['image'],
    ],
    imgDesktopFile: [],
    imgMobileFile: [],
    imgDesktopSrc: [],
    imgMobileSrc: [],
    title: '',
    show_order: null,
    dialogDelete: false,
    dialogNew: false,
    dialogEdit: false,
    currentActionId: null,
    currentActionIndex: null,
    promocodes: [],
    model: null,
    search: null,
    products: [],
    foundProducts: [],
    productsLoading: false,
  }),

  mounted() {
    this.getActions()
    this.getPromocodes()
  },

  computed: {
    fields() {
      if (!this.model) return []

      return Object.keys(this.model).map((key) => {
        return {
          key,
          value: this.model[key] || 'n/a',
        }
      })
    },
    ...mapState({ brands: (state) => state.brands.brands }),
  },

  watch: {
    async search(value) {
      if (!value || value.length < 3) {
        this.foundProducts = []
        return
      }

      if (this.productsLoading) {
        return
      }

      this.productsLoading = true

      const response = await this.axios.get(
        `/api/site/products?limit=20&orderBy=priority&search=${value}&orderByDesc=1`
      )

      this.foundProducts = response.data.data
      this.productsLoading = false
    },
  },

  methods: {
    async getActions() {
      let response = await this.axios.get('/api/admin/shares')
      this.actions = response.data.data
      for (let i = 0; i < this.actions.length; i++) {
        this.$set(this.imgDesktopSrc, i, this.actions[i].desktop_image_url)
        this.$set(this.imgMobileSrc, i, this.actions[i].phone_image_url)
      }
    },

    async getPromocodes() {
      const response = await this.axios.get('/api/promo/list')
      this.promocodes = response.data
    },

    openDesktopImageDialog(i) {
      this.$refs[`desktop-img-${i}`][0].click()
    },

    openMobileImageDialog(i) {
      this.$refs[`mobile-img-${i}`][0].click()
    },

    setDesktopImage(i, event) {
      this.$set(this.imgDesktopFile, i, event.target.files[0])
      this.$set(this.imgDesktopSrc, i, URL.createObjectURL(event.target.files[0]))
    },

    setMobileImage(i, event) {
      this.$set(this.imgMobileFile, i, event.target.files[0])
      this.$set(this.imgMobileSrc, i, URL.createObjectURL(event.target.files[0]))
    },

    deleteDesktopImage(i) {
      this.$set(this.imgDesktopFile, i, null)
      this.$set(this.imgDesktopSrc, i, null)
    },

    deleteMobileImage(i) {
      this.$set(this.imgMobileFile, i, null)
      this.$set(this.imgMobileSrc, i, null)
    },

    async newAction() {
      this.loading = true
      try {
        const response = await this.axios.post('/api/admin/shares', { title: this.title })
        this.actions.push(response.data.data)
        this.title = ''
        this.dialogNew = false
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },

    newActionClose() {
      this.title = ''
      this.dialogNew = false
    },

    async saveAction(action, i) {
      this.loading = true
      try {
        const formData = new FormData()
        if (action.title) {
          formData.append('title', action.title)
        }
        if (action.seo_title) {
          formData.append('seo_title', action.seo_title)
        }
        if (action.seo_breadcrumbs) {
          formData.append('seo_breadcrumbs', action.seo_breadcrumbs)
        }
        if (action.seo_keywords) {
          formData.append('seo_keywords', action.seo_keywords)
        }
        if (action.seo_description) {
          formData.append('seo_description', action.seo_description)
        }
        if (this.imgMobileFile[i]) {
          formData.append('phone_image', this.imgMobileFile[i], this.imgMobileFile[i].name)
        } else if (!this.imgMobileSrc[i]) {
          formData.append('phone_image', '')
        }
        if (this.imgDesktopFile[i]) {
          formData.append('desktop_image', this.imgDesktopFile[i], this.imgDesktopFile[i].name)
        } else if (!this.imgDesktopSrc[i]) {
          formData.append('desktop_image', '')
        }
        if (action.catalog_title) {
          formData.append('catalog_title', action.catalog_title)
        }

        await this.axios.post(`/api/admin/shares/${action.id}`, formData)

        for (let id of action.deletedContents) {
          await this.axios.delete(`/api/admin/shares/${action.id}/contents/${id}`)
        }

        for (let content of action.contents) {
          const url = content.id
            ? `/api/admin/shares/${action.id}/contents/${content.id}`
            : `/api/admin/shares/${action.id}/contents`
          let params = {}
          if (content.type === 1) {
            params = {
              type: 1,
              text: content.text,
            }
          }
          if (content.type === 3) {
            params = {
              type: 3,
              promocode_id: content.promocode.id,
            }
          }
          await this.axios.post(url, params)
        }
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },

    async toggleActionStatus(action, index) {
      try {
        const response = await this.axios.post(`/api/admin/shares/${action.id}`, {
          is_active: !action.is_active,
        })
        this.$set(this.actions, index, response.data.data)
      } catch (e) {
        console.log(e)
      }
    },

    openEditDialog(action, index) {
      this.dialogEdit = true
      this.currentActionId = action.id
      this.currentActionIndex = index
      this.title = action.title
      this.show_order = action.show_order
    },

    openDeleteDialog(action, index) {
      this.dialogDelete = true
      this.currentActionId = action.id
      this.currentActionIndex = index
      this.title = action.title
    },

    closeEditDialog() {
      this.dialogEdit = false
      this.title = ''
      this.show_order = null
    },

    closeDeleteDialog() {
      this.dialogDelete = false
      this.title = ''
    },

    async saveActionTitleAndOrder() {
      this.loading = true
      try {
        await this.axios.post(`/api/admin/shares/${this.currentActionId}`, {
          title: this.title,
          show_order: this.show_order,
        })
        await this.getActions()
        this.title = ''
        this.show_order = null
        this.dialogEdit = false
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },

    async deleteAction() {
      this.loading = true
      try {
        await this.axios.delete(`/api/admin/shares/${this.currentActionId}`)
        this.actions.splice(this.currentActionIndex, 1)
        this.title = ''
        this.dialogDelete = false
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },

    async openPanel(i) {
      if (i === this.currentActionIndex) {
        return
      }
      this.currentActionId = this.actions[i].id
      this.currentActionIndex = i
      this.products = []
      let response = await this.axios.get(`/api/admin/shares/${this.currentActionId}`)
      this.$set(this.actions, i, { ...response.data.data })
      this.actions[i].categories = this.actions[i].categories.map((c) => c.id)
      this.actions[i].deletedContents = []
      this.getProducts()
    },

    async getProducts() {
      const response = await this.axios.get(`/api/admin/shares/${this.currentActionId}/products`)
      this.products = response.data.data
    },

    async addProduct(product) {
      for (let p of this.products) {
        if (p.id === product.id) {
          return
        }
      }

      this.products.unshift(product)
      await this.axios.post(`/api/admin/shares/${this.currentActionId}/products`, {
        product_id: product.id,
      })
      this.moveProduct(product.id, 0)
    },

    async removeProduct(product) {
      this.products = this.products.filter((p) => p.id !== product.id)
      await this.axios.delete(`/api/admin/shares/${this.currentActionId}/products/${product.id}`)
    },

    async moveProduct(productId, newIndex) {
      await this.axios.patch(`/api/admin/shares/${this.currentActionId}/products/${productId}`, {
        show_order: newIndex,
      })
    },

    addText(action) {
      action.contents.push({ type: 1, text: '' })
    },

    addPromocode(action) {
      action.contents.push({ type: 3, promocode_id: null })
    },

    deleteBlock(action, i) {
      action.deletedContents.push(action.contents[i].id)
      action.contents.splice(i, 1)
    },

    async changeBrands(action) {
      await this.axios.put(`/api/admin/shares/${this.currentActionId}/brands`, {
        brands: action.brands,
      })
      this.getProducts()
    },

    async changeCategories(action) {
      await this.axios.put(`/api/admin/shares/${this.currentActionId}/categories`, {
        categories: action.categories,
      })
      this.getProducts()
    },
  },
}
</script>

<style scoped>
.action-header {
  order: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-title {
  font-weight: bold;
  vertical-align: center;
}

.order {
  max-width: 80px;
}

.box {
  padding: 15px;
  border: 1px solid #ccc;
}
</style>
