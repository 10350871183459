<template>
  <v-dialog v-model="show" scrollable persistent max-width="750px">
    <v-card>
      <v-card-title class="headline grey lighten-2" primary-title>Условия</v-card-title>
      <v-card-text>
        <v-container grid-list-md class="pl-0 pr-0" text-xs-center>
          <v-layout v-for="(condition, index) of value" :key="index" align-center>
            <v-flex xs3>
              <v-text-field v-model.number="condition.total_from" box label="От" type="number" required></v-text-field>
            </v-flex>
            <v-flex xs1>&mdash;</v-flex>
            <v-flex xs3>
              <v-text-field v-model.number="condition.total_to" box label="До" type="number" required></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-select v-model="condition.type" :items="CHARGE_TYPE" box label="Тип" required></v-select>
            </v-flex>
            <v-flex xs2>
              <v-text-field v-model.number="condition.amount" box label="Наценка" type="number" required></v-text-field>
            </v-flex>
            <v-flex xs1>
              <v-btn flat icon color="error" @click="deleteCondition(index)">
                <v-icon>clear</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs12>
              <v-btn flat icon color="info" @click="addCondition">
                <v-icon large>add_circle</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="close" outline>Закрыть</v-btn>
        <v-btn color="info" :loading="loading" @click="save">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { CHARGE_TYPE } from '@/constants.js'

export default {
  props: {
    value: Array,
    show: Boolean,
    loading: Boolean,
  },

  data: () => ({
    CHARGE_TYPE: CHARGE_TYPE,
  }),

  methods: {
    close() {
      this.$emit('close')
    },

    save() {
      this.$emit('input', this.value)
      this.$emit('save')
    },

    addCondition() {
      this.value.push({ type: 'fixed' })
    },

    deleteCondition(index) {
      this.value = this.value.filter((el, i) => index !== i)
    },
  },
}
</script>
