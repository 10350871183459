import Vue from 'vue'

const state = () => ({
  warehouse: {
    data: {},
  },
  storageLoading: false,
  orderBy: {
    vendor_code: false,
  },
  pagiLoading: {
    prev: false,
    next: false,
  },
})

const getters = {
  warehouse: (state) => state.warehouse,

  storageLoading: (state) => state.storageLoading,

  warehousePagiLoad: (state) => state.pagiLoading,

  orderBy: (state) => state.orderBy,
}

const actions = {
  async getWarehouse({ commit }) {
    commit('CHANGE_ANIMATE_STATUS', true)
    await Vue.axios
      .get(`/api/admin/products/warehouse?paginate=10&withCountableFields=1&withWarehouseData=1&orderByDesc=1`)
      .then((res) => {
        commit('SAVE_WAREHOUSE', res.data.data)
        commit('CHANGE_ANIMATE_STATUS', false)
      })
      .catch((err) => console.log(err.message))
  },

  async sortBy({ commit, state }) {
    commit('CHANGE_ANIMATE_STATUS', true)
    let link = `/api/admin/products/warehouse?paginate=10&withCountableFields=1&withWarehouseData=1`

    if (state.orderBy.vendor_code) {
      commit('CHANGE_ORDER_BY', { vendor_code: false })
      link += '&orderByDesc=1'
    } else {
      commit('CHANGE_ORDER_BY', { vendor_code: true })
      link += '&orderBy=vendor_code'
    }

    const currentPage = state.warehouse.current_page
    if (currentPage) {
      link += `&page=${currentPage}`
    }

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_WAREHOUSE', res.data.data)
        commit('CHANGE_ANIMATE_STATUS', false)
      })
      .catch((err) => console.log(err.message))
  },

  async goToWarehousePage({ commit, state }, page) {
    let link = `/api/admin/products/warehouse?page=${page}&paginate=10&withCountableFields=1&withWarehouseData=1`

    if (state.orderBy.vendor_code) {
      link += `&orderBy=vendor_code`
    } else {
      link += '&orderByDesc=1'
    }

    await Vue.axios.get(link).then((res) => {
      commit('SAVE_WAREHOUSE', res.data.data)
    })
  },

  async searchByWarehouse({ commit, state }, query) {
    commit('CHANGE_ANIMATE_STATUS', true)

    let link = `/api/admin/products/warehouse?paginate=10&withCountableFields=1&withWarehouseData=1&search=${query}`

    if (state.orderBy.vendor_code) {
      link += `&orderBy=vendor_code`
    } else {
      link += '&orderByDesc=1'
    }

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_WAREHOUSE', res.data.data)
        commit('CHANGE_ANIMATE_STATUS', false)
      })
      .catch((err) => console.log(err.message))
  },

  async storageProductRemove({ dispatch }, data) {
    let link = `/api/admin/products/warehouse/${data.id}`
    if (data.removeFromBase) {
      link += `?deleteProduct=1`
    }
    Vue.axios
      .delete(link)
      .then(() => {
        dispatch('getWarehouse')
      })
      .catch((err) => console.log(err.message))
  },

  async storageProductsRemove({ dispatch }, data) {
    let link = `/api/admin/products/warehouse`
    if (data.products) {
      data.products.forEach((p, i) => {
        if (i === 0) {
          link += `?products[]=${p}`
        } else {
          link += `&products[]=${p}`
        }
      })
    }
    if (data.removeFromBase) {
      link += '&deleteProduct=1'
    }
    await Vue.axios
      .delete(link)
      .then(() => {
        dispatch('getWarehouse')
      })
      .catch((err) => console.log(err.message))
  },

  async patchProduct({ dispatch }, data) {
    await Vue.axios
      .patch(`/api/admin/products/warehouse/${data.id}`, data.params)
      .then(() => dispatch('getWarehouse'))
      .catch((err) => console.log(err.message))
  },

  async warehouseAddProduct({ dispatch, commit }, data) {
    commit('TOGGLE_BTN_LOADER', true)
    await Vue.axios
      .post('/api/admin/products/warehouse', data)
      .then(() => {
        dispatch('getWarehouse')
        commit('TOGGLE_BTN_LOADER', false)
      })
      .catch((err) => console.log(err.message))
  },

  async prevWarehousePage({ commit, state }) {
    commit('CHANGE_PAGI_PREV_LOAD', true)

    const prevPage = state.warehouse.current_page - 1
    let link = `/api/admin/products/warehouse?page=${prevPage}&paginate=10&withCountableFields=1&withWarehouseData=1`

    if (state.orderBy.vendor_code) {
      link += `&orderBy=vendor_code`
    } else {
      link += '&orderByDesc=1'
    }

    if (prevPage >= 1) {
      await Vue.axios
        .get(link)
        .then((res) => {
          commit('SAVE_WAREHOUSE', res.data.data)
          commit('CHANGE_PAGI_PREV_LOAD', false)
        })
        .catch((err) => console.log(err.message))
    }
  },

  async nextWarehousePage({ commit, state }) {
    commit('CHANGE_PAGI_NEXT_LOAD', true)
    const nextPage = state.warehouse.current_page + 1
    const lastPage = state.warehouse.last_page
    let link = `/api/admin/products/warehouse?page=${nextPage}&paginate=10&withCountableFields=1&withWarehouseData=1`

    if (state.orderBy.vendor_code) {
      link += `&orderBy=vendor_code`
    } else {
      link += '&orderByDesc=1'
    }

    if (nextPage <= lastPage) {
      await Vue.axios
        .get(link)
        .then((res) => {
          commit('SAVE_WAREHOUSE', res.data.data)
          commit('CHANGE_PAGI_NEXT_LOAD', false)
        })
        .catch((err) => console.log(err.message))
    }
  },
}

const mutations = {
  SAVE_WAREHOUSE(state, data) {
    state.warehouse = data
  },

  CHANGE_ORDER_BY(state, sort) {
    state.orderBy = sort
  },

  CHANGE_ANIMATE_STATUS(state, status) {
    state.storageLoading = status
  },

  CHANGE_PAGI_PREV_LOAD(state, status) {
    state.pagiLoading.prev = status
  },

  CHANGE_PAGI_NEXT_LOAD(state, status) {
    state.pagiLoading.next = status
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
