<template>
  <div class="way-element__wrap">
    <div :class="['way-element', `my-el-${el.id}`]">
      <div class="way-element__inner">
        <button class="way-arrow" @click="getOrders">
          <img src="/images/way-arrow.svg" alt="arrow" />
        </button>
        <div class="way-element__title">
          <h2>{{ el.name }}</h2>
          <img class="way-element__edit" src="/images/waybill-edit.svg" alt="edit" @click="openModal('edit')" />
          <p>{{ el.total }} BYN</p>
        </div>
      </div>
      <div class="way-element__inner">
        <div class="way-element__input">
          <h3>Вод.</h3>
          <input
            type="text"
            placeholder="Введите имя..."
            :value="el.courier"
            @keypress.enter="updateInput($event, 'courier')"
            @change="updateInput($event, 'courier')"
          />
        </div>
        <div class="way-element__input">
          <h3>Мен.</h3>
          <input
            type="text"
            placeholder="Введите имя..."
            :value="el.manager"
            @keypress.enter="updateInput($event, 'manager')"
            @change="updateInput($event, 'manager')"
          />
        </div>
        <button class="way-element__excel" @click="report(el.id)">
          <img src="/images/print.svg" alt="excel" />
        </button>
      </div>
    </div>
    <table class="way-table">
      <thead class="way-table__head">
        <tr>
          <th>№</th>
          <th class="way-address">Адрес</th>
          <th class="way-phone">Телефон</th>
          <th class="way-time">Время</th>
          <th class="way-floor">
            <p>На этаж</p>
            <div>
              <span class="border">гр</span>
              <span>пом</span>
            </div>
          </th>
          <th class="way-comment">Примечание</th>
          <th>Статус</th>
        </tr>
      </thead>
      <template v-if="orders.length">
        <draggable class="way-table__body" tag="tbody" handle=".way--index" v-model="orders" @change="swap">
          <tr v-for="(order, index) in orders" :key="order.id" :data-index="index" :data-id="order.id">
            <td class="way--index">
              <a :href="`/flights/${order.flight_id}/orders/${order.order_id}`" target="_blank">
                {{ index + 1 }}
              </a>
              <img
                class="way--index__close"
                src="/images/close-icon.svg"
                alt="del"
                @click="openModal('remove', order)"
              />
            </td>
            <td class="with-input">
              <textarea
                type="text"
                :value="order.address"
                @focus="toggleFocus"
                @blur="toggleFocus"
                @change="updateOrderInput($event, 'address', order.id)"
              />
            </td>
            <td class="with-input phone-td">
              <input
                type="text"
                :value="order.phone"
                @focus="toggleFocus"
                @blur="toggleFocus"
                @keypress.enter="updateOrderInput($event, 'phone', order.id)"
                @change="updateOrderInput($event, 'phone', order.id)"
              />
            </td>
            <td class="with-input time-input">
              <masked-input
                :value="sortTime(order.time, 0)"
                @focus="toggleFocus"
                @blur="toggleFocus"
                @keypress.native.enter="updateOrderInput($event, 'time', order.id)"
                @change="updateOrderInput($event, 'time', order.id)"
                :ref="`first-time-${order.id}`"
                mask="11:11"
              />
              <p>-</p>
              <masked-input
                :value="sortTime(order.time, 1)"
                @focus="toggleFocus"
                @blur="toggleFocus"
                @keypress.native.enter="updateOrderInput($event, 'time', order.id)"
                @change="updateOrderInput($event, 'time', order.id)"
                :ref="`second-time-${order.id}`"
                mask="11:11"
              />
            </td>
            <td class="floor-td">
              <span class="floor-one"> {{ order.delivery_floor_price }} BYN </span>
              <span class="floor-more">
                <span>{{ order.delivery_floor_loader }}</span>
                <span>{{ order.delivery_floor_helper }}</span>
              </span>
            </td>
            <td class="with-input">
              <textarea
                type="text"
                :value="order.admin_comment"
                @focus="toggleFocus"
                @blur="toggleFocus"
                @change="updateOrderInput($event, 'admin_comment', order.id)"
              />
            </td>
            <td class="order-status-td">
              <span
                :class="[
                  'order-status',
                  { 'gray-status': order.status === 4 },
                  { 'red-status': order.status === 2 },
                  { 'green-status': order.status === 5 },
                ]"
                @click="changeOrderStatus(order)"
              />
            </td>
          </tr>
        </draggable>
      </template>
    </table>
    <Modal class="waybill-modal" v-if="visible" @closePopup="closeModal">
      <div class="waybill-modal__input">
        <input type="text" ref="waybillName" :value="el.name" autofocus />
      </div>
      <div class="waybill-modal__btns">
        <button class="primary" @click="saveName">Сохранить</button>
        <button class="destroy" @click="destroyWaybill">Удалить</button>
      </div>
    </Modal>
    <Modal v-if="del" type="remove" class="waybill-modal">
      <h3 class="waybill-modal__title">Удалить заказ из маршрута?</h3>
      <div class="waybill-modal__btns">
        <button class="primary" @click="destroyOrder">Удалить</button>
        <button class="border" @click="closeModal">Отмена</button>
      </div>
    </Modal>
    <div class="blocker" v-if="visible || del" @click="closeModal"></div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import MaskedInput from 'vue-masked-input'
import Modal from '@/components/tools/Modal.vue'

export default {
  components: { Modal, MaskedInput, draggable },

  props: {
    el: {
      type: Object,
      default: () => {},
    },
  },

  computed: {
    orders: {
      get() {
        return this.$store.getters['waybillOrders']
      },
      set(value) {
        this.$store.commit('SAVE_WAYBILL_ORDERS', value)
      },
    },
  },

  data: () => ({
    visible: false,
    del: false,
    product_id: 0,
    order_id: 0,
  }),

  methods: {
    async swap() {
      const config = {
        headers: {
          'Content-Type': 'application/json',
        },
      }
      const data = this.orders.map((order, index) => ({
        id: order.id,
        new_index: index,
      }))

      await this.axios.patch('/api/admin/waybills/orders/swap', data, config).catch((err) => console.log(err))
    },

    sortTime(str, index) {
      if (!str.length) return

      const arr = str.split('-')
      return arr[index]
    },

    report(id) {
      window.open(`${this.axios.defaults.baseURL}/api/admin/waybills/${id}/report`, '_blank')
    },

    async saveName() {
      const query = 'name'
      const value = this.$refs.waybillName.value
      const id = this.el.id
      const flight_id = this.$route.params.flight_id

      await this.$store.dispatch('updateWaybill', {
        id,
        query,
        value,
        flight_id,
      })

      await this.closeModal()
    },

    async destroyWaybill() {
      const data = {
        id: this.el.id,
        flight_id: this.$route.params.flight_id,
      }

      await this.$store.dispatch('destroyWaybill', data)
    },

    closeModal() {
      this.visible = false
      this.del = false
    },

    openModal(type, order) {
      if (type === 'remove' && order.id) {
        this.order_id = order.order_id
        this.product_id = order.id
        this.del = true
      } else if (type === 'edit') {
        this.visible = true
      }
    },

    async destroyOrder() {
      await this.$store.dispatch('destroyOrder', {
        product_id: this.product_id,
        waybill_id: this.el.id,
        flight_id: this.el.flight_id,
      })
      await this.axios.patch(`/api/admin/orders/${this.order_id}/status?status=2`).catch((err) => console.log(err))
      await this.closeModal()
    },

    async clearSelectors(parent) {
      document.querySelectorAll('.way-element__wrap').forEach((el) => {
        if (el !== parent) {
          el.classList.remove('active')
        }
      })
    },

    async toggleElement() {
      const parent = document.querySelector(`.my-el-${this.el.id}`).parentElement
      parent.classList.toggle('active')
      await this.clearSelectors(parent)
    },

    async getOrders() {
      await this.$store.dispatch('clearWaybillOrders')
      await this.toggleElement()
      await this.$store.dispatch('getWaybillOrders', this.el.id)
    },

    async updateOrderInput(e, query, id) {
      let value = e.target.value

      if (query === 'admin_comment' && !value.length) {
        value = 'null'
      }

      if (query === 'time') {
        const first_time = this.$refs[`first-time-${id}`]
        const second_time = this.$refs[`second-time-${id}`]

        value = `${first_time[0].$el.value}-${second_time[0].$el.value}`
      }

      if (!value.length && query !== 'admin_comment') {
        e.target.focus()
        return
      }

      await this.$store.dispatch('waybillOrderUpdate', {
        id,
        query,
        value,
        waybill_id: this.el.id,
      })
      await e.target.blur()
    },

    toggleFocus(e) {
      const el = e.target.parentElement

      el.classList.toggle('focus-on')
    },

    async updateInput(e, query) {
      const value = e.target.value

      if (!value.length) {
        e.target.focus
        return
      }

      await this.$store.dispatch('updateWaybill', {
        id: this.el.id,
        query,
        value,
        flight_id: this.$route.params.flight_id,
      })
      await e.target.blur()
    },

    async changeOrderStatus(data) {
      let value = 4

      if (data.status === 2) {
        value = 4
      }

      if (data.status === 4) {
        value = 5
      }

      if (data.status === 5) {
        value = 2
      }

      await this.$store.dispatch('waybillOrderUpdate', {
        id: data.id,
        value,
        query: 'status',
        waybill_id: this.el.id,
      })
      await this.axios
        .patch(`/api/admin/orders/${data.order_id}/status?status=${value}`)
        .catch((err) => console.log(err))
    },
  },
}
</script>

<style lang="scss" scoped>
.order-status-td {
  display: flex;
  justify-content: center;
  align-items: center;
}
.order-status {
  display: block;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:active {
    transition: all 0.2s ease-in-out;
    opacity: 0.8;
  }

  &.gray-status {
    background-color: #828282;
  }
  &.red-status {
    background-color: #ff4544;
  }
  &.green-status {
    background-color: #04b200;
  }
}
.floor-td {
  padding: 0 0 2px 0 !important;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 16px;
  color: #000000;

  span {
    display: block;
    text-align: center;
  }

  .floor-one {
    display: block;
    border-bottom: 1px solid #dadada;
  }

  .floor-more {
    display: flex;
    justify-content: space-between;
    span {
      width: 100%;
      &:first-child {
        border-right: 1px solid #dadada;
      }
    }
  }
}
.way-floor {
  min-width: 100px !important;
  padding: 0 !important;
  p {
    margin: 0;
    padding: 0 10px;
    border-bottom: 1px solid #dadada;
    text-align: center;
  }
  div {
    display: flex;
    padding: 0 10px;
  }
  span {
    display: block;
    text-align: center;
    width: 100%;
    &.border {
      border-right: 1px solid #dadada;
    }
  }
}
.phone-td {
  min-width: 140px !important;
}
.waybill-modal {
  &__title {
    font-size: 22px;
    line-height: 34px;
    text-align: center;
    margin-bottom: 27px;
  }

  &__btns {
    display: flex;

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 40px;
      font-size: 12px;
      line-height: 14px;

      &.border {
        background: #ffffff;
        border: 1px solid #0984e3;
        color: #0984e3;
        font-size: 12px;
        line-height: 14px;
      }

      &.primary {
        background-color: #0984e3;
        color: #fff;
        margin-right: 18px;
        transition: all 0.2s ease-in-out;
        &:hover {
          transition: all 0.2s ease-in-out;
          background-color: #0d65a9;
        }
        &:active {
          transition: all 0.2s ease-in-out;
          opacity: 0.8;
        }
      }

      &.destroy {
        border: 1px solid #ff7776;
        background-color: #fff;
        color: #e84118;
        transition: all 0.2s ease-in-out;
        &:hover {
          transition: all 0.2s ease-in-out;
          border-color: #c73937;
          color: #c73937;
        }
        &:active {
          transition: all 0.2s ease-in-out;
          opacity: 0.8;
        }
      }
    }
  }
  &__input {
    margin-bottom: 16px;
    input {
      background: #ffffff;
      border: 1px solid #ededed;
      width: 100%;
      height: 40px;
      font-size: 14px;
      transition: all 0.2s ease-in-out;
      padding-left: 8px;
      &:focus {
        transition: all 0.2s ease-in-out;
        border-color: #0984e3;
      }
    }
  }
}
.with-input {
  overflow: visible;
  word-break: break-word;
  white-space: normal;

  input {
    width: 100%;
  }

  textarea {
    resize: none;
  }
}
.way-element__edit {
  position: absolute;
  right: -24px;
  top: -1px;
  cursor: pointer;
  transition: opacity 0.2s ease-in-out;
  &:active {
    opacity: 0.8;
    transition: opacity 0.2s ease-in-out;
  }
}
.way-arrow {
  width: 40px;
  cursor: pointer;
  img {
    transition: all 0.2s ease-in-out;
  }
}
.way-table {
  background: #f7f7f7;
  th {
    font-weight: bold;
  }
  th,
  td {
    font-size: 14px;
    line-height: 160%;
    padding: 10px;
    border-right: 0.5px solid #aaaaaa;
    min-width: 50px;
    text-align: left;
    text-decoration: none;
    max-width: 100px;
    &:first-child {
      padding-left: 15px;
      min-width: 40px;
    }
    &:last-child {
      border-right: 0;
    }
    transition: all 0.2s ease-in-out;
  }
  tr {
    border-bottom: 1px solid #aaaaaa;
    &:last-child {
      border-color: #dadada;
    }
  }
  .way-address {
    min-width: 185px;
  }
  .way-comment {
    min-width: 190px;
  }
  .way-time {
    min-width: 50px;
  }
  .way-phone {
    min-width: 130px;
  }
  .way--index {
    color: #0984e3;
    text-align: center;
    min-width: 50px;
    position: relative;

    a {
      text-decoration: none;
    }

    &__close {
      position: absolute;
      right: 1px;
      top: 3px;
      width: 10px;
      height: 10px;
      object-fit: contain;
      cursor: pointer;
    }
  }
}
.way-element__wrap {
  transition: all 0.2s ease-in-out;
  .way-table {
    display: none;
  }
  &.active {
    .way-table {
      display: block;
      width: 100%;
    }
    .way-arrow {
      img {
        transition: all 0.2s ease-in-out;
        transform: rotate(180deg);
      }
    }
  }
}
.way-element {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  padding: 7px 13px 8px 27px;
  border-bottom: 1px solid #dadada;

  &__inner {
    display: flex;
    align-items: center;
  }
  &__title {
    margin-left: 18px;
    position: relative;
    h2 {
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      margin-bottom: 3px;
      font-weight: bold;
    }
    p {
      font-size: 11px;
      line-height: 13px;
      color: #828282;
      margin: 0;
    }
  }
  &__input {
    display: flex;
    align-items: center;
    margin-right: 22px;
    h3 {
      font-weight: bold;
      font-size: 14px;
      line-height: 160%;
      margin-right: 11px;
    }
    input {
      background: #ffffff;
      border: 1px solid #ededed;
      height: 40px;
      font-size: 14px;
      color: #000000;
      max-width: 100px;
      width: 100%;
      padding-left: 13px;
      padding-right: 7px;
      transition: all 0.2s ease-in-out;
      &::placeholder {
        font-size: 11px;
      }
      &:focus {
        transition: all 0.2s ease-in-out;
        border-color: #0984e3;
      }
    }
  }
  &__excel {
    border: 1px solid #0984e3;
    border-radius: 100%;
    width: 29px;
    height: 29px;
  }
}
.with-input {
  input {
    box-sizing: border-box;
  }
}
td {
  &.focus-on {
    position: relative;
    transition: all 0.2s ease-in-out;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid #0984e3;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.2s ease-in-out;
      cursor: text;
    }
  }
}

.time-input {
  text-align: center !important;
  p {
    margin: 0 !important;
  }
  input {
    text-align: center;
  }
}
</style>
