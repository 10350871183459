<template>
  <div>
    <ul class="modal-filter__navbar">
      <li :class="{ active: tab === 0 }" @click="changeTab(0)">По артикулу</li>
      <li :class="{ active: tab === 1 }" @click="changeTab(1)">Вручную</li>
    </ul>
    <div class="modal-body">
      <template v-if="tab === 0">
        <div class="item-area">
          <template v-if="vendorProduct">
            <div class="item-area__photo">
              <img :src="vendorProduct.photo" :alt="vendorProduct.name" />
            </div>
            <div class="item-area__desc">
              <h2>{{ vendorProduct.name }}</h2>
              <p>{{ vendorProduct.type }}</p>
              <a :href="`${CLICKHOUSE_WEBSITE_URL}/product/${vendorProduct.alias}`" target="_blank">{{
                vendorProduct.vendor_code
              }}</a>
              <div class="item-area__price">
                <h3>
                  <template v-if="vendorProduct.family_price">{{ vendorProduct.family_price }}</template>
                  <template v-else>
                    {{ vendorProduct.price }}
                  </template>
                  RUB
                </h3>
                <a :href="vendorProduct.link" target="_blank">
                  <img src="/images/share-icon.svg" alt="share" />
                </a>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-if="tab === 1">
        <div class="hand-area">
          <div class="hand-row">
            <input type="text" placeholder="Наименование товара" v-model="hand.name" />
            <input type="number" placeholder="Цена" v-model="hand.price" />
          </div>
          <div class="hand-single">
            <input type="text" placeholder="Описание товара" v-model="hand.description" />
          </div>
          <div class="hand-triple">
            <input type="number" placeholder="Курс" v-model="hand.exchange" />
            <input type="number" placeholder="%" v-model="hand.commission" />
            <input type="number" placeholder="Фикс." v-model="hand.fix_price" />
          </div>
        </div>
      </template>
    </div>
    <div class="modal-bottom">
      <input type="text" placeholder="Введите артикул" @change="getProductInfo" v-model.trim="articul" />
      <input type="number" placeholder="Кол-во" v-model.number="count" />
      <button :class="['button', 'primary', { 'loader-active': loader }]" :disabled="!status" @click="addProduct">
        Добавить
        <div class="my-loader" v-if="loader"></div>
      </button>
    </div>
  </div>
</template>

<script>
import { CLICKHOUSE_WEBSITE_URL } from '@/constants.js'

export default {
  props: {
    type: {
      type: String,
      default: 'order',
    },

    orderType: {
      type: String,
      default: 'single',
    },

    without: {
      type: String,
      default: '0',
    },

    id: {
      type: Number,
      default: 0,
    },

    productId: {
      type: Number,
      default: 0,
    },

    flightId: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    CLICKHOUSE_WEBSITE_URL,
    tab: 0,
    articul: '',
    count: null,
    hand: {
      name: '',
      price: '',
      description: '',
      exchange: '',
      fix_price: '',
      commission: '',
    },
  }),

  computed: {
    vendorProduct() {
      return this.$store.getters['productOfVendorCode']
    },

    loader() {
      return this.$store.getters['createItemLoader']
    },

    status() {
      return this.articul && this.count
    },
  },

  methods: {
    getProductInfo() {
      this.$store.dispatch('getProductOfVendorCode', this.articul)
    },

    addProduct() {
      if (this.tab === 0) {
        if (!this.vendorProduct) {
          this.getProductInfo()
        } else {
          const data = {
            flightId: this.flightId,
            orderType: this.orderType,
            without: this.without,
            id: this.id,
            old_product: this.productId,
            product_id: this.vendorProduct.id || 0,
            qty: this.count,
            vendor_code: this.vendorProduct.vendor_code,
            name: this.vendorProduct.name,
            description: this.vendorProduct.type,
            type: this.vendorProduct.type,
            price: this.vendorProduct.price,
            fixed_price: this.vendorProduct.fixed_price || 0,
            commission: this.vendorProduct.commission || '',
            exchange: this.vendorProduct.exchange || '',
          }

          if (this.type === 'replace') {
            this.$store.dispatch('orderReplaceProduct', data)
          }
          if (this.type === 'order') {
            this.$store.dispatch('addProduct', data)
          }
          if (this.type === 'warehouse') {
            this.$store.dispatch('warehouseAddProduct', {
              flightId: this.flightId,
              orderType: this.orderType,
              without: this.without,
              quantity: data.qty,
              vendor_code: data.vendor_code,
              product_id: data.product_id,
              name: data.name,
              type: data.type,
              price: data.price,
            })
          }
          setTimeout(() => this.closeModal(), 2000)
        }
      }

      if (this.tab === 1) {
        const product = {
          flightId: this.flightId,
          orderType: this.orderType,
          without: this.without,
          id: this.id,
          qty: this.count,
          vendor_code: this.articul,
          name: this.hand.name,
          description: this.hand.description,
          type: this.hand.description,
          price: this.hand.price,
          exchange: this.hand.exchange,
          fix_price: this.hand.fix_price,
          commission: this.hand.commission,
        }
        if (this.type === 'order') {
          this.$store.dispatch('addProduct', product)
          setTimeout(() => this.closeModal(), 2000)
        }
        if (this.type === 'replace') {
          this.$store.dispatch('orderReplaceProduct', product)
          setTimeout(() => this.closeModal(), 2000)
        }
        if (this.type === 'warehouse') {
          this.$store.dispatch('warehouseAddProduct', {
            flightId: this.flightId,
            orderType: this.orderType,
            without: this.without,
            quantity: this.count,
            vendor_code: this.articul,
            name: this.hand.name,
            type: this.hand.description,
            description: this.hand.description,
            price: this.hand.price,
            exchange: this.hand.exchange,
            fix_price: this.hand.fix_price,
            commission: this.hand.commission,
          })
          setTimeout(() => this.closeModal(), 2000)
        }
      }
    },

    closeModal() {
      this.$emit('closeModal')
    },

    changeTab(id) {
      this.tab = id
      this.clearAll()
      this.$store.dispatch('clearVendorProduct')
    },

    cutText(text) {
      const limit = 70
      if (text.length > limit) {
        return text.slice(0, limit) + '...'
      } else {
        return text
      }
    },

    clearAll() {
      this.articul = ''
      this.count = ''
      this.hand = {
        name: '',
        price: '',
        description: '',
        course: '',
        comission: '',
        fix: '',
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.item-area {
  display: flex;
  gap: 11px;
  &__photo {
    padding: 2px;
    img {
      width: 150px;
      height: 150px;
      object-fit: contain;
    }
  }

  &__desc {
    padding: 10px 10px 9px 0;
    font-size: 14px;
    line-height: 22px;
    h2 {
      font-weight: 500;
      color: #000;
    }
    p {
      font-weight: 400;
      color: #828282;
      margin: 0;
      height: 40px;
      overflow: hidden;
      margin-bottom: 8px;
    }
    a {
      text-decoration: none;
      color: #0984e3;
    }
  }

  &__price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    h3 {
      font-weight: 700;
    }
  }
}

.button.primary.loader-active {
  font-size: 0;
  position: relative;
  .my-loader {
    right: 0;
    left: 0;
    margin: 0 auto;
    top: 12px;
    border-color: rgba(#fff, 0.2);
    border-top-color: #fff;
  }
}

.modal-filter__navbar {
  li {
    width: 100%;
  }
}

.item-area {
  width: 100%;
  background: #ffffff;
  border: 1px solid #ededed;
  min-height: 152px;
}

.modal-body {
  min-height: 152px;
  margin: 24px 0;
}

.hand-area {
  input {
    background: #ffffff;
    border: 1px solid #ededed;
    min-height: 40px;
    padding: 0 8px;
    width: 100%;
    font-size: 13px;
  }
}

.hand-row {
  display: grid;
  grid-template-columns: 1fr 0.5fr;
  gap: 10px;
  margin-bottom: 16px;
}

.hand-single {
  margin-bottom: 16px;
}

.hand-triple {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.modal-bottom {
  display: grid;
  grid-template-columns: 1fr 59px 1fr;
  gap: 10px;
  input {
    outline: 0;
    padding: 0 8px;
    background: #ffffff;
    border: 1px solid #ededed;
    min-height: 40px;
    max-width: 118px;
    width: 100%;
    font-size: 12px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

input {
  font-size: 14px;
  transition: border-color 0.3s ease-in-out;
  &:focus {
    transition: border-color 0.3s ease-in-out;
    border-color: #0984e3;
  }
}
</style>
