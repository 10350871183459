<template>
  <div class="ya-action__wrap">
    <div class="ya-action">
      <div class="my-loader" v-if="loader"></div>

      <span v-for="button in buttons" :key="button.id">
        <button :class="['active']" :disabled="button.disabled" @click="action(button)">
          <template v-if="button.id === 0">
            <svg
              class="ya-action__left"
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M1 2.5L3.5 -1.5299e-07L3.5 2.5L9 2.5L9 4.5L3.5 4.5L3.5 7L1 4.5L-1.5299e-07 3.5L1 2.5Z"
                fill="#0984E3"
              />
            </svg>
          </template>

          {{ button.name }}

          <template v-if="button.id === 1">
            <svg
              class="ya-action__right"
              width="9"
              height="7"
              viewBox="0 0 9 7"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8 4.5L5.5 7L5.5 4.5L-1.96701e-07 4.5L-1.09278e-07 2.5L5.5 2.5L5.5 -1.5299e-07L8 2.5L9 3.5L8 4.5Z"
                fill="#0984E3"
              />
            </svg>
          </template>
        </button>
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters({
      loader: 'actionButtonsLoader',
    }),
  },

  data() {
    return {
      buttons: [
        {
          id: 0,
          name: 'Отменить посл. действие',
          disabled: false,
          action: 'undo',
          loader: false,
        },
        {
          id: 1,
          name: 'Повторить посл. действие',
          disabled: false,
          action: 'redo',
          loader: false,
        },
      ],
    }
  },

  methods: {
    async action(btn) {
      this.$store.dispatch('toggleActionLoader', true)

      await this.axios
        .post(`/api${window.location.pathname}/${btn.action}`)
        .then(() => {
          setTimeout(() => this.$store.dispatch('toggleActionLoader', false), 500)
        })
        .catch(() => {
          setTimeout(() => this.$store.dispatch('toggleActionLoader', false), 500)
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.ya-action__wrap {
  position: relative;
  .my-loader {
    top: 9px;
    left: -15px;
  }
  button {
    &.loader {
      font-size: 0;
      cursor: default;
      svg {
        display: none;
      }
    }
  }
}
</style>
