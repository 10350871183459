import Vue from 'vue'

const state = () => ({
  order: null,
  otherOrder: null,
  products: [],
  availability: [],
  availabilityId: 0,
  avLoader: false,
  btnLoader: false,
  productOfVendorCode: null,
  transferLoad: false,
  promo_code_info: null,
  transfer: {
    products: [],
    toOrder: 0,
    notOrder: 0,
    orderType: 'single',
  },
  actionButtonsLoader: false,
})

const getters = {
  otherOrder: (state) => state.otherOrder,

  order: (state) => state.order,

  orderProducts: (state) => state.products,

  availability: (state) => state.availability,

  availabilityLoader: (state) => state.avLoader,

  availabilityId: (state) => state.availabilityId,

  createItemLoader: (state) => state.btnLoader,

  productOfVendorCode: (state) => state.productOfVendorCode,

  transfer: (state) => state.transfer,

  transferLoad: (state) => state.transferLoad,

  promoCodeInfo: (state) => state.promo_code_info,

  actionButtonsLoader: (state) => state.actionButtonsLoader,
}

const actions = {
  async getOtherOrders({ commit, state, dispatch }, data) {
    dispatch('toggleActionLoader', true)
    if (state.order) {
      const link = `/api/admin/flights/${data.flightId}/orders?phone=${state.order.phone}&without=${data.without}`
      await Vue.axios
        .get(link)
        .then((res) => {
          commit('SAVE_OTHER_ORDERS', res.data.data)
          setTimeout(() => dispatch('toggleActionLoader', false), 1500)
        })
        .catch((err) => {
          console.log(err.message)
          setTimeout(() => dispatch('toggleActionLoader', false), 1500)
        })
    }
  },

  toggleActionLoader({ commit }, status) {
    commit('TOGGLE_ACTION_LOADER', status)
  },

  orderUpdateProduct({ commit, dispatch, state }, data) {
    dispatch('toggleActionLoader', true)
    const products = state.products
    const index = products.findIndex((p) => p.id === data.product.id)
    if (index < 0) {
      return
    } else {
      commit('ORDER_UPD_PRODUCT', { index, product: data.product })
      setTimeout(() => dispatch('toggleActionLoader', false), 8000)
    }
  },

  async orderProductUpdate({ dispatch }, data) {
    dispatch('toggleActionLoader', true)
    let link = `/api/admin/orders/${data.id}/products/${data.productId}`

    if (data.type === 'exchange' && !parseFloat(data.value) && parseFloat(data.value) !== 0) {
      link += '?is_exchange_unchangeable=0'
    } else if (data.type === 'commission' && !parseFloat(data.value) && parseFloat(data.value) !== 0) {
      link += '?is_commission_unchangeable=0'
    } else {
      link += `?${data.type}=${data.value}`
    }

    await Vue.axios
      .patch(link)
      .then(() => {
        if (data.orderType === 'other') {
          dispatch('getOtherOrders', {
            flightId: data.flightId,
            without: data.without,
          })
          dispatch('getOrderProducts', data.id)
          dispatch('getOrder', data.id)
        }
        dispatch('toggleActionLoader', false)
      })
      .catch((err) => {
        console.log(err.message)
        dispatch('toggleActionLoader', false)
      })
  },

  async getProductOfVendorCode({ commit }, vendor_code) {
    commit('TOGGLE_BTN_LOADER', true)
    const link = `/api/admin/products?vendor_code=${vendor_code}`

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_PRODUCT_OF_VENDORCODE', res.data.data)
        commit('TOGGLE_BTN_LOADER', false)
      })
      .catch((err) => console.log(err.message))
  },

  async checkPromocode({ state, commit }) {
    await Vue.axios
      .get(`/api/admin/orders/promocodes?code=${state.order.promo_code}`)
      .then((res) => {
        const data = res.data.data[0]
        commit('SAVE_PROMO_INFO', data)
      })
      .catch((err) => console.log(err.message))
  },

  clearVendorProduct({ commit }) {
    commit('SAVE_PRODUCT_OF_VENDORCODE', [null])
  },

  async addProduct({ dispatch, commit }, data) {
    dispatch('clearAvailability')
    dispatch('toggleActionLoader', true)
    commit('TOGGLE_BTN_LOADER', true)
    let link = `/api/admin/orders/${data.id}/products?qty=${data.qty}&vendor_code=${data.vendor_code}&name=${data.name}&description=${data.description}&price=${data.price}`

    if (data.exchange) {
      link += `&exchange=${data.exchange}`
    }
    if (data.fixed_price) {
      link += `&fix_price=${data.fixed_price}`
    }
    if (data.commission) {
      link += `&commission=${data.commission}`
    }
    if (data.product_id) {
      link += `&product_id=${data.product_id}`
    }

    await Vue.axios
      .post(link)
      .then(() => {
        commit('TOGGLE_BTN_LOADER', false)
        if (data.orderType === 'single') {
          dispatch('getOrder', data.id)
          dispatch('getOrderProducts', data.id)
        } else {
          dispatch('getOtherOrders', {
            flightId: data.flightId,
            without: data.without,
          })
        }
        setTimeout(() => dispatch('toggleActionLoader', false), 1500)
      })
      .catch((err) => {
        console.log(err.message)
        setTimeout(() => dispatch('toggleActionLoader', false), 1500)
      })
  },

  clearAvailability({ commit }) {
    commit('SAVE_AVAILABILITY', [])
    commit('SAVE_AVAILABILITY_ID', 0)
  },

  async checkAvailability({ commit }, id) {
    commit('TOGGLE_AV_LOADER', true)
    await Vue.axios
      .post(`/api/admin/orders/${id}/products/availability`)
      .then((res) => {
        const data = res.data.data
        data.forEach((item) => {
          let right = null
          let colors = {
            first: '#535c68',
            second: '#535c68',
          }

          item.availabilities.forEach((av, index) => {
            if (index === 0) {
              if (av.stock === null) colors.first = '#535c68'
              if (av.stock >= 20) colors.first = '#04B200'
              if (av.stock > 0 && av.stock < 20) colors.first = '#FFD200'
              if (av.stock === 0) colors.first = '#FF3200'
            }
            if (av.stock === null) colors.second = '#535c68'
            if (av.stock) {
              right += av.stock
            }
          })

          if (right >= 20) colors.second = '#04B200'
          if (right > 0 && right < 20) colors.second = '#FFD200'
          if (right === 0) colors.second = '#FF3200'

          colors.style = `background: linear-gradient(to right, ${colors.first} 50%, ${colors.second} 50%);`

          item.colors = colors
        })

        commit('SAVE_AVAILABILITY', data)
        commit('SAVE_AVAILABILITY_ID', id)
        commit('TOGGLE_AV_LOADER', false)
      })
      .catch((err) => console.log(err.message))
  },

  async getOrderProducts({ commit, dispatch }, id) {
    dispatch('toggleActionLoader', true)
    await Vue.axios
      .get(`/api/admin/orders/${id}/products?withAvailabilities=1`)
      .then((res) => {
        commit('SAVE_PRODUCTS', res.data.data)
        setTimeout(() => dispatch('toggleActionLoader', false), 1500)
      })
      .catch((err) => {
        console.log(err.message)
        setTimeout(() => dispatch('toggleActionLoader', false), 1500)
      })
  },

  async getOrder({ commit, dispatch }, id) {
    dispatch('toggleActionLoader', true)
    await Vue.axios
      .get(`/api/admin/orders/${id}`)
      .then((res) => {
        commit('SAVE_ORDER', res.data.data)
        dispatch('checkPromocode')
        setTimeout(() => dispatch('toggleActionLoader', false), 1500)
      })
      .catch((err) => {
        console.log(err.message)
        setTimeout(() => dispatch('toggleActionLoader', false), 1500)
      })
  },

  updateOrder({ commit }, data) {
    commit('SAVE_ORDER', data)
  },

  updateOtherOrder({ dispatch }, res) {
    dispatch('getOtherOrders', {
      flightId: res.flightId,
      without: res.without,
    })
  },

  async patchOrder({ commit, dispatch }, data) {
    dispatch('toggleActionLoader', true)

    await Vue.axios
      .patch(`/api/admin/orders/${data.id}`, data.params)
      .then((res) => {
        if (data.type === 'single') {
          commit('SAVE_ORDER', res.data.data)
        } else {
          dispatch('getOtherOrders', {
            flightId: data.flightId,
            without: data.without,
          })
        }
        setTimeout(() => dispatch('toggleActionLoader', false), 1500)
      })
      .catch((err) => {
        console.log(err.message)
        setTimeout(() => dispatch('toggleActionLoader', false), 1500)
      })
  },

  async removeElementFromOrder({ dispatch, state }, data) {
    let link = `/api/admin/orders/${data.orderId}/products/${data.id}`
    if (data.removeFromBase) {
      link += '?deleteProduct=1'
    }
    await Vue.axios
      .delete(link)
      .then(() => {
        if (data.orderType === 'flight') {
          setTimeout(
            () =>
              dispatch('searchByFlight', {
                input: data.searchInput,
                id: data.flightId,
              }),
            1000
          )
        }
        if (data.orderType === 'searchPage') {
          setTimeout(() => dispatch('getSearchQuery', data.query), 1000)
        }
        if (data.orderType === 'single') {
          dispatch('getOrder', state.order.id)
          dispatch('getOrderProducts', state.order.id)
        } else {
          dispatch('getOtherOrders', {
            flightId: data.flightId,
            without: data.without,
          })
        }
      })
      .catch((err) => console.log(err.message))
  },

  async removeElementsFromOrder({ dispatch, state }, data) {
    let link = `/api/admin/orders/${data.orderId}/products`

    if (data.products) {
      data.products.forEach((p, i) => {
        if (i === 0) {
          link += `?products[]=${p}`
        } else {
          link += `&products[]=${p}`
        }
      })
    }

    if (data.removeFromBase) {
      link += '&deleteProduct=1'
    }
    await Vue.axios
      .delete(link)
      .then(() => {
        if (data.orderType === 'single') {
          dispatch('getOrder', state.order.id)
          dispatch('getOrderProducts', state.order.id)
        } else {
          dispatch('getOtherOrders', {
            flightId: data.flightId,
            without: data.without,
          })
        }
      })
      .catch((err) => console.log(err.message))
  },

  setTransferProducts({ commit }, data) {
    commit('SAVE_TRANSFER', data)
  },

  async transferProducts({ commit, dispatch, state }, data) {
    commit('CHANGE_TRANSFER_ANIMATION', true)
    const products = data.products
    let link = `/api/admin/orders/${data.toOrder}/products/insert?products[]=`
    if (products.length) {
      await products.forEach((product, index) => {
        if (index === 0) {
          link += product
        } else {
          link += `&products[]=${product}`
        }
      })
      await Vue.axios
        .post(link)
        .then(() => {
          dispatch('getOrder', state.order.id)
          dispatch('getOrderProducts', state.order.id)
          dispatch('getOtherOrders', {
            flightId: data.flightId,
            without: data.without,
          })
          commit('CHANGE_TRANSFER_ANIMATION', false)
          commit('SAVE_TRANSFER', {
            toOrder: 0,
            notOrder: 0,
            products: [],
            orderType: 'single',
            flightId: 0,
            without: '0',
          })
        })
        .catch((err) => console.log(err.message))
    }
  },

  async transferProductsToFlight({ dispatch }, data) {
    const products = data.products
    let link = `/api/admin/orders/${data.id}/products/flight?flight_id=${data.flightId}`
    if (products.length) {
      products.forEach((product) => {
        link += `&products[]=${product}`
      })
      await Vue.axios
        .patch(link)
        .then(() => {
          dispatch('getOrder', data.id)
          dispatch('getOrderProducts', data.id)
          dispatch('getOtherOrders', {
            flightId: data.currentFlightId,
            without: data.id,
          })
        })
        .catch((err) => console.log(err.message))
    }
  },

  async orderReplaceProduct({ commit, dispatch }, data) {
    dispatch('clearAvailability')
    dispatch('toggleActionLoader', true)
    commit('TOGGLE_BTN_LOADER', true)
    const link = `/api/admin/orders/${data.id}/products/${data.old_product}/replace`

    let params = {
      qty: data.qty,
      vendor_code: data.vendor_code,
      name: data.name,
      description: data.description,
      price: data.price,
      product_id: data.product_id,
    }

    if (data.exchange) {
      params.exchange = data.exchange
    }
    if (data.fixed_price) {
      params.fixed_price = data.fixed_price
    }
    if (data.commission) {
      params.commission = data.commission
    }

    await Vue.axios
      .patch(link, params)
      .then(() => {
        if (data.orderType === 'single') {
          dispatch('getOrder', data.id)
          dispatch('getOrderProducts', data.id)
        } else {
          dispatch('getOtherOrders', {
            flightId: data.flightId,
            without: data.without,
          })
        }
        commit('TOGGLE_BTN_LOADER', false)
        dispatch('toggleActionLoader', false)
      })
      .catch((err) => {
        console.log(err.message)
        commit('TOGGLE_BTN_LOADER', false)
        dispatch('toggleActionLoader', false)
      })
  },
}

const mutations = {
  ORDER_UPD_PRODUCT(state, data) {
    Vue.set(state.products, data.index, data.product)
  },

  TOGGLE_ACTION_LOADER(state, status) {
    state.actionButtonsLoader = status
  },

  SAVE_TRANSFER(state, data) {
    state.transfer = data
  },

  SAVE_PROMO_INFO(state, data) {
    state.promo_code_info = data
  },

  CHANGE_TRANSFER_ANIMATION(state, status) {
    state.transferLoad = status
  },

  SAVE_OTHER_ORDERS(state, data) {
    state.otherOrder = data
  },

  SAVE_ORDER(state, data) {
    state.order = data
  },

  SAVE_PRODUCTS(state, data) {
    state.products = data
  },

  SAVE_AVAILABILITY(state, data) {
    state.availability = data
  },

  TOGGLE_AV_LOADER(state, boolean) {
    state.avLoader = boolean
  },

  TOGGLE_BTN_LOADER(state, boolean) {
    state.btnLoader = boolean
  },

  SAVE_PRODUCT_OF_VENDORCODE(state, data) {
    state.productOfVendorCode = data[0]
  },

  SAVE_AVAILABILITY_ID(state, id) {
    state.availabilityId = id
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
