import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import Layout from '@/pages/Layout'
import LoginPage from '@/pages/LoginPage'
import PlanningPage from '@/pages/PlanningPage'
import FlightsPage from '@/pages/FlightsPage'
import ActionsPage from '@/pages/ActionsPage'
import UsersPage from '@/pages/UsersPage'
import InfoPage from '@/pages/InfoPage'
import CatalogPage from '@/pages/CatalogPage'
import SupplierPage from '@/pages/SupplierPage'
import SupplierUpdateCatalogPage from '@/pages/SupplierUpdateCatalogPage'
import PromoCodesEditor from '@/pages/PromoCodesEditorPage'
import MainPage from '@/pages/MainPage'
import OrderPage from '@/pages/OrderPage'
import Warehouse from '@/pages/Warehouse'
import OrderCreate from '@/pages/OrderCreate'
import WaybillsPage from '@/pages/Waybills'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: LoginPage,
  },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/',
        name: 'admin',
        component: PlanningPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/flights',
        name: 'flights',
        component: FlightsPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/actions',
        name: 'actions',
        component: ActionsPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/waybills/:flight_id',
        name: 'waybills',
        component: WaybillsPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/flights/:flightId/orders/:id',
        name: 'order',
        component: OrderPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/products/warehouse',
        name: 'warehouse',
        component: Warehouse,
        meta: { requiresAuth: true },
      },
      {
        path: '/users',
        name: 'users',
        component: UsersPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/information',
        name: 'information',
        component: InfoPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/catalog',
        name: 'catalog',
        component: CatalogPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/supplier/:id',
        name: 'supplier',
        component: SupplierPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/supplier/:id/update-catalog',
        name: 'supplier-update-catalog',
        component: SupplierUpdateCatalogPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/promo',
        name: 'promo',
        component: PromoCodesEditor,
        meta: { requiresAuth: true },
      },
      {
        path: '/main',
        name: 'main',
        component: MainPage,
        meta: { requiresAuth: true },
      },
      {
        path: '/orders_create/:flightId',
        name: 'orderCreate',
        component: OrderCreate,
        meta: { requiresAuth: true },
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!store.getters.isAuthenticated) {
      next('/login')
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router
