<template>
  <div class="yandex--map" style="position: relative; overflow: hidden">
    <iframe
      src="https://yandex.ru/map-widget/v1/-/CCUia0SkkB"
      frameborder="1"
      allowfullscreen="true"
      style="position: relative"
    ></iframe>
  </div>
</template>

<script>
export default {
  name: 'Map',
}
</script>

<style scoped lang="scss">
.yandex--map {
  iframe {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 80px);
  }
}
</style>
