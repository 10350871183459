import axios from 'axios'

function levelCategories(categories, level, result) {
  for (let cat of categories) {
    result.push({ value: cat.id, text: cat.name, level })
    if (cat.children_subcategories_all.length > 0) {
      levelCategories(cat.children_subcategories_all, level + 1, result)
    }
  }
}

const ROOT = { value: -1, text: 'Каталог', level: 0 }

const state = {
  categories: [ROOT],
}

const mutations = {
  updateCategories(state, categories) {
    const result = [ROOT]
    levelCategories(categories, 0, result)
    state.categories = result
  },
}

const actions = {
  async fetchCategories({ commit }) {
    const response = await axios.get('/api/admin/categories?withSubcategories=1')
    commit('updateCategories', response.data.data)
  },
}

export default {
  state,
  actions,
  mutations,
}
