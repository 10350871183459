<template>
  <div>
    <template v-if="isAuthenticated">
      <Navigation app />
      <Toolbar app />
      <v-content class="my-content">
        <router-view></router-view>
      </v-content>
    </template>

    <transition name="fade" mode="out-in">
      <div class="loader" v-show="loading">Загрузка</div>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Toolbar from '@/components/Toolbar'
import Navigation from '@/components/Navigation'

export default {
  components: {
    Toolbar: Toolbar,
    Navigation: Navigation,
  },

  computed: {
    ...mapGetters(['isAuthenticated']),

    loading() {
      return this.$store.getters.loading
    },
  },

  mounted() {
    this.fetchCategories()
    this.fetchBrands()
  },

  methods: {
    ...mapActions(['fetchCategories', 'fetchBrands']),
  },
}
</script>

<style>
.loader {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 100000;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  padding-top: 300px;
  text-align: center;
  font-size: 50px;
  color: rgba(0, 0, 0, 0.4);
}
</style>
