<template>
  <div class="v-table__overflow">
    <table class="order-page__table">
      <thead class="order-page__thead">
        <tr>
          <th class="order-page__checkbx">
            <input type="checkbox" :id="`all-${id}`" v-model="allInputs" @click="setSelected" />
            <label :for="`all-${id}`"></label>
          </th>
          <th>№</th>
          <th>Бренд</th>
          <th>Артикул</th>
          <th class="desc">Описание</th>
          <th>Ед. изм.</th>
          <th>Кол‑во</th>
          <th>Кол‑во факт.</th>
          <th>Склад</th>
          <th>Цена, BYN</th>
          <th>Цена без скидки, BYN</th>
          <th>Наценка, BYN</th>
          <th>Ст‑ть, BYN</th>
          <th>Нал.</th>
          <th>
            <button class="order-page__btn order-page__move" :disabled="!selected.length" @click="openTransferProducts">
              <svg
                style="vertical-align: middle"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M7.72606 4.4568L9.54047 6.27121H7.72606V10.2634H6.2742V6.27121H4.45898L6.2742 4.45599L6.99973 3.73047L7.72525 4.45599H7.72606V4.4568Z"
                  fill="#0984E3"
                />
                <circle cx="7" cy="7" r="6.5" stroke="#0984E3" />
              </svg>
            </button>
            <button class="order-page__btn order-page__delete" :disabled="!selected.length" @click="emitRemoveElements">
              <svg
                style="vertical-align: middle"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M8.48708 9.89949L9.9013 8.48528L6.36532 4.9493L9.89949 1.41512L8.48528 0.000906229L4.9511 3.53509L1.41602 0L0.00180209 1.41421L3.53689 4.9493L0 8.48619L1.41421 9.9004L4.9511 6.36351L8.48708 9.89949Z"
                  fill="#FF7675"
                />
              </svg>
            </button>
          </th>
        </tr>
      </thead>
      <tbody class="order-page__tbody">
        <tr class="order-page__tr" v-for="(item, index) in products" :key="index">
          <td class="order-page__checkbx">
            <input
              type="checkbox"
              :id="item.id"
              :checked="allInputs"
              @change="checkSelected"
              :value="item.id"
              v-model="selected"
            />
            <label :for="item.id"></label>
          </td>
          <td>{{ index + 1 }}</td>
          <td>{{ item.supplier_title }}</td>
          <td>
            <a :href="`${CLICKHOUSE_WEBSITE_URL}/product/${item.alias}`" target="_blank">
              {{ item.vendor_code }}
            </a>
          </td>
          <td class="td-desc" style="text-align: left; white-space: inherit">
            <p>{{ item.name }}</p>
            <p>{{ item.model ? item.model.type : item.description }}</p>
          </td>
          <td>{{ item.measurement_unit }}</td>
          <td class="with-input">
            <input
              type="number"
              :value="item.qty"
              @focus="focusInput"
              @blur="focusNone"
              @change="updateProductData($event, 'qty', item.id)"
              @keypress.enter="updateProductData($event, 'qty', item.id)"
            />
          </td>
          <td class="with-input">
            <input
              type="number"
              :value="item.actual_quantity"
              @focus="focusInput"
              @blur="focusNone"
              @change="updateProductData($event, 'actual_quantity', item.id)"
              @keypress.enter="updateProductData($event, 'actual_quantity', item.id)"
            />
          </td>
          <td class="with-input">
            <div class="remained-in-stock">{{ item.remained }}</div>
            <input
              type="number"
              :value="item.reserved_quantity"
              @focus="focusInput"
              @blur="focusNone"
              @change="updateProductData($event, 'reserved_quantity', item.id)"
              @keypress.enter="updateProductData($event, 'reserved_quantity', item.id)"
            />
          </td>
          <td class="white-space">
            <input
              type="number"
              :value="item.price"
              @focus="focusInput"
              @blur="focusNone"
              @change="updateProductData($event, 'price', item.id)"
              @keypress.enter="updateProductData($event, 'price', item.id)"
            />
          </td>
          <td class="white-space">
            <input
              type="number"
              :value="item.full_price"
              @focus="focusInput"
              @blur="focusNone"
              @change="updateProductData($event, 'full_price', item.id)"
              @keypress.enter="updateProductData($event, 'full_price', item.id)"
            />
          </td>
          <td class="white-space">
            {{ splitPrice(item.overall_charge + '') }}
          </td>
          <td class="white-space">{{ parseFloat(item.total.toFixed(2)) }}</td>
          <td>
            <span v-if="avId !== id" class="order-page__presense none"></span>
            <span
              v-if="avId === id"
              :style="availability[index].colors.style"
              :class="['order-page__presense']"
              @click="showModal(item.id)"
            ></span>
          </td>
          <td style="white-space: nowrap">
            <span class="order-page__btn order-page__move">
              <a :href="item.link" target="_blank">
                <img src="/images/share-icon.svg" alt="move" />
              </a>
            </span>
            <span class="order-page__btn order-page__move" @click="openReplace(item.id)">
              <img src="/images/update.svg" alt="move" />
            </span>
            <span class="order-page__btn order-page__delete" @click="emitRemoveElement(item.id)">
              <img src="/images/delete-table.svg" alt="delete" />
            </span>
          </td>
        </tr>
        <tr class="order-page__tbody-bottom">
          <td>
            <button class="create-btn" @click="openModal">
              <img src="/images/plus-white.svg" alt="plus" />
            </button>
          </td>
          <td colspan="10" class="total">Итого:</td>
          <td>{{ order.products_overall_charge_sum || 0 }}</td>
          <td>{{ order.products_total_sum || 0 }}</td>
          <td colspan="2"></td>
        </tr>
      </tbody>
    </table>
    <Availability :data="availabilityData[0]" v-if="visible" @closeAvailability="closeModal" />
    <div class="blocker" v-if="visible" @click="closeModal"></div>
  </div>
</template>

<script>
import { CLICKHOUSE_WEBSITE_URL } from '@/constants.js'
import Availability from '@/components/tools/modals/Availability.vue'

export default {
  props: {
    id: {
      type: Number,
      default: 0,
    },
    exchange: {
      type: Number,
      default: 0,
    },
    products: {
      type: Array,
      default: () => [],
    },
    order: {
      type: Object,
      default: () => {},
    },
    orderType: {
      type: String,
      default: 'single',
    },
    without: {
      type: String,
      default: '0',
    },
    flightId: {
      type: Number,
      default: 0,
    },
  },

  components: { Availability },

  data: () => ({
    CLICKHOUSE_WEBSITE_URL,
    visible: false,
    presenseData: {},
    allInputs: false,
    selected: [],
    availabilityId: 0,
    availabilityData: null,
  }),

  computed: {
    availability() {
      return this.$store.getters['availability']
    },

    avId() {
      return this.$store.getters['availabilityId']
    },
  },

  methods: {
    openTransferProducts() {
      this.$emit('openTransferProducts', {
        selected: this.selected,
        type: 'transferProducts',
      })
    },

    updateProductData(e, type, productId) {
      let value = e.target.value
      if (type === 'reserved_quantity') {
        value = parseInt(e.target.value)
      }

      const params = {
        flightId: this.flightId,
        id: this.id,
        orderType: this.orderType,
        without: this.without,
        productId,
        type,
        value,
      }

      this.$store.dispatch('orderProductUpdate', params)
      e.target.blur()
    },

    focusInput(e) {
      const parent = e.target.offsetParent
      parent.classList.add('focus-on')
    },

    focusNone(e) {
      const parent = e.target.offsetParent
      parent.classList.remove('focus-on')
    },

    openReplace(product_id) {
      this.$emit('openReplace', product_id)
    },

    splitPrice(x) {
      if (typeof x === 'string') {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else if (!x) {
        return 0
      }
    },

    checkSelected() {
      if (this.selected.length > 0) {
        this.$store.dispatch('setTransferProducts', {
          toOrder: 0,
          notOrder: this.id,
          products: this.selected,
          orderType: this.orderType,
          flightId: this.flightId,
          without: this.without,
        })
      } else {
        this.$store.dispatch('setTransferProducts', {
          toOrder: 0,
          notOrder: 0,
          products: this.selected,
          orderType: this.orderType,
          flightId: this.flightId,
          without: this.without,
        })
      }
      if (this.selected.length === this.products.length) {
        this.allInputs = true
      } else {
        this.allInputs = false
      }
    },

    setSelected() {
      if (!this.allInputs) {
        const products = this.products
        products.forEach((product) => this.selected.push(product.id))
        this.$store.dispatch('setTransferProducts', {
          toOrder: 0,
          notOrder: this.id,
          products: this.selected,
          orderType: this.orderType,
          flightId: this.flightId,
          without: this.without,
        })
      } else {
        this.selected = []
        this.$store.dispatch('setTransferProducts', {
          toOrder: 0,
          notOrder: 0,
          products: this.selected,
          orderType: this.orderType,
          flightId: this.flightId,
          without: this.without,
        })
      }
    },

    openTransfer() {
      this.$emit('openTransfer')
    },

    emitRemoveElements() {
      this.$emit('removeElements', this.selected)
      this.selected = []
      this.allInputs = false
    },

    emitRemoveElement(id) {
      this.$emit('removeElement', id ? id : null)
      this.selected = []
      this.allInputs = false
    },

    showModal(id) {
      this.availabilityId = parseInt(id)
      this.availabilityData = this.availability.filter((i) => i.id === id)
      this.visible = true
    },

    closeModal() {
      this.visible = false
    },

    openModal() {
      this.$emit('openPopup')
    },
  },
}
</script>

<style lang="scss" scoped>
.td-desc {
  p {
    margin: 0;
  }
}

.with-input {
  position: relative;
  input {
    box-sizing: border-box;
    width: 60px;
    text-align: center;
  }
}

.order-page__tbody {
  td:hover {
    text-decoration: none;
  }
}

td {
  text-align: center;
  transition: all 0.2s ease-in-out;
  a {
    text-decoration: none;
  }
  &.focus-on {
    position: relative;
    transition: all 0.2s ease-in-out;
    &::before {
      content: '';
      width: 100%;
      height: 100%;
      border: 1px solid #0984e3;
      position: absolute;
      left: 0;
      top: 0;
      transition: all 0.2s ease-in-out;
      cursor: text;
    }
  }
}

.order-page__presense.none {
  background: #535c68 !important;
  cursor: default;
}

.order-page__btn {
  &:disabled {
    svg {
      path {
        fill: #828282;
      }
      circle {
        stroke: #828282;
      }
    }
  }
}

.white-space {
  white-space: nowrap;
}

.remained-in-stock {
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  color: #828282;
}
</style>
