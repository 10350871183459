<template>
  <div class="order-page__col order-page__buttons">
    <div class="order-page__buttons-row">
      <button
        :class="['order-research', { 'order-research__active': avLoader }]"
        title="Проверить наличие"
        :disabled="!products.length || (type === 'other' && !order.details.length)"
        @click="getAvailability"
      >
        <div class="my-loader" v-if="avLoader"></div>
        <img src="/images/research-ic.svg" alt="research" />
      </button>

      <button class="order-up" @click="openModal" title="Переместить заказ" v-if="parseInt(order.status) === 2">
        <img src="/images/arrow-up.svg" alt="arrow-up" />
      </button>

      <button class="order-restart" v-if="parseInt(order.status) === 1" @click="setStatus(2)" title="Подтвердить заказ">
        <img src="/images/order_check.svg" alt="delete" />
      </button>

      <button class="order-delete" v-if="parseInt(order.status) !== 3" @click="setStatus(3)" title="Отменить">
        <img src="/images/delete.svg" alt="delete" />
      </button>

      <button
        class="order-restart"
        v-if="parseInt(order.status) === 3"
        @click="setStatus(1)"
        title="Возобновить приём заказов"
      >
        <img src="/images/restart.svg" alt="restart" />
      </button>
    </div>
    <div class="order-page__buttons-row">
      <button
        @click="print"
        title="Распечатать акт"
        :disabled="!products.length || parseInt(order.status) === 1 || parseInt(order.status) === 3"
      >
        <img src="/images/print.svg" alt="print" />
      </button>
      <button
        :class="{ emailLoader }"
        @click="toEmail"
        title="Отправить заказ на почту"
        :disabled="!products.length || parseInt(order.status) === 3"
      >
        <img src="/images/mailto.svg" alt="mailto" />
        <div class="my-loader" v-if="emailLoader"></div>
      </button>
      <button
        :disabled="!products.length || parseInt(order.status) !== 2"
        @click="createRoute"
        title="Отправить заказ в маршрут"
      >
        <img src="/images/delivery.svg" alt="delivery" />
      </button>
    </div>
    <button
      :class="['success--btn', { 'btn-load': transferLoad && transfer.notOrder !== id }]"
      :disabled="transfer.products.length && transfer.notOrder === id"
      @click="transferProducts"
    >
      Внести позиции
      <div class="my-loader" v-if="transferLoad && transfer.notOrder !== id"></div>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    flightId: {
      type: Number,
      default: 0,
    },

    id: {
      type: Number,
      default: 0,
    },

    type: {
      type: String,
      default: 'single',
    },

    index: {
      type: Number,
      default: 0,
    },

    without: {
      type: String,
      default: '0',
    },
  },

  computed: {
    avLoader() {
      return this.$store.getters['availabilityLoader']
    },

    transfer() {
      return this.$store.getters['transfer']
    },

    order() {
      if (this.type === 'single') {
        return this.$store.getters['order']
      } else {
        const other = this.$store.getters['otherOrder']
        return other[this.index]
      }
    },

    products() {
      if (this.type === 'single') {
        return this.$store.getters['orderProducts']
      } else {
        return this.order.details || []
      }
    },

    transferLoad() {
      return this.$store.getters['transferLoad']
    },
  },

  data: () => ({
    emailLoader: false,
  }),

  methods: {
    openModal() {
      this.$emit('openTransfer')
    },

    transferProducts() {
      const data = {
        toOrder: this.id,
        notOrder: this.transfer.notOrder,
        products: this.transfer.products,
        orderType: this.type,
        flightId: this.flightId,
        without: this.without,
      }
      this.$store.dispatch('transferProducts', data)
    },

    print() {
      window.open(`${this.axios.defaults.baseURL}/api/admin/orders/${this.id}/report`, '_blank')
    },

    async toEmail() {
      this.$emit('sendOrderToEmail', this.id)
    },

    getAvailability() {
      this.availabilityShow = true
      this.$store.dispatch('checkAvailability', this.id)
    },

    createRoute() {
      this.$emit('openWaybillModal')
    },

    async setStatus(status) {
      await this.axios
        .patch(`/api/admin/orders/${this.id}/status?status=${status}`)
        .then((res) => {
          if (this.type === 'single') {
            this.$store.dispatch('updateOrder', res.data.data)
          } else {
            this.$store.dispatch('updateOtherOrder', {
              flightId: this.flightId,
              without: this.$route.params.id,
            })
          }
        })
        .catch((err) => console.log(err.message))
    },
  },
}
</script>

<style lang="scss" scoped>
.emailLoader {
  position: relative;
  img {
    display: none;
  }
  .my-loader {
    left: 0;
    top: 6px;
    right: 0;
    margin: 0 auto;
  }
}
.order-research__active {
  position: relative;
  &:hover {
    background-color: #ffd200 !important;
  }
  .my-loader {
    border-color: rgba(#fff, 0.2);
    border-top-color: #fff;
    margin: 0 auto;
    top: 6px;
    left: 0;
  }
  img {
    display: none;
  }
}
.success--btn {
  position: relative;
  padding: 0;
  width: 100%;
  max-width: 119px;
  height: 29px;
  color: #fff;
  background: #04b200;
  border: none;
  border-radius: 37px;
  font-size: 12px;
  line-height: 14px;
  transition: background 0.2s ease-in-out;
  &:hover {
    transition: background 0.2s ease-in-out;
    background: #038000;
  }
  &:active {
    transition: background 0.2s ease-in-out;
    background: #04b200;
  }
  &:disabled {
    border: 1px solid #828282;
    background: transparent;
    color: #828282;
  }
}
.btn-load {
  font-size: 0;
  .my-loader {
    top: 6px;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-color: rgba(#ffff, 0.2);
    border-top-color: #fff;
  }
}
</style>
