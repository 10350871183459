import Vue from 'vue'

const state = () => ({
  searchQuery: '',
  flightId: 0,
  flightOrders: null,
  flights: { data: [] },
  flightLoadAnim: false,
  flightSearchAnim: false,
  flightFilters: {
    byStatus: {
      boolean: false,
      data: [],
    },
    byDiapasons: {
      boolean: false,
      data: [],
    },
    byNumbers: {
      boolean: false,
      data: [],
    },
  },
  flightSearch: '',
})

const getters = {
  getSearchQuery: (state) => state.searchQuery,

  flightSearch: (state) => state.flightSearch,

  flights: (state) => state.flights, // все рейсы

  flightOrders: (state) => state.flightOrders, // заказы в конкретном рейсе

  loadingAnimation: (state) => state.flightLoadAnim,

  flightSearchAnim: (state) => state.flightSearchAnim,

  flightFilters: (state) => state.flightFilters,
}

const actions = {
  saveSearchQuery({ commit }, query) {
    commit('SAVE_SEARCH_QUERY', query)
  },

  // поиск по рейсу
  searchByFlight({ commit, state }, data) {
    commit('CHANGE_FLIGHT_ANIMATE_STATUS')
    commit('SET_FLIGHT_SEARCH', data.input)
    let link = `/api/admin/flights/${data.id}/orders?paginate=10&withCountableFields=1&search=${data.input}`
    const filters = state.flightFilters

    if (filters.byStatus.boolean) {
      filters.byStatus.data.forEach((status) => {
        link += `&statuses[]=${status}`
      })
    }

    if (filters.byDiapasons.boolean) {
      filters.byDiapasons.data.forEach((diapason, i) => {
        link += `&ranges[${i}][0]=${diapason.first}&ranges[${i}][1]=${diapason.second}`
      })
    }

    if (filters.byNumbers.boolean) {
      filters.byNumbers.data.forEach((num, index) => {
        link += `&numbers[${index}]=${num.trim()}`
      })
    }

    Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_FLIGHT', res.data.data)
        commit('CHANGE_FLIGHT_ANIMATE_STATUS')
      })
      .catch((err) => console.log(err.message))
  },

  saveFlights({ commit }, data) {
    commit('SAVE_FLIGHTS', data)
  },

  flightResetSearch({ commit }) {
    commit('FLIGHT_RESET_SEARCH_QUERY')
    commit('RESET_FLIGHT_FILTERS')
  },

  async filterByStatus({ commit, state }, data) {
    let statuses = []
    // check statuses
    if (data.statuses.worked) statuses.push(1)
    if (data.statuses.end) statuses.push(2)
    if (data.statuses.canceled) statuses.push(3)
    if (data.statuses.waybill) statuses.push(4)
    if (data.statuses.waybillEnd) statuses.push(5)

    let link = `/api/admin/flights/${data.id}/orders?paginate=10&withCountableFields=1&orderByDesc=1`

    if (state.flightSearch.length) {
      link += `&search=${state.flightSearch}`
    }

    statuses.forEach((status) => {
      link += `&statuses[]=${status}`
    })

    const filters = state.flightFilters

    if (filters.byStatus.boolean) {
      filters.byStatus.data.forEach((status) => {
        link += `&statuses[]=${status}`
      })
    }

    if (filters.byDiapasons.boolean) {
      filters.byDiapasons.data.forEach((diapason, i) => {
        link += `&ranges[${i}][0]=${diapason.first}&ranges[${i}][1]=${diapason.second}`
      })
    }

    if (filters.byNumbers.boolean) {
      filters.byNumbers.data.forEach((num, index) => {
        link += `&numbers[${index}]=${num.trim()}`
      })
    }

    commit('SET_FLIGHT_FILTER_BY_STATUS', {
      boolean: true,
      data: statuses,
    })

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_FLIGHT', res.data.data)
      })
      .catch((err) => console.log(err.message))
  },

  async filterByDiapasons({ commit, state }, data) {
    let link = `/api/admin/flights/${data.id}/orders?paginate=10&withCountableFields=1&orderByDesc=1`

    const diapasons = data.diapasons

    diapasons.forEach((diapason, i) => {
      link += `&ranges[${i}][0]=${diapason.first}&ranges[${i}][1]=${diapason.second}`
    })

    if (state.flightSearch.length) {
      link += `&search=${state.flightSearch}`
    }

    const filters = state.flightFilters

    if (filters.byStatus.boolean) {
      filters.byStatus.data.forEach((status) => {
        link += `&statuses[]=${status}`
      })
    }

    if (filters.byDiapasons.boolean) {
      filters.byDiapasons.data.forEach((diapason, i) => {
        link += `&ranges[${i}][0]=${diapason.first}&ranges[${i}][1]=${diapason.second}`
      })
    }

    if (filters.byNumbers.boolean) {
      filters.byNumbers.data.forEach((num, index) => {
        link += `&numbers[${index}]=${num.trim()}`
      })
    }

    commit('SET_FLIGHT_FILTER_BY_DIAPASONS', {
      boolean: true,
      data: diapasons,
    })

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_FLIGHT', res.data.data)
      })
      .catch((err) => console.log(err.message))
  },

  async filterByNumbers({ commit, state }, data) {
    let link = `/api/admin/flights/${data.id}/orders?paginate=10&withCountableFields=1&orderByDesc=1`

    if (state.flightSearch.length) {
      link += `&search=${state.flightSearch}`
    }

    const numbers = data.numbers.split(',')
    numbers.forEach((num, index) => {
      link += `&numbers[${index}]=${num.trim()}`
    })

    const filters = state.flightFilters

    if (filters.byStatus.boolean) {
      filters.byStatus.data.forEach((status) => {
        link += `&statuses[]=${status}`
      })
    }

    if (filters.byDiapasons.boolean) {
      filters.byDiapasons.data.forEach((diapason, i) => {
        link += `&ranges[${i}][0]=${diapason.first}&ranges[${i}][1]=${diapason.second}`
      })
    }

    if (filters.byNumbers.boolean) {
      filters.byNumbers.data.forEach((num, index) => {
        link += `&numbers[${index}]=${num.trim()}`
      })
    }

    commit('SET_FLIGHT_FILTER_BY_NUMBERS', {
      boolean: true,
      data: numbers,
    })

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_FLIGHT', res.data.data)
      })
      .catch((err) => console.log(err.message))
  },

  async getFlights({ commit, state }) {
    commit('RESET_FLIGHT_FILTERS')
    const page = state.flights.current_page
    let link = `/api/admin/flights?paginate=11&withCountableFields=1&orderByDesc=1`

    if (page) {
      link += `&page=${page}`
    }

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_FLIGHTS', res.data.data)
      })
      .catch((err) => console.log(err.message))
  },

  nextPage({ commit, state }) {
    const nextPage = state.flights.current_page + 1
    const lastPage = state.flights.last_page
    const link = `/api/admin/flights?page=${nextPage}&paginate=11&withCountableFields=1&orderByDesc=1`
    if (nextPage <= lastPage) {
      Vue.axios
        .get(link)
        .then((res) => {
          commit('CHANGE_PAGE', res.data.data)
        })
        .catch((err) => console.log(err.message))
    }
  },

  prevPage({ commit, state }) {
    const prevPage = state.flights.current_page - 1
    const link = `/api/admin/flights?page=${prevPage}&paginate=11&withCountableFields=1&orderByDesc=1`
    if (prevPage >= 1) {
      Vue.axios
        .get(link)
        .then((res) => {
          commit('CHANGE_PAGE', res.data.data)
        })
        .catch((err) => console.log(err.message))
    }
  },

  prevSearchPage({ commit, state }, query) {
    commit('CHANGE_FLIGHT_SEARCH_ANIMATE_STATUS', true)
    const prevPage = state.flights.current_page - 1
    const link = `/api/admin/flights?page=${prevPage}&paginate=5&search=${query}`
    if (prevPage >= 1) {
      Vue.axios
        .get(link)
        .then((res) => {
          commit('CHANGE_PAGE', res.data.data)
          commit('CHANGE_FLIGHT_SEARCH_ANIMATE_STATUS', false)
        })
        .catch((err) => console.log(err.message))
    }
  },

  nextSearchPage({ commit, state }, query) {
    commit('CHANGE_FLIGHT_SEARCH_ANIMATE_STATUS', true)
    const nextPage = state.flights.current_page + 1
    const lastPage = state.flights.last_page
    const link = `/api/admin/flights?page=${nextPage}&paginate=5&search=${query}`
    if (nextPage <= lastPage) {
      Vue.axios
        .get(link)
        .then((res) => {
          commit('CHANGE_PAGE', res.data.data)
          commit('CHANGE_FLIGHT_SEARCH_ANIMATE_STATUS', false)
        })
        .catch((err) => console.log(err.message))
    }
  },

  async goToOrdersPage({ commit, state }, obj) {
    let link = `/api/admin/flights/${obj.id}/orders?page=${obj.pageId}&paginate=10&withCountableFields=1&orderByDesc=1`

    const filters = state.flightFilters

    if (filters.byStatus.boolean) {
      filters.byStatus.data.forEach((status) => {
        link += `&statuses[]=${status}`
      })
    }

    if (filters.byDiapasons.boolean) {
      filters.byDiapasons.data.forEach((diapason, i) => {
        link += `&ranges[${i}][0]=${diapason.first}&ranges[${i}][1]=${diapason.second}`
      })
    }

    if (filters.byNumbers.boolean) {
      filters.byNumbers.data.forEach((num, index) => {
        link += `&numbers[${index}]=${num.trim()}`
      })
    }

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('CHANGE_ORDERS_PAGE', res.data)
      })
      .catch((err) => console.log(err.message))
  },

  toggleFlight({ commit, state }, id) {
    commit('RESET_FLIGHT_FILTERS')
    commit('FLIGHT_RESET_SEARCH_QUERY')
    // commit("SAVE_FLIGHT", null);
    const page = state.flightOrders ? state.flightOrders.current_page : false
    let link = `/api/admin/flights/${id}/orders?paginate=10&withCountableFields=1&orderByDesc=1`

    if (page) {
      link += `&page=${page}`
    }

    if (state.flightSearch.length) {
      link += `&search=${state.flightSearch}`
    }

    Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_FLIGHT', res.data.data)
        commit('SAVE_FLIGHT_ID', id)
      })
      .catch((err) => console.log(err.message))
  },

  async getSearchQuery({ commit, dispatch, state }, query) {
    commit('CHANGE_FLIGHT_SEARCH_ANIMATE_STATUS', true)
    let link = `/api/admin/flights?paginate=5&search=${query}&orderByDesc=1`
    if (state.flights.current_page) {
      link += `&page=${state.flights.current_page}`
    }
    await Vue.axios
      .get(link)
      .then((res) => {
        dispatch('saveFlights', res.data.data)
        commit('CHANGE_FLIGHT_SEARCH_ANIMATE_STATUS', false)
      })
      .catch((err) => console.log(err.message))
  },

  async orderUpdateInFlight({ dispatch, state }) {
    const id = state.flightId
    if (!id) return
    dispatch('toggleFlight', id)
  },
}

const mutations = {
  // сохраняем все рейсы
  SAVE_FLIGHTS: (state, data) => {
    state.flights = data
  },

  SAVE_FLIGHT_ID: (state, id) => {
    state.flightId = id
  },

  // пагинация рейса
  CHANGE_PAGE: (state, data) => {
    state.flights = data
  },

  // пагинация заказов в рейсе
  CHANGE_ORDERS_PAGE: (state, res) => {
    state.flightOrders.current_page = res.data.current_page
    state.flightOrders.data = res.data.data
  },

  // сохраняем рейс, который открыт
  SAVE_FLIGHT: (state, data) => {
    state.flightOrders = data
  },

  CHANGE_FLIGHT_SEARCH_ANIMATE_STATUS: (state, status) => {
    state.flightSearchAnim = status
  },

  CHANGE_FLIGHT_ANIMATE_STATUS: (state) => {
    state.flightLoadAnim = !state.flightLoadAnim
  },

  FLIGHT_RESET_SEARCH_QUERY: (state) => {
    state.flightSearch = ''
    state.flightId = 0
  },

  SET_FLIGHT_SEARCH: (state, query) => {
    state.flightSearch = query
  },

  SET_FLIGHT_FILTER_BY_STATUS: (state, data) => {
    state.flightFilters.byStatus = data
  },

  SET_FLIGHT_FILTER_BY_DIAPASONS: (state, data) => {
    state.flightFilters.byDiapasons = data
  },

  SET_FLIGHT_FILTER_BY_NUMBERS: (state, data) => {
    state.flightFilters.byNumbers = data
  },

  SAVE_SEARCH_QUERY(state, query) {
    state.searchQuery = query
  },

  RESET_FLIGHT_FILTERS: (state) => {
    state.flightFilters = {
      byStatus: {
        boolean: false,
        data: [],
      },
      byDiapasons: {
        boolean: false,
        data: [],
      },
      byNumbers: {
        boolean: false,
        data: [],
      },
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
