import Vue from 'vue'

const state = {
  user: JSON.parse(localStorage.getItem('auth.user')),
  token: localStorage.getItem('auth.token'),
}

const getters = {
  user: (state) => state.user,
  token: (state) => state.token,
  isAuthenticated: (state) => !!state.user,
}

const mutations = {
  setToken(state, token) {
    state.token = token
    localStorage.setItem('auth.token', token)
  },

  setUser(state, user) {
    state.user = user
    localStorage.setItem('auth.user', JSON.stringify(user))
  },

  logout(state) {
    state.user = null
    state.token = null
    localStorage.removeItem('auth.token')
    localStorage.removeItem('auth.user')
  },
}

const actions = {
  async loginAction({ commit }, user) {
    let response = await Vue.axios.post('/api/site/token', {
      grant_type: 'password',
      username: user.email,
      password: user.password,
      client_id: '2',
      client_secret: 'BDyMWqbbeongJWKCpK1b0COusWKS0SdlJ3HXa3dU',
      scope: '',
      lifetime_type: 1,
    })
    commit('setToken', response.data.access_token)
    response = await Vue.axios.get('/api/site/account')
    commit('setUser', response.data.data)
  },

  logoutAction({ commit }) {
    commit('logout')
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
