<template>
  <div class="filter--wrap">
    <div class="ya-table__filter" @click="openModal">
      <h4>Фильтры</h4>
      <img src="/images/filter-icon.svg" alt="filter-icon" />
    </div>
    <div class="filter--status">
      <div class="filter--status_item" v-if="filters.byNumbers.boolean">
        <span>По номеру</span>
        <img src="/images/delete-table.svg" alt="remove" @click="resetFilters" />
      </div>
      <div class="filter--status_item" v-if="filters.byDiapasons.boolean">
        <span>По диапазону</span>
        <img src="/images/delete-table.svg" alt="remove" @click="resetFilters" />
      </div>
      <div class="filter--status_item" v-if="filters.byStatus.boolean">
        <span>По статусу</span>
        <img src="/images/delete-table.svg" alt="remove" @click="resetFilters" />
      </div>
    </div>
    <Modal v-if="visible && modalType === 'filter'" @closePopup="closeModal">
      <div class="modal-filter">
        <ul class="modal-filter__navbar">
          <li :class="{ active: tab === 0 }" @click="changeTab(0)">По номеру</li>
          <li :class="{ active: tab === 1 }" @click="changeTab(1)">По диапазону</li>
          <li :class="{ active: tab === 2 }" @click="changeTab(2)">По статусу</li>
        </ul>
        <div class="modal-filter__body">
          <template v-if="tab === 0">
            <textarea
              :class="['modal-filter__textarea', { error: textAreaError }]"
              name="area"
              id="filterArea"
              placeholder="Введите номера через запятую  /  8937, 8932, 8931"
              v-model="textArea"
              @keyup="checkTextArea"
            ></textarea>
          </template>

          <template v-if="tab === 1">
            <div class="modal-filter__diapasons">
              <div class="diapason" v-for="diapason in diapasons" :key="diapason.index">
                <input type="number" placeholder="От" min="1" max="9999" v-model="diapason.first" />
                <span>-</span>
                <input type="number" placeholder="До" min="0" max="9999" v-model="diapason.second" />
                <span
                  class="diapason-reset"
                  v-if="diapason.first && diapason.second && diapason.first.length && diapason.second.length"
                  @click="deleteDiapason(diapason)"
                >
                  <img src="/images/close-icon.svg" alt="reset" />
                </span>
              </div>
              <button class="diapason-add" v-if="diapasons.length < 6" @click="addDiapason">
                <img src="/images/plus.svg" alt="plus" />
              </button>
            </div>
          </template>

          <template v-if="tab === 2">
            <ul class="modal-filter__checkboxes">
              <li class="ended">
                <input type="checkbox" id="worked" v-model="checkboxes.worked" />
                <label for="worked">В ожидании</label>
              </li>
              <li class="worked">
                <input type="checkbox" id="ended" v-model="checkboxes.end" />
                <label for="ended">Подтверждён</label>
              </li>
              <li class="canceled">
                <input type="checkbox" id="canceled" v-model="checkboxes.canceled" />
                <label for="canceled">Отклонён</label>
              </li>
              <li class="way-yellow">
                <input type="checkbox" id="waybill" v-model="checkboxes.waybill" />
                <label for="waybill">В маршруте</label>
              </li>
              <li class="way-end">
                <input type="checkbox" id="waybillEnd" v-model="checkboxes.waybillEnd" />
                <label for="waybillEnd">Завершён</label>
              </li>
            </ul>
          </template>
        </div>
        <div class="modal-filter__bottom">
          <button class="button outlined" @click="resetFilters" :disabled="disabled">Сбросить фильтры</button>
          <button class="button primary" @click="applyFilter" :disabled="disabled">Применить фильтры</button>
        </div>
      </div>
    </Modal>
    <div class="blocker" v-if="visible" @click="closeModal"></div>
  </div>
</template>
<script>
import Modal from '@/components/tools/Modal.vue'

export default {
  components: {
    Modal,
  },

  props: {
    flightId: {
      type: Number,
      default: 0,
    },
    modalType: {
      type: String,
      default: () => 'filter',
    },
    element: {
      type: Object,
      default: () => {},
    },
  },

  data: () => ({
    visible: false,
    tab: 0,
    textArea: '',
    textAreaError: false,
    diapasons: [
      {
        first: '',
        second: '',
      },
    ],
    checkboxes: {
      worked: false,
      canceled: false,
      end: false,
      waybill: false,
      waybillEnd: false,
    },
  }),

  computed: {
    filters() {
      return this.$store.getters['flightFilters']
    },

    disabled: {
      get: function () {
        if (
          this.checkboxes.worked ||
          this.checkboxes.end ||
          this.checkboxes.canceled ||
          this.checkboxes.waybill ||
          this.checkboxes.waybillEnd ||
          this.diapasons.length > 1 ||
          (this.diapasons[0].first.length && this.diapasons[0].second.length) ||
          this.textAreaError === false
        ) {
          return false
        }
        return true
      },
      set: function () {
        if (
          this.checkboxes.worked ||
          this.checkboxes.end ||
          this.checkboxes.canceled ||
          this.checkboxes.waybill ||
          this.checkboxes.waybillEnd ||
          this.diapasons.length > 1 ||
          (this.diapasons[0].first.length && this.diapasons[0].second.length) ||
          this.textAreaError === false
        ) {
          return false
        }
        return true
      },
    },
  },

  methods: {
    applyFilter() {
      if (this.tab === 0) {
        this.$store.dispatch('filterByNumbers', {
          id: this.flightId,
          numbers: this.textArea,
        })
      }

      if (this.tab === 1) {
        this.$store.dispatch('filterByDiapasons', {
          id: this.flightId,
          diapasons: this.diapasons,
        })
      }

      if (this.tab === 2) {
        this.$store.dispatch('filterByStatus', {
          statuses: this.checkboxes,
          id: this.flightId,
        })
      }
    },

    closeModal() {
      this.$emit('closePopup')
      this.visible = false
    },

    deleteDiapason(diapason) {
      if (this.diapasons.length <= 1) {
        diapason.first = ''
        diapason.second = ''
        return
      }
      const index = this.diapasons.indexOf(diapason)
      this.diapasons.splice(index, 1)
      diapason.first = ''
      diapason.second = ''
    },

    addDiapason() {
      this.diapasons.push({
        first: '',
        second: '',
      })
    },

    clearAll() {
      this.textArea = ''
      this.checkboxes = {
        worked: false,
        canceled: false,
        end: false,
      }
      this.disabled = true
      this.diapasons = [
        {
          first: '',
          second: '',
        },
      ]
    },

    resetFilters() {
      this.clearAll()
      this.$store.dispatch('toggleFlight', this.flightId)
    },

    changeTab(id) {
      this.tab = id
      this.clearAll()
    },

    checkTextArea() {
      if (!this.textArea.length) {
        this.textAreaError = false
        return
      }

      const text = this.textArea.split(',')
      const filter = text.filter((el) => /^\d{1,}$/.test(el))
      const check = text.join(',') === filter.join(',')

      if (check) {
        this.textAreaError = false
      } else {
        this.textAreaError = true
      }
    },

    openModal() {
      this.visible = true
    },
  },
}
</script>

<style lang="scss" scoped>
.filter--wrap {
  display: flex;
  align-items: center;
  gap: 22px;
}

.filter--status {
  display: flex;
  gap: 15px;
  font-size: 14px;
  line-height: 16px;
  color: #0984e3;
  &_item {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  img {
    width: 9px;
    height: 9px;
    object-fit: contain;
    cursor: pointer;
  }
}
.way-yellow {
  color: #f1c40f;
}
.way-end {
  font-weight: 500;
}
</style>
