<template>
  <div>
    <Modal v-if="data" @closePopup="closeModal">
      <div class="modal-presense">
        <h2>{{ data.name }}</h2>
        <span>{{ data.description }}</span>
        <span
          ><a :href="`/catalog/product/${data.vendor_code}`" target="_blank">{{ data.vendor_code }}</a>
          <p>/</p>
          <a :href="data.link" target="_blank"> <img src="/images/share-icon.svg" alt="share" /> </a
        ></span>
        <div class="modal-grid">
          <div class="modal-row" v-for="item in data.availabilities" :key="item.id">
            <p
              :class="[
                { green: item.stock >= 20 },
                { yellow: item.stock >= 1 && item.stock < 20 },
                { red: !item.stock },
              ]"
            >
              {{ item.store_title }}
            </p>
            <p>{{ item.stock || 0 }}</p>
          </div>
          <div class="modal-row">
            <p :class="[{ green: total >= 20 }, { yellow: total >= 1 && total < 20 }, { red: !total }]">Всего</p>
            <p>{{ total }}</p>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '@/components/tools/Modal.vue'

export default {
  components: { Modal },

  computed: {
    total() {
      if (!this.data) return 0
      const availability = this.data.availabilities
      let total = 0

      availability.forEach((i) => {
        total += i.stock
      })

      return total
    },
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  methods: {
    closeModal() {
      this.$emit('closeAvailability')
    },
  },
}
</script>
<style lang="scss" scoped>
.modal-row {
  p {
    background-color: transparent !important;
  }
}
</style>
