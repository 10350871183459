<template>
  <div>
    <h2>Удалить товар(ы)?</h2>
    <div class="remove-modal__check">
      <input type="checkbox" ref="rmcheckbox" />
      <label for="del" @click="toggle">Удалить позицию из базы данных</label>
    </div>
    <div class="remove-modal__bottom">
      <button class="outlined" @click="closeModal">Отмена</button>
      <button :class="['button', 'primary', { 'modal-loader': loader }]" @click="removeElement">
        Удалить
        <div class="my-loader" v-if="loader"></div>
      </button>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'order',
    },

    removeId: {
      type: Number,
      default: 0,
    },

    selected: {
      type: Array,
      default: () => [],
    },

    orderType: {
      type: String,
      default: 'single',
    },

    orderId: {
      type: Number,
      default: 0,
    },

    without: {
      type: String,
      default: '0',
    },

    flightId: {
      type: Number,
      default: 0,
    },

    searchInput: {
      type: String,
      default: '',
    },

    search: {
      type: Boolean,
      default: false,
    },

    query: {
      type: String,
      default: '',
    },
  },

  data: () => ({
    rmFromBase: false,
    loader: false,
  }),

  methods: {
    closeModal() {
      this.$emit('closeRemoveModal')
    },

    toggle() {
      const input = this.$refs.rmcheckbox
      input.checked = !input.checked
      this.rmFromBase = input.checked
    },

    removeElement() {
      if (this.type === 'flight' && !this.search) {
        this.loader = true
        if (this.removeId) {
          this.$store.dispatch('removeElementFromOrder', {
            flightId: this.flightId,
            orderType: this.orderType,
            orderId: this.orderId,
            id: this.removeId,
            removeFromBase: this.rmFromBase,
            searchInput: this.searchInput,
          })
        }
        setTimeout(() => {
          this.loader = false
          this.closeModal()
        }, 1200)
      }
      if (this.type === 'flight' && this.search) {
        this.loader = true
        if (this.removeId) {
          this.$store.dispatch('removeElementFromOrder', {
            query: this.query,
            flightId: this.flightId,
            orderType: 'searchPage',
            orderId: this.orderId,
            id: this.removeId,
            removeFromBase: this.rmFromBase,
            searchInput: this.searchInput,
          })
        }
        setTimeout(() => {
          this.loader = false
          this.closeModal()
        }, 1200)
      }
      if (this.type === 'order') {
        this.loader = true
        if (this.removeId) {
          this.$store.dispatch('removeElementFromOrder', {
            flightId: this.flightId,
            without: this.without,
            orderType: this.orderType,
            orderId: this.orderId,
            id: this.removeId,
            removeFromBase: this.rmFromBase,
          })
        } else {
          this.$store.dispatch('removeElementsFromOrder', {
            flightId: this.flightId,
            without: this.without,
            orderType: this.orderType,
            orderId: this.orderId,
            id: this.removeId,
            removeFromBase: this.rmFromBase,
            products: this.selected,
          })
        }
        setTimeout(() => {
          this.loader = false
          this.closeModal()
        }, 1200)
      }
      if (this.type === 'warehouse') {
        this.loader = true
        if (this.removeId) {
          this.$store.dispatch('storageProductRemove', {
            id: this.removeId,
            removeFromBase: this.rmFromBase,
          })
        } else {
          this.$store.dispatch('storageProductsRemove', {
            id: this.removeId,
            removeFromBase: this.rmFromBase,
            products: this.selected,
          })
          this.$emit('clearSelected')
        }
        setTimeout(() => {
          this.loader = false
          this.closeModal()
        }, 2000)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.remove-modal {
  max-height: 275px;

  .modal-loader {
    position: relative;
    cursor: default;
    font-size: 0;
    .my-loader {
      border-top-color: #fff;
      right: 0;
      left: 0;
      margin: 0 auto;
      top: 12px;
    }
  }

  &__check {
    margin-bottom: 37px;
    label {
      font-size: 14px;
      line-height: 16px;
      color: #828282;
      padding-left: 60px !important;
    }
    input[type='checkbox']:not(:checked) + label:before {
      width: 20px !important;
      height: 20px !important;
      top: -3px !important;
      left: 28px !important;
    }
    input[type='checkbox']:checked + label:before {
      width: 20px !important;
      height: 20px !important;
      top: -3px !important;
      left: 28px !important;
    }
    input[type='checkbox']:checked + label:after,
    input[type='checkbox']:not(:checked) + label:after {
      top: 0.5px !important;
      left: 31px !important;
    }
  }

  &__bottom {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 11px;
    // padding: 0 30px;
    button {
      min-height: 40px;
      width: 100%;
      font-size: 12px;
      line-height: 14px;
    }
  }

  h2 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 29px;
    text-align: center;
  }
}
</style>
