import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Vuetify from 'vuetify'
import VueMask from 'v-mask'
import App from './App.vue'
import store from './store'
import router from './router'
import { API_URL } from './constants.js'
import './utils/echo'
import 'vuetify/dist/vuetify.min.css'

axios.defaults.baseURL = API_URL
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'

axios.interceptors.request.use(
  function (config) {
    config.headers.common['Authorization'] = `Bearer ${store.getters.token}`
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // todo: hack for CORS, change to error.response.status === 401
    if (!error?.response) {
      store.dispatch('logoutAction')
      return router.push('/login')
    } else {
      if (error.response?.data?.message) {
        store.commit('newError', error.response.data.message)
      }
      if (error.response?.data?.errors?.[0]) {
        store.commit('newError', error.response.data.errors[0])
      }
    }
    return Promise.reject(error)
  }
)

Vue.use(VueAxios, axios)
Vue.use(Vuetify)
Vue.use(VueMask)

Vue.config.productionTip = false

new Vue({
  render: (h) => h(App),
  store,
  router,
}).$mount('#app')
