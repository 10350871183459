<template>
  <div class="main-order-page">
    <Order
      :order="mainOrder"
      :flightId="parseInt($route.params.flightId)"
      @orderUpdate="orderUpdate"
      @productsOrderUpdate="productsOrderUpdate"
    />
    <template v-if="otherOrders.length">
      <div class="other-phone-orders">
        Остальные заказы по номеру: <span>{{ phone }}</span>
      </div>
      <div class="other-order" v-for="(item, index) in otherOrders" :key="index">
        <Order
          :order="otherOrders[index]"
          :index="index"
          type="other"
          :flightId="parseInt($route.params.flightId)"
          :without="$route.params.id"
          @otherOrderUpdate="orderUpdate('other')"
          @productsOtherOrderUpdate="productsOrderUpdate"
        />
      </div>
    </template>
  </div>
</template>

<script>
import Order from '@/components/order/Order.vue'

export default {
  components: { Order },

  computed: {
    mainOrder() {
      return this.$store.getters['order']
    },

    otherOrders() {
      const orders = this.$store.getters['otherOrder']
      if (orders) return orders
      else return []
    },

    phone() {
      const order = this.$store.getters['order']
      if (order) {
        return order.phone
      } else return false
    },
  },

  async mounted() {
    await this.getOrder()
    await this.getOrderProducts()
    await this.$store.dispatch('getFlights')
    await this.getOtherOrders()
  },

  methods: {
    async getOtherOrders() {
      this.$store.dispatch('getOtherOrders', {
        flightId: this.$route.params.flightId,
        without: this.$route.params.id,
      })
    },

    async orderUpdate(type) {
      if (type === 'other') {
        await this.getOtherOrders()
      } else {
        await this.getOrder()
        await this.getOrderProducts()
      }
    },

    async productsOrderUpdate(type) {
      if (type === 'single') {
        await this.getOrder()
        await this.getOrderProducts()
      } else {
        await this.getOtherOrders()
      }
    },

    async getOrder() {
      await this.$store.dispatch('getOrder', this.$route.params.id)
    },

    async getOrderProducts() {
      await this.$store.dispatch('getOrderProducts', this.$route.params.id)
    },
  },
}
</script>

<style lang="scss">
.other-phone-orders {
  font-weight: 700;
  color: #000;
  font-size: 14px;
  line-height: 16px;
  padding: 22px 0;
  background-color: #fff;
  padding-left: 32px;
  border-bottom: 1px solid #dadada;
  span {
    font-weight: 400;
  }
}
.main-order-page {
  padding-bottom: 30px;
}
</style>
