<template>
  <v-container fluid>
    <v-card class="pa-4 mb-3">
      <carousel :perPage="1" :navigationEnabled="true" :navigateTo="position">
        <slide v-for="(month, monthYear) in calendar" :key="monthYear">
          <table class="planning_table">
            <thead>
              <tr>
                <th colspan="7">{{ monthName(monthYear) }}</th>
              </tr>
              <tr>
                <th>П</th>
                <th>В</th>
                <th>С</th>
                <th>Ч</th>
                <th>П</th>
                <th>С</th>
                <th>В</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(days, index) in month" :key="index">
                <td
                  @click="changeActiveDay(days[day].date)"
                  style="cursor: pointer"
                  v-for="day in ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']"
                  :key="day"
                >
                  <span
                    v-if="days[day]"
                    :class="{
                      weekend_day: day == 'Sun',
                      delivery_day: days[day].delivery,
                      active_day: days[day].active,
                    }"
                  >
                    <img v-if="days[day].delivery" src="/images/delivery_day__icon_15x15.png" style="width: 15px" />
                    <span v-else>{{ days[day].day }}</span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </slide>
      </carousel>
    </v-card>
    <v-card v-if="day">
      <div>
        <v-card-title primary-title>
          <v-flex align-end>
            <span class="headline font-weight-medium">{{ day.fullDate }}</span>
            <span v-if="day.delivery.date" class="ml-2 grey--text subheading">(рейс)</span>
          </v-flex>
        </v-card-title>
        <v-list v-if="day.delivery.date && day.orderCount > 0">
          <v-list-tile d-flex align-center>
            <span class="font-weight-medium">Заказов:</span>
            <span class="ml-2 grey--text underline">{{ day.orderCount }}</span>
          </v-list-tile>
          <v-list-tile d-flex align-center>
            <span class="font-weight-medium">Заказано товаров на сумму:</span>
            <span class="ml-2 grey--text underline">{{ day.orderTotal }} руб.</span>
          </v-list-tile>
        </v-list>
      </div>

      <v-card-text v-if="day.delivery.date">
        <v-layout row wrap>
          <v-flex xs12 sm12 md12 lg6>
            <div class="row">
              <div class="form-group">
                <div>Доставка по Минску С</div>
                <date-picker
                  value-type="format"
                  format="DD.MM.YYYY"
                  v-model="day.delivery.minsk_date_from_edit"
                ></date-picker>
              </div>
              <div class="form-group ml-5">
                <div>Доставка по Минску ПО</div>
                <date-picker
                  value-type="format"
                  format="DD.MM.YYYY"
                  v-model="day.delivery.minsk_date_to_edit"
                ></date-picker>
              </div>
            </div>
            <div class="row">
              <div class="form-group">
                <div>Доставка по РБ С</div>
                <date-picker
                  value-type="format"
                  format="DD.MM.YYYY"
                  v-model="day.delivery.country_date_from_edit"
                ></date-picker>
              </div>
              <div class="form-group ml-5">
                <div>Доставка по РБ ПО</div>
                <date-picker
                  value-type="format"
                  format="DD.MM.YYYY"
                  v-model="day.delivery.country_date_to_edit"
                ></date-picker>
              </div>
            </div>
          </v-flex>
          <v-flex xs12 sm12 md12 lg6>
            <div>
              <v-select
                v-model="day.delivery.suppliers"
                :items="suppliers"
                item-text="title"
                return-object
                attach
                chips
                label="Поставщики"
                multiple
              ></v-select>
            </div>
          </v-flex>
        </v-layout>
      </v-card-text>

      <v-card-actions class="d-flex">
        <div class="form-group" v-show="day.delivery.date && day.orderCount > 0">
          <div>Дата переноса</div>
          <date-picker value-type="format" format="DD.MM.YYYY" v-model="moveDeliveryDate"></date-picker>
        </div>

        <v-btn @click="moveDelivery" v-if="day.delivery.id && day.orderCount > 0" color="warning">Перенести </v-btn>
        <v-btn @click="cancelDelivery" v-if="day.delivery.id && day.orderCount == 0" color="error">Отменить </v-btn>
        <v-btn @click="newDelivery" v-if="currentDate" color="success">
          {{ day.delivery.id ? 'Сохранить' : 'Запланировать' }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

export default {
  components: {
    Carousel,
    Slide,
    DatePicker,
  },

  data() {
    return {
      position: 0,
      moveDeliveryDate: '',
      day: null,
      minsk_date_from: '',
      minsk_date_to: '',
      country_date_from: '',
      country_date_to: '',
      currentDate: false,
      calendar: [],
      monthArray: [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
      ],
      suppliers: [],
    }
  },

  mounted() {
    this.loadPlanning()
    this.loadSuppliers()
  },

  methods: {
    monthName(monthYear) {
      let [year, month] = monthYear.split('-')
      return this.monthArray[month - 1] + ' ' + year
    },

    loadPlanning() {
      this.axios.get('/api/planning').then((response) => {
        this.calendar = response.data.calendar
        this.position = new Date().getMonth()
      })
    },

    async changeActiveDay(date) {
      this.currentDate = date
      const response = await this.axios.get(`/api/planning/day/${date}`)
      this.day = response.data.info
      this.day.delivery = this.day.delivery || {
        date: this.currentDate,
        status: 0,
        minsk_date_from: '',
        minsk_date_to: '',
        country_date_from: '',
        country_date_to: '',
      }
    },

    newDelivery() {
      this.axios
        .post('/api/planning/save', {
          date: this.day,
        })
        .then(() => {
          this.loadPlanning()
          this.changeActiveDay(this.currentDate)
        })
    },

    cancelDelivery() {
      this.axios.get('/api/planning/cancel/' + this.currentDate).then(() => {
        this.loadPlanning()
        this.changeActiveDay(this.currentDate)
      })
    },

    moveDelivery() {
      this.axios.get('/api/planning/move/' + this.currentDate + '/' + this.moveDeliveryDate).then(() => {
        this.loadPlanning()
        this.changeActiveDay(this.currentDate)
        this.moveDeliveryDate = ''
      })
    },

    async loadSuppliers() {
      const response = await this.axios.get('/api/admin/suppliers')
      this.suppliers = response.data.data
    },
  },
}
</script>

<style scoped>
.planning_table {
  width: 100%;
  text-align: center;
}

.row {
  display: flex;
  margin-bottom: 20px;
}

td:hover {
  text-decoration: underline;
}
</style>
