<template>
  <div class="waybill-colors">
    <div class="waybill-colors__close" @click="closeModal">
      <img src="/images/close-icon.svg" alt="close" />
    </div>
    <h3 class="waybill-colors__title">Цвет</h3>
    <ul class="waybill-colors__list">
      <li :style="{ backgroundColor: color }" v-for="color in colors" :key="color" @click="pick(color)"></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'ColorPicker',

  props: {
    id: {
      type: Number,
      default: 0,
    },

    flightId: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    colors: [
      'rgb(131, 18, 70)',
      'rgb(165, 40, 22)',
      'rgb(230, 81, 0)',
      'rgb(245, 169, 40)',
      'rgb(252, 216, 0)',
      'rgb(129, 119, 22)',
      'rgb(84, 139, 46)',
      'rgb(5, 116, 55)',
      'rgb(1, 95, 102)',
      'rgb(10, 87, 159)',
      'rgb(24, 33, 129)',
      'rgb(104, 55, 185)',
      'rgb(80, 51, 45)',
      'rgb(204, 18, 89)',
      'rgb(253, 82, 80)',
      'rgb(240, 126, 4)',
      'rgb(244, 198, 34)',
      'rgb(252, 237, 0)',
      'rgb(173, 181, 45)',
      'rgb(124, 178, 72)',
      'rgb(14, 156, 92)',
      'rgb(7, 147, 170)',
      'rgb(1, 139, 206)',
      'rgb(53, 76, 170)',
      'rgb(161, 37, 163)',
      'rgb(115, 87, 74)',
      'rgb(189, 189, 189)',
      'rgb(117, 117, 117)',
      'rgb(66, 66, 66)',
      'rgb(0, 0, 0)',
    ],
  }),

  methods: {
    async pick(color) {
      const data = {
        id: this.id,
        query: 'region_color',
        value: color,
      }

      await this.$store.dispatch('orderSetColor', data)
      await this.$store.dispatch('getWaybillAllOrders', this.flightId)
      await this.closeModal()
    },

    closeModal() {
      this.$emit('closeModal')
    },
  },
}
</script>

<style lang="scss" scoped>
.waybill-colors {
  position: fixed;
  top: 25%;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-color: #f7f7f7;
  width: 100%;
  max-width: 450px;
  min-height: 200px;
  padding: 15px 45px 45px 45px;
  z-index: 5;

  &__title {
    font-size: 14px;
    line-height: 160%;
    margin-bottom: 8px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    background-color: #fff;
    width: 100%;
    padding: 20px;
    gap: 5px;

    li {
      width: 20px;
      height: 20px;
      cursor: pointer;
      transition: opacity 0.2s ease-in-out;

      &:active {
        transition: opacity 0.2s ease-in-out;
        opacity: 0.8;
      }
    }
  }

  &__close {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid #ff7776;
    width: 20px;
    height: 20px;
    position: absolute;
    right: 13px;
    top: 13px;
    cursor: pointer;

    img {
      width: 15px;
      height: 15px;
      object-fit: contain;
    }
  }
}
</style>
