<template>
  <div class="transfer-modal">
    <ul class="transfer-modal__body">
      <li class="transfer-modal__item" v-for="item in waybills" :key="item.id">
        <div class="transfer-modal__input">
          <input
            type="radio"
            ref="radio"
            name="transfer"
            :data-id="item.id"
            :id="`radio-${item.id}`"
            @change="checkInput"
          />
          <label :for="`radio-${item.id}`"></label>
        </div>
        <div class="transfer-modal__name">
          <b>{{ item.name }}</b>
        </div>
      </li>
    </ul>
    <div class="waybill--time">
      <masked-input ref="timeFirst" placeholder="00:00" mask="11:11" v-model="time.first" />
      <span>-</span>
      <masked-input ref="timeSecond" placeholder="00:00" mask="11:11" v-model="time.second" />
    </div>
    <div class="waybill--comment">
      <input ref="comment" type="text" placeholder="Комментарий" v-model="comment" />
    </div>
    <div class="transfer-modal__bottom">
      <button :disabled="disabled" :class="['button', 'primary', { loading }]" @click="check">
        Перенести заказ
        <div class="my-loader" v-if="loading"></div>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MaskedInput from 'vue-masked-input'

export default {
  props: {
    flightId: {
      type: Number,
      default: 0,
    },
    order: {
      type: Object,
      default: () => {},
    },
  },

  components: { MaskedInput },

  data: () => ({
    loading: false,
    disabled: true,
    time: {
      first: '',
      second: '',
    },
    waybillId: 0,
    comment: '',
  }),

  computed: {
    waybills() {
      return this.$store.getters['waybillsData']
    },
  },

  methods: {
    checkInput(e) {
      const checked = e.target.checked
      this.waybillId = parseInt(e.target.getAttribute('data-id'))

      if (checked) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },

    closeModal() {
      this.$emit('closeModal')
    },

    async check() {
      const id = this.waybillId
      const time = this.time
      const comment = this.comment
      const phone = this.order.phone
      const order_id = this.order.id
      const total = this.order.total
      const name = this.order.name
      const city = this.order.delivery_city
      let address = this.order.delivery_address_full
      const lifting = {
        status: this.order.delivery_lifting,
        cost: this.order.delivery_lifting_cost || 0,
        loaders: this.order.loaders_count || 0,
        helpers: this.order.helpers_count || 0,
      }

      if (city.length) {
        address = `${city}, ${this.order.delivery_address_full}`
      }

      if (id && time.first.length && time.second.length) {
        this.loading = true
        await this.createWaybill({
          id,
          time,
          comment,
          phone,
          order_id,
          address,
          total,
          name,
          lifting,
        })
      } else if (!time.first.length) {
        this.$refs.timeFirst.focus()
      } else if (!time.second.length) {
        this.$refs.timeSecond.focus()
      }
    },

    async createWaybill(data) {
      if (!data.name) {
        data.name = 'null'
      }

      let link = `/api/admin/waybills/order/add?waybill_id=${data.id}&address=${data.address}&phone=${data.phone}&time=${data.time.first}-${data.time.second}&order_id=${data.order_id}&total=${data.total}&name=${data.name}&flight_id=${this.flightId}`

      if (data.comment) {
        link += `&admin_comment=${data.comment}`
      }

      if (data.lifting.status) {
        link += `&delivery_floor_price=${data.lifting.cost}&delivery_floor_helper=${data.lifting.helpers}&delivery_floor_loader=${data.lifting.loaders}&is_delivery_floor=1`
      }

      await axios
        .post(link)
        .then(() => {
          this.loader = false
          axios
            .patch(`/api/admin/orders/${data.order_id}/status?status=4`)
            .then(() => {
              this.closeModal()
              setTimeout(() => (window.location.href = `/waybills/${this.flightId}`), 500)
            })
            .catch((err) => console.log(err))
        })
        .catch((err) => console.log(err))
    },
  },

  mounted() {
    this.$store.dispatch('getWaybills', this.flightId)
  },
}
</script>

<style lang="scss" scoped>
.order-page .transfer-modal .transfer-modal__body {
  max-height: 201px;
}
.order-page .transfer-modal__item {
  margin: 0;
}
.button {
  height: 40px;
  &.loading {
    position: relative;
    font-size: 0;
    .my-loader {
      top: 11px;
      left: 0;
      right: 0;
      margin: 0 auto;
      border-color: rgba(#fff, 0.2);
      border-top-color: #fff;
    }
  }
}
.waybill {
  &--time {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px 0;
    span {
      display: block;
      margin: 0 3px;
      color: #828282;
    }
    input {
      background: #ffffff;
      border: 1px solid #ededed;
      width: 64px;
      height: 40px;
      font-size: 14px;
      text-align: center;
      color: #000000;
      transition: border-color 0.2s ease-in-out;
      &:focus {
        transition: border-color 0.2s ease-in-out;
        border-color: #0984e3;
      }
      &::placeholder {
        color: #b2b2b2;
        font-size: 14px;
      }
    }
  }
  &--comment {
    margin-bottom: 15px;
    input {
      background: #ffffff;
      border: 1px solid #ededed;
      height: 40px;
      width: 100%;
      padding-left: 15px;
      font-size: 14px;
      color: #000000;
      transition: border-color 0.2s ease-in-out;
      &:focus {
        transition: border-color 0.2s ease-in-out;
        border-color: #0984e3;
      }
      &::placeholder {
        font-size: 14px;
        color: #b2b2b2;
      }
    }
  }
}
</style>
