<template>
  <section class="waybills">
    <div class="waybills--container">
      <div class="waybills-grid">
        <Waybill :step="step" title="Маршруты" @openModal="openModal" v-if="!step" @toggleStep="toggleStep" />
        <Waybill :step="step" title="Заказы" @openModal="openModal" v-if="step" @toggleStep="toggleStep" />
        <Map />
      </div>
    </div>
    <Modal class="waybill-modal" @closePopup="closeModal" v-if="visible">
      <CreateWaybill :flightId="parseInt($route.params.flight_id)" @createWaybill="create" @closeModal="closeModal" />
    </Modal>
    <div class="blocker" v-if="visible" @click="closeModal"></div>
  </section>
</template>

<script>
import Waybill from '@/components/waybills/Waybill.vue'
import Map from '@/components/waybills/Map.vue'
import Modal from '@/components/tools/Modal.vue'
import CreateWaybill from '@/components/tools/modals/CreateWaybill.vue'

export default {
  name: 'Waybills',

  components: { Map, Waybill, Modal, CreateWaybill },

  data: () => ({
    visible: false,
    step: 0,
  }),

  methods: {
    openModal() {
      this.visible = true
    },

    closeModal() {
      this.visible = false
    },

    async create(e) {
      await this.$store.dispatch('createWaybill', e)
    },

    toggleStep(step) {
      this.step = step
    },
  },
}
</script>

<style scoped lang="scss">
.waybills {
  overflow: hidden;
  &-grid {
    display: grid;
    grid-template-columns: 1.2fr 1fr;
  }
}
.waybill-modal {
  .ya-modal {
    max-height: 306px;
  }
}
</style>
