const state = {
  text: '',
  show: false,
}

const mutations = {
  newError(state, text) {
    state.text = text
    state.show = true
  },

  closeError(state) {
    state.show = false
  },
}

export default {
  state,
  mutations,
}
