<template>
  <v-dialog v-model="show" persistent max-width="400px">
    <v-card>
      <v-card-title v-if="value.id" class="headline grey lighten-2" primary-title
        >Редактирование поставщика</v-card-title
      >
      <v-card-title v-else class="headline grey lighten-2" primary-title>Новый поставщик</v-card-title>
      <v-card-text>
        <v-text-field v-model="value.title" box label="Поставщик" required></v-text-field>
        <v-text-field v-model="value.alias" box label="Alias" required></v-text-field>
        <v-select v-model="value.currency" box :items="CURRENCIES_SELECT" label="Валюта" required></v-select>
        <v-select v-model="value.exchange_type" box :items="EXCHANGE_RATE_TYPE" label="Курс" required></v-select>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="info" @click="close" outline>Закрыть</v-btn>
        <v-btn v-if="value.id" color="info" @click="save" :loading="loading">Сохранить</v-btn>
        <v-btn v-else color="info" @click="save" :loading="loading">Создать</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { CURRENCIES_SELECT, EXCHANGE_RATE_TYPE } from '@/constants.js'

export default {
  props: {
    value: Object,
    show: Boolean,
    loading: Boolean,
  },

  data: () => ({
    CURRENCIES_SELECT,
    EXCHANGE_RATE_TYPE,
  }),

  methods: {
    close() {
      this.$emit('close')
    },

    save() {
      this.$emit('input', this.value)
      this.$emit('save')
    },
  },
}
</script>
