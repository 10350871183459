<template>
  <div class="create-waybill">
    <div class="create-waybill__input">
      <input type="text" placeholder="Наименование маршрута" v-model="fields.name" @keyup="checkFields" />
    </div>
    <div class="create-waybill__input">
      <input type="text" placeholder="Курьер" v-model="fields.courier" @keyup="checkFields" />
    </div>
    <div class="create-waybill__input">
      <input type="text" placeholder="Менеджер" v-model="fields.manager" @keyup="checkFields" />
    </div>
    <div class="create-waybill__bottom">
      <button :class="['button', 'primary', { active: loader }]" :disabled="disabled" @click="create">
        Создать
        <div class="my-loader" v-if="loader"></div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flightId: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    loader() {
      return this.$store.getters['wayLoader']
    },
  },

  data: () => ({
    disabled: true,
    fields: {
      name: '',
      courier: '',
      manager: '',
    },
  }),

  methods: {
    checkFields() {
      const f = this.fields

      if (f.name.length && f.courier.length && f.manager.length) {
        this.disabled = false
      } else {
        this.disabled = true
      }
    },

    clear() {
      this.fields = {
        name: '',
        courier: '',
        manager: '',
      }
      setTimeout(() => {
        this.$emit('closeModal')
      }, 300)
    },

    create() {
      const data = {
        flight_id: this.flightId,
        name: this.fields.name,
        courier: this.fields.courier,
        manager: this.fields.manager,
      }

      this.$emit('createWaybill', data)
      this.clear()
    },
  },
}
</script>

<style lang="scss" scoped>
.create-waybill {
  padding-top: 45px;
  &__input {
    margin-bottom: 16px;
    input {
      background: #ffffff;
      border: 1px solid #ededed;
      font-size: 14px;
      color: #000000;
      padding-left: 8px;
      width: 100%;
      height: 40px;
      transition: all 0.2s ease-in-out;
      &::placeholder {
        font-size: 14px;
        color: #828282;
      }
      &:focus {
        border-color: #0984e3;
        transition: all 0.2s ease-in-out;
      }
    }
  }
  &__bottom {
    margin-top: 24px;
    button {
      width: 100%;
      height: 40px;
      transition: all 0.2s ease-in-out;
      &:hover {
        background-color: #0d65a9;
        transition: all 0.2s ease-in-out;
      }
      &:active {
        background-color: inherit;
        transition: all 0.2s ease-in-out;
      }
      &.active {
        font-size: 0;
        position: relative;
        .my-loader {
          left: 0;
          right: 0;
          top: 12px;
          border-color: rgba(#fff, 0.2);
          border-top-color: #fff;
          margin: 0 auto;
        }
      }
    }
  }
}
</style>
