<template>
  <v-app>
    <router-view></router-view>
    <v-snackbar v-model="show" :top="true" :multi-line="true" :timeout="10000">
      <span class="error--text">{{ text }}</span>
      <v-btn dark flat @click="close">Закрыть</v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  computed: {
    ...mapState({
      text: (state) => state.errors.text,
    }),

    show: {
      get() {
        return this.$store.state.errors.show
      },
      set() {
        this.close()
      },
    },
  },

  methods: {
    ...mapMutations({
      close: 'closeError',
    }),
  },
}
</script>
