<template>
  <v-content>
    <v-container fluid fill-height>
      <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
          <v-card class="elevation-12">
            <v-toolbar dark color="primary">
              <v-toolbar-title>Вход</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
              <v-form>
                <v-text-field prepend-icon="person" type="text" v-model="email" placeholder="Email"></v-text-field>
                <v-text-field
                  prepend-icon="lock"
                  type="password"
                  v-model="password"
                  placeholder="Пароль"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="login">Войти</v-btn>
            </v-card-actions>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  data: () => ({
    valid: false,
    email: '',
    password: '',
  }),

  methods: {
    ...mapActions(['loginAction']),
    async login() {
      try {
        await this.loginAction({
          email: this.email,
          password: this.password,
        })
        this.$router.push('/')
      } catch (e) {
        console.log(e)
      }
    },
  },
}
</script>
