<template>
  <v-navigation-drawer :value="drawer" @input="toggle" app width="200px">
    <v-toolbar flat class="transparent toolbar--nav">
      <v-list class="pa-0">
        <v-list-tile>
          <v-list-tile-content>
            <v-avatar color="red">
              <span class="white--text headline">{{ userFirstLetter }}</span>
            </v-avatar>
          </v-list-tile-content>
          <v-list-tile-content>
            <v-list-tile-title>{{ userFullName }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-toolbar>

    <v-list dense class="pt-0">
      <v-list-tile to="/main">
        <v-list-tile-content @click="resetSearchQuery">
          <v-list-tile-title>
            <v-icon class="mr-2" color="black">home</v-icon>
            <span>Главная</span>
          </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile exact to="/">
        <v-list-tile-content @click="resetSearchQuery">
          <v-list-tile-title
            ><v-icon class="mr-2" color="black">calendar_month</v-icon><span>Планирование</span></v-list-tile-title
          >
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile to="/flights">
        <v-list-tile-content @click="getFlights()">
          <v-list-tile-title
            ><v-icon class="mr-2" color="black">local_shipping</v-icon><span>Рейсы</span></v-list-tile-title
          >
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile to="/actions">
        <v-list-tile-content @click="resetSearchQuery">
          <v-list-tile-title><v-icon class="mr-2" color="black">star</v-icon><span>Акции</span></v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile to="/products/warehouse">
        <v-list-tile-content @click="resetSearchQuery">
          <v-list-tile-title
            ><v-icon class="mr-2" color="black">local_grocery_store</v-icon><span>Склад</span></v-list-tile-title
          >
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile to="/catalog">
        <v-list-tile-content @click="resetSearchQuery">
          <v-list-tile-title><v-icon class="mr-2" color="black">folder</v-icon><span>Каталог</span> </v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile to="/users">
        <v-list-tile-content @click="resetSearchQuery">
          <v-list-tile-title><v-icon class="mr-2" color="black">people</v-icon><span>Клиенты</span></v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile to="/information">
        <v-list-tile-content @click="resetSearchQuery">
          <v-list-tile-title><v-icon class="mr-2" color="black">info</v-icon><span>Информация</span></v-list-tile-title>
        </v-list-tile-content>
      </v-list-tile>

      <v-list-tile to="/promo">
        <v-list-tile-content @click="resetSearchQuery">
          <v-list-tile-title
            ><v-icon class="mr-2" color="black">discount</v-icon><span>Промокоды</span></v-list-tile-title
          >
        </v-list-tile-content>
      </v-list-tile>

      <div role="listitem">
        <a href="/cms" target="_blank" class="v-list__tile v-list__tile--link theme--light" @click="resetSearchQuery">
          <div class="v-list__tile__content">
            <div class="v-list__tile__title">
              <v-icon class="mr-2" color="black">edit</v-icon>
              <span>Управление блогом</span>
            </div>
          </div>
        </a>
      </div>

      <div role="listitem">
        <div class="v-list__tile v-list__tile--link theme--light" @click="logout">
          <div class="v-list__tile__content">
            <div class="v-list__tile__title">
              <v-icon class="mr-2" color="black">logout</v-icon>
              <span>Выход</span>
            </div>
          </div>
        </div>
      </div>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'Navigation',
  computed: {
    ...mapGetters(['drawer', 'user']),

    userFirstLetter() {
      return this.userFullName.charAt(0)
    },

    userFullName() {
      return this.user.name ? this.user.name : this.user.email
    },
  },

  methods: {
    ...mapActions(['logoutAction']),

    async logout() {
      await this.logoutAction()
      this.$router.push('/login')
    },

    toggle(value) {
      if (this.drawer != value) {
        this.$store.dispatch('toggleDrawer')
      }
    },

    resetSearchQuery() {
      this.$store.dispatch('saveSearchQuery', '')
    },

    getFlights() {
      this.resetSearchQuery()
      this.$store.dispatch('getFlights')
    },
  },
}
</script>
