<template>
  <div role="dialog">
    <div :class="['ya-modal', 'filter-modal', { 'remove-modal': type === 'remove' }]">
      <div class="ya-modal__close" @click="closePopup" v-if="type !== 'remove'">
        <img src="/images/close-icon.svg" alt="close" />
      </div>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: () => '',
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    closePopup() {
      this.$emit('closePopup')
    },
  },
}
</script>
