<template>
  <v-autocomplete
    v-model="categories"
    @change="change"
    :box="box"
    chips
    multiple
    :label="label"
    :items="categoriesCombobox"
  >
    <template v-slot:selection="data">
      <v-chip :selected="data.selected" color="info" text-color="white" close @input="remove(data.item)">
        {{ data.item.text }}
      </v-chip>
    </template>
    <template v-slot:item="{ item }">
      <span :class="`indent-${item.level}`">{{ item.text }}</span>
    </template>
  </v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    value: [],
    label: {
      type: String,
      default: 'Категории',
    },
    box: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    categories: [],
  }),

  watch: {
    value(newValue) {
      this.categories = newValue
    },
  },

  computed: {
    ...mapState({ categoriesCombobox: (state) => state.categories.categories }),
  },

  methods: {
    change() {
      this.$emit('input', this.categories)
      this.$emit('change')
    },

    remove(item) {
      const index = this.categories.indexOf(item.value)
      if (index >= 0) {
        this.categories.splice(index, 1)
      }
      this.$emit('change')
    },
  },
}
</script>

<style scoped>
.indent-0 {
  padding-left: 0;
}

.indent-1 {
  padding-left: 20px;
}

.indent-2 {
  padding-left: 40px;
}

.indent-3 {
  padding-left: 60px;
}

.indent-4 {
  padding-left: 80px;
}
</style>
