<template>
  <header :class="['ya-header', { drawer }]">
    <div class="ya-container">
      <div class="ya-header__wrap">
        <div class="ya-header__col">
          <div class="ya-burger" @click="toggleMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Search />
        </div>
        <ActionButtons />
      </div>
    </div>
  </header>
</template>

<script>
import Search from '@/components/header/Search.vue'
import ActionButtons from '@/components/header/ActionButtons.vue'
import { mapActions } from 'vuex'

export default {
  name: 'Toolbar',

  components: {
    Search,
    ActionButtons,
  },

  computed: {
    drawer() {
      return this.$store.getters['drawer']
    },
  },

  methods: {
    ...mapActions(['toggleDrawer', 'openModal']),

    toggleMenu() {
      this.$store.dispatch('toggleDrawer')
    },
  },
}
</script>
