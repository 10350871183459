var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"pagination"}},[(_vm.type === 1)?[_c('div',{staticClass:"pagination--buttons"},[_c('button',{attrs:{"disabled":_vm.flights.current_page === 1},on:{"click":_vm.prevPage}},[_vm._v(" "+_vm._s(_vm.prevText)+" ")]),_c('button',{attrs:{"disabled":_vm.flights.current_page === _vm.flights.last_page},on:{"click":_vm.nextPage}},[_vm._v(" "+_vm._s(_vm.nextText)+" ")])])]:_vm._e(),(_vm.type === 2)?[_c('div',{staticClass:"pagination--buttons"},[_c('button',{class:{ 'pagi-load': _vm.warehousePagiLoad.prev },attrs:{"disabled":_vm.warehouse.current_page === 1},on:{"click":_vm.prevPage}},[_vm._v(" "+_vm._s(_vm.prevText)+" "),(_vm.warehousePagiLoad.prev)?_c('div',{staticClass:"my-loader"}):_vm._e()]),(_vm.warehouse.last_page > 2)?_c('ul',{staticClass:"warehouse--numbers"},_vm._l((_vm.warehouse.last_page),function(index){return _c('li',{key:index,class:[
            'warehouse--numbers__item',
            { active: _vm.warehouse.current_page === index },
          ]},[_c('button',{class:{
              first: index === 1,
              last:
                index === _vm.warehouse.last_page - 1 &&
                Math.abs(index - _vm.warehouse.current_page) > 3,
              dotted:
                Math.abs(index - _vm.warehouse.current_page) > 3 &&
                index <= _vm.warehouse.last_page - 2,
            },attrs:{"disabled":index === _vm.warehouse.last_page - 1 &&
                Math.abs(index - _vm.warehouse.current_page) > 3},on:{"click":function($event){return _vm.goToWarehousePage(index)}}},[_vm._v(" "+_vm._s(index)+" ")])])}),0):_vm._e(),_c('button',{class:{ 'pagi-load': _vm.warehousePagiLoad.next },attrs:{"disabled":_vm.warehouse.current_page === _vm.warehouse.last_page},on:{"click":_vm.nextPage}},[_vm._v(" "+_vm._s(_vm.nextText)+" "),(_vm.warehousePagiLoad.next)?_c('div',{staticClass:"my-loader"}):_vm._e()])])]:_vm._e(),(_vm.type === 0 && _vm.flightOrders)?[_c('ul',{staticClass:"pagination--numbers"},_vm._l((_vm.flightOrders.last_page),function(i){return _c('li',{key:i,class:[
          'paginaion--item',
          { active: _vm.flightOrders.current_page === i },
        ],on:{"click":function($event){return _vm.goTo(i, _vm.eventId)}}},[_vm._v(" "+_vm._s(i)+" ")])}),0)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }