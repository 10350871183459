<template>
  <div id="pagination">
    <template v-if="type === 1">
      <div class="pagination--buttons">
        <button @click="prevPage" :disabled="flights.current_page === 1">
          {{ prevText }}
        </button>
        <button
          @click="nextPage"
          :disabled="flights.current_page === flights.last_page"
        >
          {{ nextText }}
        </button>
      </div>
    </template>
    <template v-if="type === 2">
      <div class="pagination--buttons">
        <button
          :class="{ 'pagi-load': warehousePagiLoad.prev }"
          @click="prevPage"
          :disabled="warehouse.current_page === 1"
        >
          {{ prevText }}
          <div class="my-loader" v-if="warehousePagiLoad.prev"></div>
        </button>
        <ul class="warehouse--numbers" v-if="warehouse.last_page > 2">
          <li
            v-for="index in warehouse.last_page"
            :key="index"
            :class="[
              'warehouse--numbers__item',
              { active: warehouse.current_page === index },
            ]"
          >
            <button
              :class="{
                first: index === 1,
                last:
                  index === warehouse.last_page - 1 &&
                  Math.abs(index - warehouse.current_page) > 3,
                dotted:
                  Math.abs(index - warehouse.current_page) > 3 &&
                  index <= warehouse.last_page - 2,
              }"
              :disabled="
                index === warehouse.last_page - 1 &&
                  Math.abs(index - warehouse.current_page) > 3
              "
              @click="goToWarehousePage(index)"
            >
              {{ index }}
            </button>
          </li>
        </ul>
        <button
          :class="{ 'pagi-load': warehousePagiLoad.next }"
          @click="nextPage"
          :disabled="warehouse.current_page === warehouse.last_page"
        >
          {{ nextText }}
          <div class="my-loader" v-if="warehousePagiLoad.next"></div>
        </button>
      </div>
    </template>
    <template v-if="type === 0 && flightOrders">
      <ul class="pagination--numbers">
        <li
          :class="[
            'paginaion--item',
            { active: flightOrders.current_page === i },
          ]"
          v-for="i in flightOrders.last_page"
          :key="i"
          @click="goTo(i, eventId)"
        >
          {{ i }}
        </li>
      </ul>
    </template>
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  props: {
    type: {
      type: Number,
      default: 1,
    },
    prev: {
      type: String,
      default: "prevPage",
    },
    next: {
      type: String,
      default: "nextPage",
    },
    prevText: {
      type: String,
      default: "Предыдущая страница",
    },
    nextText: {
      type: String,
      default: "Следующая страница",
    },
    eventId: {
      type: Number,
      default: 0,
    },
    query: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters([
      "warehouse",
      "flights",
      "flightOrders",
      "currentPage",
      "warehousePagiLoad",
    ]),
  },

  methods: {
    nextPage() {
      this.$store.dispatch(this.next, this.query);
    },

    prevPage() {
      this.$store.dispatch(this.prev, this.query);
    },

    goTo(pageId, id) {
      this.$store.dispatch("goToOrdersPage", { id, pageId });
    },

    goToWarehousePage(page) {
      this.$store.dispatch("goToWarehousePage", page);
    },
  },
};
</script>

<style lang="scss" scoped>
.pagination--buttons {
  display: flex;
  margin: 20px 0;
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0;
    margin: 0;
    outline: 0;
    background: transparent;
    cursor: pointer;
    height: 33px;
    min-width: 188px;
    font-size: 12.5px;
    line-height: 14px;
    font-weight: 400;
    margin: 0 15px;
    border: 1px solid #0984e3;
    color: #0984e3;
    transition: all 0.3s ease-in-out;
    &:hover {
      transition: all 0.3s ease-in-out;
      color: #0d65a9;
      border-color: #0d65a9;
    }
    &:active {
      transition: all 0.3s ease-in-out;
      color: #0d65a9;
      border-color: #0d65a9;
    }
    &:disabled {
      border: 1px solid #828282;
      color: #828282;
      cursor: default;
      &:hover {
        border: 1px solid #828282;
        color: #828282;
      }
    }
  }
}

.warehouse--numbers {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  button {
    background: transparent !important;
    min-width: unset !important;
    margin: 0 !important;
  }
  &__item {
    button {
      margin-right: 6px !important;
    }
    &:last-child {
      button {
        margin-right: 0;
      }
    }
    &.active {
      button {
        transition: all 0.3s ease-in-out;
        border: 1px solid #828282;
        color: #828282;
        cursor: default;
      }
      &:hover {
        button {
          color: #828282 !important;
          border-color: #828282 !important;
        }
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #0984e3;
      height: 33px;
      width: 33px;
      &.dotted {
        display: none;
      }
      &.first.dotted {
        display: block;
        margin-right: 41px !important;
        position: relative;
        &::after {
          content: "...";
          position: absolute;
          right: -26px;
          top: 10px;
          color: #828282;
        }
      }
      &.last {
        font-size: 0;
        border: none;
        &:hover {
          border: none;
        }
        &::before {
          content: "...";
          font-size: 13px;
        }
      }
    }
  }
}

.pagination--numbers {
  display: flex;
  gap: 5px;
  padding: 10px 8px 20px 8px;
  border-bottom: 0.5px solid #dadada;
}

.paginaion--item {
  display: block;
  cursor: pointer;
  font-size: 13px;
  padding: 0 6px;

  border: 1px solid #0984e3;
  color: #0984e3;
  transition: all 0.3s ease-in-out;
  &:hover {
    transition: all 0.3s ease-in-out;
    color: #0d65a9;
    border-color: #0d65a9;
  }
  &.active {
    transition: all 0.3s ease-in-out;
    border: 1px solid #828282;
    color: #828282;
    cursor: default;
    &:hover {
      color: #828282 !important;
      border-color: #828282 !important;
    }
  }
}
.pagi-load {
  font-size: 0 !important;
  position: relative;
  .my-loader {
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 7px;
  }
}

@media screen and (max-width: 920px) {
  .warehouse--numbers {
    display: none;
  }
}
</style>
