<template>
  <div class="storage">
    <div class="ya-container">
      <div class="storage-header">
        <div class="storage-col">
          <h4>Склад:</h4>
          <span v-if="items.data.warehouse_data"
            >{{ splitPrice(items.data.warehouse_data.overall_price_sum) || 0 }} BYN</span
          >
        </div>
        <TableSearch type="warehouse" class="storage-search" />
      </div>
    </div>
    <Table :items="items.data" />
    <Pagination :type="2" prev="prevWarehousePage" next="nextWarehousePage" v-if="items.last_page > 1" />
  </div>
</template>

<script>
import TableSearch from '@/components/table/TableSearch.vue'
import Table from '@/components/storage/Table.vue'
import Pagination from '@/components/tools/Pagination.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    TableSearch,
    Table,
    Pagination,
  },

  computed: {
    ...mapGetters({ items: 'warehouse' }),
  },

  mounted() {
    this.getWarehouse()

    this.$echo.channel(window.adminChannelName).listen('.WarehouseProductAdded', () => {
      this.getWarehouse()
    })

    this.$echo.channel(window.adminChannelName).listen('.WarehouseProductUpdated', () => {
      this.getWarehouse()
    })

    this.$echo.channel(window.adminChannelName).listen('.WarehouseProductDeleted', () => {
      this.getWarehouse()
    })

    this.$echo.channel(window.adminChannelName).listen('.WarehouseProductsDeleted', () => {
      this.getWarehouse()
    })
  },

  methods: {
    getWarehouse() {
      this.$store.dispatch('getWarehouse')
    },

    splitPrice(x) {
      if (typeof x === 'string') {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return x
      }
    },
  },
}
</script>
