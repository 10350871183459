var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"voyage--table mix"},[(_vm.inputSearch.length || _vm.search)?_vm._l((_vm.table.data),function(item,index){return _c('div',{key:item.id,class:{ 'search-tr': _vm.inputSearch.length || _vm.search }},[_c('table',[_c('tbody',[_c('tr',{class:[
              { 'odd-tr': _vm.inputSearch.length || _vm.search },
              {
                'even--tr': (index % 2 !== 0 && _vm.inputSearch.length) || (index % 2 !== 0 && _vm.search),
              },
            ]},[_c('td',[_c('span',{class:[
                  'bold',
                  { working: parseInt(item.status) === 2 },
                  { cancel: parseInt(item.status) === 3 },
                  { end: parseInt(item.status) === 1 },
                  { 'yellow-end': parseInt(item.status) === 4 },
                  { 'order-complete': parseInt(item.status) === 5 },
                ]},[(_vm.reserve)?_c('router-link',{attrs:{"to":`/flights/${_vm.flightId}/orders/${item.id}?reserve=1`,"target":"_blank"}},[_vm._v("№ "+_vm._s(item.id))]):_c('router-link',{attrs:{"to":`/flights/${_vm.flightId}/orders/${item.id}`,"target":"_blank"}},[_vm._v("№ "+_vm._s(item.id))])],1),_c('span',[_vm._v(_vm._s(item.name))])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(item.phone)+" ")]),_c('span',[_vm._v(" "+_vm._s(item.email)+" ")])]),_c('td',[_c('span',{staticClass:"address"},[_vm._v(" "+_vm._s(item.delivery_city)+" "),(item.delivery_address_full)?[_vm._v(" , "+_vm._s(item.delivery_address_full)+" ")]:_vm._e()],2)]),_c('td',[_c('span',{staticClass:"bold"},[_vm._v("Себестоимость")]),_c('span',[_vm._v(_vm._s(_vm.splitPrice(item.cost_price + ''))+" BYN")])]),_c('td',[_c('span',{staticClass:"bold"},[_vm._v("Наценка")]),_c('span',[_vm._v(_vm._s(item.products_overall_charge_sum)+" BYN")])]),_c('td',[_c('span',{staticClass:"bold"},[_vm._v("Курьер")]),_c('span',[_vm._v(_vm._s(item.delivery_cost)+" BYN")])]),_c('td',[_c('span',{staticClass:"bold"},[_vm._v("Подъем")]),_c('span',[(item.delivery_lifting)?[_vm._v("Да")]:[_vm._v("Нет")]],2)]),_c('td',[_c('span',{staticClass:"bold"},[_vm._v("Сборка")]),_c('span',[(item.delivery_assemble)?[_vm._v("Да")]:[_vm._v("Нет")]],2)]),_c('td',[_c('span',{staticClass:"comment"},[_vm._v(" "+_vm._s(item.comment || '')+" ")])])])])]),(_vm.inputSearch.length || _vm.search)?_c('SearchTable',{class:{ 'even--table': index % 2 === 0 },attrs:{"orderId":item.id,"products":item.details || []},on:{"openModal":_vm.openModal}}):_vm._e()],1)}):[_c('table',[_c('tbody',_vm._l((_vm.table.data),function(item){return _c('tr',{key:item.id},[_c('td',[_c('span',{class:[
                'bold',
                { working: parseInt(item.status) === 2 },
                { cancel: parseInt(item.status) === 3 },
                { end: parseInt(item.status) === 1 },
                { 'yellow-end': parseInt(item.status) === 4 },
                { 'order-complete': parseInt(item.status) === 5 },
              ]},[(_vm.reserve)?_c('router-link',{attrs:{"to":`/flights/${_vm.flightId}/orders/${item.id}?reserve=1`,"target":"_blank"}},[_vm._v("№ "+_vm._s(item.id))]):_c('router-link',{attrs:{"to":`/flights/${_vm.flightId}/orders/${item.id}`,"target":"_blank"}},[_vm._v("№ "+_vm._s(item.id))])],1),_c('span',[_vm._v(_vm._s(item.name))])]),_c('td',[_c('span',[_vm._v(" "+_vm._s(item.phone)+" ")]),_c('span',[_vm._v(" "+_vm._s(item.email)+" ")])]),_c('td',[_c('span',{staticClass:"address"},[_vm._v(" "+_vm._s(item.delivery_city)+" "),(item.delivery_address_full)?[_vm._v(" , "+_vm._s(item.delivery_address_full)+" ")]:_vm._e()],2)]),_c('td',[_c('span',{staticClass:"bold"},[_vm._v("Себестоимость")]),_c('span',[_vm._v(_vm._s(_vm.splitPrice(item.cost_price + ''))+" BYN")])]),_c('td',[_c('span',{staticClass:"bold"},[_vm._v("Наценка")]),_c('span',[_vm._v(_vm._s(item.products_overall_charge_sum)+" BYN")])]),_c('td',[_c('span',{staticClass:"bold"},[_vm._v("Курьер")]),_c('span',[_vm._v(_vm._s(item.delivery_cost)+" BYN")])]),_c('td',[_c('span',{staticClass:"bold"},[_vm._v("Подъем")]),_c('span',[(item.delivery_lifting)?[_vm._v("Да")]:[_vm._v("Нет")]],2)]),_c('td',[_c('span',{staticClass:"bold"},[_vm._v("Сборка")]),_c('span',[(item.delivery_assemble)?[_vm._v("Да")]:[_vm._v("Нет")]],2)]),_c('td',[_c('span',{staticClass:"comment"},[_vm._v(" "+_vm._s(item.comment || '')+" ")])])])}),0)])],(_vm.table.last_page > 1)?_c('Pagination',{attrs:{"type":0,"eventId":_vm.flightId}}):_vm._e(),(_vm.show)?_c('Modal',{attrs:{"type":"remove"},on:{"closePopup":_vm.closeModal}},[_c('RemoveModal',{attrs:{"type":"flight","orderType":"flight","query":_vm.query,"flightId":_vm.flightId,"search":_vm.search,"searchInput":_vm.inputSearch,"orderId":_vm.orderId,"removeId":_vm.removeId},on:{"closeRemoveModal":_vm.closeModal}})],1):_vm._e(),(_vm.show)?_c('div',{staticClass:"blocker",on:{"click":_vm.closeModal}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }