// initial state
const state = {
  drawer: localStorage.getItem('drawer') ? localStorage.getItem('drawer') : true,
}

const getters = {
  drawer: (state) => state.drawer,
}

const actions = {
  toggleDrawer({ commit }) {
    commit('toggleDrawer')
  },
}

const mutations = {
  toggleDrawer(state) {
    state.drawer = !state.drawer
    localStorage.setItem('drawer', state.drawer)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
