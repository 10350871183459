<template>
  <div class="waybill">
    <div class="waybill-header">
      <div class="waybill-wrap">
        <h1>{{ title }}</h1>
        <span>Рейс № {{ $route.params.flight_id }}</span>
      </div>
      <div class="waybill-buttons">
        <button :class="['blue--btn', { active: step }]" @click="toggleStep">
          Заказы
          <img class="waybill-icon" src="/images/waybill-icon.svg" alt="order" />
          <img class="waybill-close" src="/images/waybill-close.svg" alt="close" />
        </button>
        <button class="green--btn" @click="openModal">Создать маршрут</button>
      </div>
    </div>
    <template v-if="!step">
      <div class="waybill-elements" v-if="waybillsData">
        <div class="waybill-el" v-for="waybill in waybillsData" :key="waybill.id">
          <Element :el="waybill" />
        </div>
      </div>
    </template>
    <template v-if="step">
      <div class="waybill-order">
        <Orders :flightId="parseInt($route.params.flight_id)" />
      </div>
    </template>
  </div>
</template>

<script>
import Element from '@/components/waybills/Element.vue'
import Orders from '@/components/waybills/Orders.vue'

export default {
  name: 'Waybill',

  components: { Element, Orders },

  props: {
    title: {
      type: String,
      default: 'Маршруты',
    },
    step: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    waybillsData() {
      return this.$store.getters['waybillsData']
    },
  },

  methods: {
    async getWaybills(id) {
      await this.$store.dispatch('getWaybills', id)
    },

    openModal() {
      this.$emit('openModal')
    },

    toggleStep() {
      let step

      if (this.step) {
        step = 0
      } else {
        step = 1
      }

      this.$emit('toggleStep', step)
    },
  },

  async mounted() {
    const flight_id = await this.$route.params.flight_id
    if (flight_id) {
      await this.$store.dispatch('saveFlightId', parseInt(flight_id))
      await this.getWaybills(flight_id)
    }
  },
}
</script>

<style scoped lang="scss">
.waybill {
  padding: 19px 0 0 0;
  &-elements {
    overflow: auto;
    max-height: calc(100vh - 140px);
  }
  &-wrap {
    display: flex;
    color: #000000;
    h1 {
      font-weight: bold;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      margin-right: 18px;
    }
    span {
      font-size: 14px;
      line-height: 16px;
    }
  }
  &-icon {
    margin-left: 4px;
  }
  &-close {
    display: none;
    margin-left: 4px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #dadada;
    padding: 0 13px 11px 27px;
  }
  &-buttons {
    display: flex;
    align-items: center;
  }
}
.blue--btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #0984e3;
  font-size: 12px;
  line-height: 14px;
  color: #0984e3;
  height: 33px;
  min-width: 110px;
  margin-right: 14px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transition: all 0.2s ease-in-out;
    border: 1px solid #0d65a9;
    color: #0d65a9;
  }
  &.active {
    background: #0984e3;
    color: #fff;
    transition: all 0.2s ease-in-out;
    &:hover {
      border: 1px solid #0d65a9;
      transition: all 0.2s ease-in-out;
      background: #0d65a9;
    }
    &:active {
      border: 1px solid #0984e3;
      transition: all 0.2s ease-in-out;
      background: #0984e3;
    }
    .waybill-icon {
      display: none;
    }
    .waybill-close {
      display: block;
    }
  }
}
.green--btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #04b200;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  height: 33px;
  min-width: 137px;
  transition: all 0.2s ease-in-out;
  &:hover {
    transition: all 0.2s ease-in-out;
    background: #038000;
  }
  &:active {
    transition: all 0.2s ease-in-out;
    background: #04b200;
  }
}
</style>
