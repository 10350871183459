<template>
  <div class="waybill__orders" v-if="orders.length">
    <table class="orders-table">
      <tbody class="orders-table__body">
        <tr v-for="order in orders" :key="order.id">
          <td class="mini-td color-td">
            <span
              :style="{ backgroundColor: order.region_color }"
              class="color-circle"
              @click="openModal(order.id)"
            ></span>
          </td>
          <td>
            <span class="bold">
              <a :href="`/flights/${flightId}/orders/${order.id}`" target="_blank"> №{{ order.id }} </a>
            </span>
            {{ order.name }}
          </td>
          <td>
            {{ order.delivery_city }},
            {{ order.delivery_address_full }}
          </td>
          <td class="mini-td" :data-id="order.id" @click="copy">
            <img :data-id="order.id" src="/images/copy.svg" alt="copy" />
          </td>
        </tr>
      </tbody>
    </table>
    <ColorPicker v-if="visible" :flightId="flightId" :id="id" @closeModal="closeModal" />
    <div class="blocker" v-if="visible" @click="closeModal"></div>
  </div>
</template>

<script>
import ColorPicker from '@/components/waybills/ColorPicker.vue'

export default {
  components: { ColorPicker },

  props: {
    flightId: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    visible: false,
    id: 0,
  }),

  computed: {
    orders() {
      return this.$store.getters['allOrders']
    },
  },

  methods: {
    getOrders() {
      this.$store.dispatch('getWaybillAllOrders', this.flightId)
    },

    closeModal() {
      this.visible = false
    },

    openModal(id) {
      this.visible = true
      this.id = id
    },

    copy(e) {
      const order_id = e.target.getAttribute('data-id')

      const text = `${window.location.origin}/flights/${this.flightId}/orders/${order_id}`

      try {
        navigator.clipboard.writeText(text)
      } catch (e) {
        console.log(e)
      }
    },
  },

  async mounted() {
    await this.getOrders()
  },
}
</script>

<style lang="scss" scoped>
.color-td {
  position: relative;
}

.color-circle {
  width: 20px;
  height: 20px;
  background: #ffc700;
  border-radius: 100%;
  left: 0;
  right: 0;
  margin: 0 auto;
  position: absolute;
  cursor: pointer;
}

.orders-table {
  width: 100%;
  &__body {
    tr {
      border-bottom: 1px solid #dadada;
      font-size: 14px;
      line-height: 160%;
      color: #000;
      td {
        padding: 13px 10px 10px 10px;
        border-right: 1px solid #dadada;
        &:hover {
          text-decoration: none;
        }
        &:first-child {
          padding-left: 29px;
        }
        span.bold {
          display: inline-block;
          margin-right: 10px;
          font-weight: bold;
          color: #04b300;
          a {
            text-decoration: none;
            color: inherit;
          }
        }
        &.mini-td {
          text-align: center;
          width: 50px;

          img {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
