<template>
  <table class="order-page__table" v-if="products.length">
    <thead class="order-page__thead">
      <tr>
        <th>Артикул</th>
        <th style="text-align: left">Описание</th>
        <th>Кол-во</th>
        <th>Кол. факт</th>
        <th>Цена RUB</th>
        <th>Цена общ. RUB</th>
        <th>Ком. %</th>
        <th>Фикс. BYN</th>
        <th>Ст-ть дост. RUB</th>
        <th>Курс</th>
        <th>Ст-ть, BYN</th>
        <th style="min-width: 47px"></th>
      </tr>
    </thead>
    <tbody class="order-page__tbody">
      <tr class="order-page__tr" v-for="product in products" :key="product.id">
        <td>
          <a :href="`/catalog/product/${product.vendor_code}`" target="_blank">
            {{ product.vendor_code }}
          </a>
        </td>
        <td class="td-desc">{{ product.model.type || product.description }}</td>
        <td>{{ product.qty || 0 }}</td>
        <td>{{ product.actual_quantity || 0 }}</td>
        <td>{{ product.price || 0 }}</td>
        <td>{{ product.total || 0 }}</td>
        <td>-</td>
        <td>{{ product.options.fix_price || 0 }}</td>
        <td>{{ product.options.delivery_cost || 0 }}</td>
        <td>{{ product.exchange || 0 }}</td>
        <td>{{ product.options.price_byn || 0 }}</td>
        <td style="min-width: 47px">
          <img
            class="delete-td"
            src="/images/delete-table.svg"
            alt="delete"
            @click="emitOpenModal({ removeId: product.id, orderId: orderId })"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>
<script>
export default {
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    orderId: {
      type: Number,
      default: 0,
    },
  },

  methods: {
    emitOpenModal(data) {
      this.$emit('openModal', data)
    },
  },
}
</script>

<style lang="scss" scoped>
tbody {
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}
td {
  padding: 15px 6px;
  text-align: center;
  &:hover {
    text-decoration: none;
  }
  a {
    color: #0984e3;
    text-decoration: none;
  }
}
.td-desc {
  min-width: 424px;
  text-align: left;
}
.even--table {
  .order-page__thead,
  .order-page__tr {
    background-color: #fff;
  }
}
.order-page__tr td:nth-child(3) {
  color: inherit;
}
.delete-td {
  cursor: pointer;
}
</style>
