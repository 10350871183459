import axios from 'axios'

const state = {
  brands: [],
}

const mutations = {
  updateBrands(state, brands) {
    state.brands = brands
  },
}

const actions = {
  async fetchBrands({ commit }) {
    const response = await axios.get('/api/admin/brands')
    commit('updateBrands', response.data.data)
  },
}

export default {
  state,
  actions,
  mutations,
}
