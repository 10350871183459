<template>
  <div class="home">
    <template v-if="$route.query.search">
      <OrderTable :search="true" />
    </template>
    <template v-else>
      <OrderTable :openFlight="flightId" />
    </template>
  </div>
</template>

<script>
import OrderTable from '@/components/table/OrderTable.vue'

export default {
  components: {
    OrderTable,
  },

  data: () => ({
    flightId: 0,
  }),

  mounted() {
    this.flightId = parseInt(this.$route.query.open) || 0
  },
}
</script>
