<template>
  <div class="transfer-modal">
    <ul class="transfer-modal__body">
      <li class="transfer-modal__item" v-for="item in flights" :key="item.id">
        <div class="transfer-modal__input">
          <input
            type="radio"
            ref="radio"
            name="transfer"
            :data-id="item.id"
            :id="`radio-${item.id}`"
            @change="checkInput"
          />
          <label :for="`radio-${item.id}`"></label>
        </div>
        <div class="transfer-modal__name">
          <b v-if="item.is_reserve">Резерв</b>
          <b v-if="!item.is_reserve">Рейс №{{ item.id }}</b>
          <span v-if="!item.is_reserve">{{ item.date }}</span>
          <p v-if="!item.is_reserve">
            <template v-if="item.status === 0"> (В ожидании) </template>
            <template v-if="item.status === 1"> (Идёт приём заказов) </template>
            <template v-if="item.status === 2"> (Завершён) </template>
          </p>
        </div>
      </li>
    </ul>
    <div class="transfer-modal__bottom">
      <button :class="['button', 'primary', { loading }]" :disabled="!disabled" @click="orderTransfer">
        Перенести заказ
        <div class="my-loader" v-if="loading"></div>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    flights() {
      return this.$store.getters['flights'].data || []
    },
  },

  props: {
    orderId: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'transfer',
    },
    selected: {
      type: Array,
      default: () => [],
    },
    flightId: {
      type: Number,
      default: 0,
    },
    currentFlightId: {
      type: Number,
      default: 0,
    },
  },

  data: () => ({
    disabled: false,
    id: '',
    loading: false,
  }),

  methods: {
    async orderTransfer() {
      this.loading = true
      if (this.type === 'transfer') {
        await this.axios
          .patch(`/api/admin/orders/${this.orderId}/flight?flight_id=${this.id}`)
          .then(() => {
            setTimeout(() => {
              this.loading = false
              location.href = `/flights/${this.id}/orders/${this.orderId}`
            }, 1000)
          })
          .catch((err) => console.log(err.message))
      } else if (this.type === 'transferProducts') {
        this.$store.dispatch('transferProductsToFlight', {
          id: this.orderId,
          flightId: this.id,
          currentFlightId: this.currentFlightId,
          products: this.selected,
        })
        setTimeout(() => {
          this.loading = true
          this.closeModal()
        }, 1500)
      }
    },

    checkInput(e) {
      const id = e.target.dataset.id
      this.id = id
      const elems = this.$refs.radio
      let count = 0

      elems.forEach((el) => {
        if (el.checked) count++
      })

      if (count > 0) {
        this.disabled = true
      } else {
        this.disabled = false
      }
    },

    closeModal() {
      this.$emit('closeTransfer')
    },
  },
}
</script>

<style lang="scss" scoped>
.loading {
  position: relative;
  font-size: 0;
  .my-loader {
    border-color: rgba(#fff, 0.2);
    border-top-color: #fff;
    left: 0;
    right: 0;
    margin: 0 auto;
    top: 8px;
  }
}
</style>
