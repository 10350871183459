<template>
  <div :class="['ya-table__search', { search_active: flightSearch.length }]">
    <button class="search_close" @click="resetInput">
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M7.0358 6.15685L9.67445 3.5182L8.7949 2.63865L6.15625 5.2773L3.5176 2.63865L2.63805 3.5182L5.2767 6.15685L2.63805 8.79551L3.5176 9.67506L6.15625 7.0364L8.7949 9.67506L9.67445 8.79551L7.0358 6.15685Z"
          fill="#FF4544"
        />
      </svg>
    </button>
    <input
      ref="searchInput"
      type="text"
      placeholder="Поиск"
      v-model="flightSearch"
      @keyup="checkSearch"
      @keyup.enter="search"
    />
    <button class="search_button" @click="search" v-if="!loading">
      <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M8.34428 3.08162C9.78645 1.63946 12.1247 1.63946 13.5668 3.08162C15.009 4.52378 15.009 6.86199 13.5668 8.30415C12.1247 9.74632 9.78645 9.74632 8.34428 8.30415C6.90212 6.86199 6.90212 4.52378 8.34428 3.08162ZM14.981 1.66741C12.7578 -0.555803 9.15328 -0.555803 6.93007 1.66741C5.00285 3.59463 4.74627 6.55985 6.16032 8.76346L0.292861 14.6309C-0.0976645 15.0214 -0.0976646 15.6546 0.292859 16.0451C0.683384 16.4357 1.31655 16.4357 1.70707 16.0451L7.51947 10.2327C9.75058 11.9258 12.945 11.7544 14.981 9.71837C17.2042 7.49516 17.2042 3.89062 14.981 1.66741Z"
          fill="#C4C4C4"
        />
      </svg>
    </button>
    <div class="my-loader" v-if="loading"></div>
  </div>
</template>
<script>
export default {
  props: {
    flightId: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'flight',
    },
  },

  computed: {
    loading() {
      if (this.type === 'flight') {
        return this.$store.getters['loadingAnimation']
      }
      if (this.type === 'warehouse') {
        return this.$store.getters['storageLoading']
      }
      return false
    },
  },

  data: () => ({
    flightSearch: '',
  }),

  methods: {
    search() {
      if (this.type === 'flight') {
        if (!this.flightSearch.length) {
          this.$refs.searchInput.focus()
          this.$emit('searchByFlight', false)
          return
        }

        this.$store.dispatch('searchByFlight', {
          id: this.flightId,
          input: this.flightSearch.toLowerCase(),
        })

        setTimeout(() => this.$emit('searchByFlight', true), 1500)
        return
      } else if (this.type === 'warehouse') {
        this.$store.dispatch('searchByWarehouse', this.flightSearch)
        return
      }
    },

    checkSearch() {
      if (!this.flightSearch.length && this.type !== 'warehouse') {
        this.$store.dispatch('toggleFlight', this.flightId)
      }
      return
    },

    resetInput() {
      this.flightSearch = ''
      this.$emit('searchByFlight', false)
      this.$store.dispatch('flightResetSearch')
      if (this.type === 'warehouse') {
        this.$store.dispatch('getWarehouse')
      }
      this.checkSearch()
    },
  },
}
</script>
