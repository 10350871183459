<template>
  <div class="ya-table" v-if="flights.data.length">
    <transition-group name="fade">
      <VoyageElement
        v-for="flight in flights.data"
        :searchProp="search"
        :query="query"
        :key="flight.id"
        :flight="flight"
        :openFlight="openFlight"
        @openPopup="visible = true"
        @getFlightOrders="toggleVoyage"
      />
    </transition-group>
    <Modal v-if="visible" @closePopup="visible = false">
      <CreateItem />
    </Modal>
    <div class="blocker" v-if="visible" @click="visible = false"></div>
    <template v-if="search">
      <Pagination prev="prevSearchPage" next="nextSearchPage" :query="query" />
    </template>
    <template v-else>
      <Pagination prev="prevPage" next="nextPage" />
    </template>
  </div>
</template>
<script>
import VoyageElement from '@/components/table/VoyageElement.vue'
import Modal from '@/components/tools/Modal.vue'
import CreateItem from '@/components/tools/modals/CreateItem.vue'
import Pagination from '@/components/tools/Pagination.vue'

export default {
  components: {
    VoyageElement,
    Modal,
    CreateItem,
    Pagination,
  },

  props: {
    openFlight: {
      type: Number,
      default: 0,
    },

    search: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    visible: false,
    query: '',
  }),

  computed: {
    flights() {
      return this.$store.getters['flights']
    },
  },

  mounted() {
    if (!this.search) {
      this.$store.dispatch('getFlights')
    } else {
      this.getSearchQuery()
    }

    this.$echo.channel(window.adminChannelName).listen('.FlightStatusChanged', () => {
      if (!this.search) {
        this.$store.dispatch('getFlights')
      } else {
        this.getSearchQuery()
      }
    })

    this.$echo.channel(window.adminChannelName).listen('.OrderStatusChanged', () => {
      this.$store.dispatch('orderUpdateInFlight')
    })

    this.$echo.channel(window.adminChannelName).listen('.OrderCreated', () => {
      if (!this.search) {
        this.$store.dispatch('getFlights')
      } else {
        this.getSearchQuery()
      }
    })
  },

  methods: {
    async getSearchQuery() {
      this.query = this.$route.query.search
      this.$store.dispatch('getSearchQuery', this.query)
    },

    toggleVoyage(id) {
      this.$store.dispatch('toggleFlight', id)
    },
  },
}
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
</style>
