<template>
  <v-container fluid>
    <div class="information-page">
      <v-card>
        <v-card-title>
          <span class="headline">Информация</span>
        </v-card-title>
      </v-card>
      <v-tabs class="box-shadow" color="gray" light slider-color="black">
        <v-tab v-for="(page, index) in pages" :key="index">{{ page.title }}</v-tab>
        <v-tab>Контакты</v-tab>
        <v-tab>Города</v-tab>
        <v-tab-item v-for="(page, index) in pages" :key="page.id">
          <v-card class="info__content">
            <v-form>
              <span class="headline">Блоки</span>
              <v-card v-for="(block, blockIndex) in page.blocks" :key="'block' + block.id">
                <v-card>
                  <v-textarea
                    :error-messages="error(block, 'title')"
                    label="Название блока"
                    v-model="block.title"
                    hint="Вводите название"
                  ></v-textarea>
                </v-card>
                <v-card>
                  <v-textarea
                    :error-messages="error(block, 'text')"
                    label="Текст блока"
                    v-model="block.text"
                    hint="Вводите текст"
                  ></v-textarea>
                </v-card>
                <v-flex d-flex justify-end>
                  <v-btn @click="deleteBlock(index, blockIndex)" color="error">Удалить блок</v-btn>
                </v-flex>
              </v-card>
              <v-flex d-flex justify-end>
                <v-btn @click="addBlock(index)" color="success">Добавить блок</v-btn>
              </v-flex>

              <v-textarea
                :error-messages="error(page, 'text')"
                label="Текст страницы"
                v-model="page.text"
                hint="Вводите текст"
              ></v-textarea>
              <v-checkbox label="Отображать" v-model="page.visible" id="visible"></v-checkbox>
              <v-flex d-flex justify-end>
                <v-btn @click="savePage(index)" color="error">Сохранить</v-btn>
              </v-flex>
            </v-form>
          </v-card>
        </v-tab-item>
        <v-tab-item class="contacts-tab">
          <v-form class="d-flex contacts-tab-form">
            <div class="pa-2 contacts-col">
              <div class="contacts-info">
                <v-container grid-list-lg>
                  <v-layout v-for="(phone, index) in contacts.phones" :key="index" row>
                    <v-flex xs3>
                      <v-text-field v-model="phone.phone" label="Телефон"></v-text-field>
                    </v-flex>
                    <v-flex xs4>
                      <v-select v-model="phone.type" :items="TYPE" label="Тип" required></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field v-model="phone.description" label="Описание"></v-text-field>
                    </v-flex>
                    <v-flex xs1 class="align-center">
                      <v-btn flat icon small @click="removePhone(index)" color="error">
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-btn @click="addPhone" class="add-btn ml-0">еще</v-btn>
                </v-container>
                <v-container grid-list-lg>
                  <v-layout v-for="(email, index) in contacts.emails" :key="index">
                    <v-flex xs3>
                      <v-text-field v-model="email.email" label="Email"></v-text-field>
                    </v-flex>
                    <v-flex xs4>
                      <v-select v-model="email.type" :items="TYPE" label="Тип" required></v-select>
                    </v-flex>
                    <v-flex xs4>
                      <v-text-field v-model="email.description" label="Описание"></v-text-field>
                    </v-flex>
                    <v-flex xs1>
                      <v-btn flat icon small @click="removeEmail(index)" color="error">
                        <v-icon>clear</v-icon>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                  <v-btn @click="addEmail" class="add-btn ml-0">еще</v-btn>
                </v-container>
                <v-container grid-list-lg>
                  <v-layout>
                    <v-flex xs10>
                      <v-text-field
                        label="Facebook"
                        v-model="contacts.social.facebook.link"
                        class="new-input-230"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-switch v-model="contacts.social.facebook.status"></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs10>
                      <v-text-field
                        label="Telegram"
                        v-model="contacts.social.telegram.link"
                        class="new-input-230"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-switch v-model="contacts.social.telegram.status"></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs10>
                      <v-text-field
                        label="VK"
                        v-model="contacts.social.vkontakte.link"
                        class="new-input-230"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-switch v-model="contacts.social.vkontakte.status"></v-switch>
                    </v-flex>
                  </v-layout>
                  <v-layout>
                    <v-flex xs10>
                      <v-text-field
                        label="Instagram"
                        v-model="contacts.social.instagram.link"
                        class="new-input-230"
                      ></v-text-field>
                    </v-flex>
                    <v-flex xs2>
                      <v-switch v-model="contacts.social.instagram.status"></v-switch>
                    </v-flex>
                  </v-layout>
                </v-container>
              </div>
            </div>

            <div class="pa-2 mt-4 contacts-col">
              <div>
                <v-text-field v-model="contacts.work_time" label="Рабочее время"></v-text-field>
              </div>

              <div>
                <v-text-field v-model="contacts.address" label="Пункт самовывоза"></v-text-field>
              </div>

              <div>
                <v-text-field v-model="contacts.map" label="Карта"></v-text-field>
              </div>

              <div>
                <v-btn @click="saveContacts" color="success" class="ml-0">Сохранить</v-btn>
              </div>
            </div>
          </v-form>
        </v-tab-item>
        <v-tab-item>
          <v-form class="d-flex">
            <v-flex xs6 class="pa-2">
              <div class="city-table">
                <div class="box-table">
                  <div class="box-tr">
                    <div class="box-td">Город</div>
                    <div class="box-td">Стоимость</div>
                    <div class="box-td">Бесплатно при сумме заказа BYN</div>
                    <div class="box-td"></div>
                  </div>
                  <v-card class="box-tr city_item" v-for="(city, index) in cities" :key="index">
                    <div class="box-td">
                      <v-text-field class="input-text-center" v-model="city.name"></v-text-field>
                    </div>
                    <div class="box-td">
                      <v-text-field class="new-input-50" v-model="city.price"></v-text-field>
                    </div>
                    <div class="box-td">
                      <v-text-field v-model="city.freeShippingFrom"></v-text-field>
                    </div>
                    <div class="box-td">
                      <v-icon @click="removeCity(index)">delete_forever</v-icon>
                    </div>
                  </v-card>
                </div>
              </div>

              <div class="add-city" @click="addCity()">Добавить город</div>
            </v-flex>
            <v-flex class="mt-5" d-flex justify-end>
              <v-btn @click="saveCities" color="success">Сохранить</v-btn>
            </v-flex>
          </v-form>
        </v-tab-item>
      </v-tabs>
    </div>
  </v-container>
</template>

<script>
const TYPE = [
  { text: 'Основной', value: 'main' },
  { text: 'Постпродажи', value: 'post_sale' },
]

export default {
  data() {
    return {
      pages: [],
      cities: [],
      contacts: {
        map: '',
        phones: [],
        vibers: [],
        emails: [],
        social: {
          facebook: {
            link: '',
            status: false,
          },
          telegram: {
            link: '',
            status: false,
          },
          vkontakte: {
            link: '',
            status: false,
          },
          instagram: {
            link: '',
            status: false,
          },
        },
        address: '',
        work_time: '',
      },
      TYPE,
    }
  },

  mounted() {
    this.loadContacts()
    this.loadCities()
    this.loadPages()
  },

  methods: {
    addPhone() {
      this.contacts.phones.push({ phone: '', type: 'main', description: '' })
    },

    addEmail() {
      this.contacts.emails.push({ email: '', type: 'main', description: '' })
    },

    addCity() {
      this.cities.push({ name: '', price: '', freeShippingFrom: '' })
    },

    removePhone(index) {
      this.contacts.phones = this.contacts.phones.filter((item, pos) => pos != index)
    },

    removeEmail(index) {
      this.contacts.emails = this.contacts.emails.filter((item, pos) => pos != index)
    },

    removeCity(index) {
      this.cities = this.cities.filter((item, pos) => pos != index)
    },

    saveContacts() {
      this.axios.post('/api/settings/contacts', { value: this.contacts })
    },

    saveCities() {
      this.axios.post('/api/settings/cities', { value: this.cities })
    },

    async loadContacts() {
      const response = await this.axios.get('/api/settings/contacts')
      this.contacts = response.data.value
    },

    async loadCities() {
      const response = await this.axios.get('/api/settings/cities')
      this.cities = response.data.value
    },

    loadPages() {
      this.axios.get('/api/pages').then((response) => {
        this.pages = response.data
      })
    },

    addBlock(index) {
      this.pages[index].blocks.push({
        id: null,
        title: '',
        text: '',
      })
    },

    deleteBlock(index, blockIndex) {
      this.pages[index].blocks.splice(blockIndex, 1)
    },

    savePage(index) {
      this.pages[index].errors = false
      this.axios.post('/api/pages', { ...this.pages[index] }).then((response) => {
        if (!response.data.success) {
          this.pages[index].errors = response.data.errors
        }
      })
    },

    error(target, field) {
      return target.errors && target.errors[field] ? target.errors[field] : []
    },
  },
}
</script>
