<template>
  <div :class="['ya-search', { 'search--active': query.length }]">
    <button class="ya-search__clear-btn" @click="clear" v-if="query.length">
      <img src="/images/delete-table.svg" alt="clear" />
    </button>
    <input
      ref="input"
      type="text"
      placeholder="Поиск по рейсам"
      :value="query"
      @change="changeQuery"
      @keyup.enter="search"
    />
    <div class="my-loader" v-if="loading"></div>
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      @click="search"
      v-if="!loading"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8.34428 3.08162C9.78645 1.63946 12.1247 1.63946 13.5668 3.08162C15.009 4.52378 15.009 6.86199 13.5668 8.30415C12.1247 9.74632 9.78645 9.74632 8.34428 8.30415C6.90212 6.86199 6.90212 4.52378 8.34428 3.08162ZM14.981 1.66741C12.7578 -0.555803 9.15328 -0.555803 6.93007 1.66741C5.00285 3.59463 4.74627 6.55985 6.16032 8.76346L0.292861 14.6309C-0.0976645 15.0214 -0.0976646 15.6546 0.292859 16.0451C0.683384 16.4357 1.31655 16.4357 1.70707 16.0451L7.51947 10.2327C9.75058 11.9258 12.945 11.7544 14.981 9.71837C17.2042 7.49516 17.2042 3.89062 14.981 1.66741Z"
        fill="#C4C4C4"
      />
    </svg>
  </div>
</template>
<script>
export default {
  computed: {
    query() {
      return this.$store.getters['getSearchQuery']
    },

    loading() {
      return this.$store.getters['flightSearchAnim']
    },
  },

  mounted() {
    const query = this.$route.query.search ? this.$route.query.search : ''
    this.$store.dispatch('saveSearchQuery', query)
  },

  methods: {
    changeQuery(e) {
      const query = e.target.value
      this.$store.dispatch('saveSearchQuery', query)
    },

    search() {
      if (!this.query.length) {
        this.$refs.input.focus()
      }
      if (this.query.length) {
        location.href = `/flights?search=${this.query}`
      }
    },

    clear() {
      const input = this.$refs.input
      input.value = ''
      this.$store.dispatch('saveSearchQuery', '')
      location.href = '/flights'
    },
  },
}
</script>

<style lang="scss" scoped>
.ya-search {
  position: relative;
  transition: all 0.2s ease-in-out;
  svg {
    position: absolute;
    top: 8px;
    right: 10px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }
  input {
    transition: all 0.2s ease-in-out;
  }

  .my-loader {
    right: 10px;
    top: 8px;
  }
}
.search--active {
  transition: all 0.2s ease-in-out;
  input {
    border-color: #0984e3;
    padding-left: 25px;
    transition: all 0.2s ease-in-out;
  }
  svg path {
    fill: #0984e3;
    transition: all 0.2s ease-in-out;
  }
}
.ya-search__clear-btn {
  position: absolute;
  left: 10px;
  top: 6px;
}
</style>
