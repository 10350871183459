<template>
  <div>
    <div :class="['current-city', { active: visible }]" @click="visible = !visible">
      <span :class="{ current: currentCity !== 'Город' || userCity }">
        {{ userCity && currentCity === 'Город' ? userCity : currentCity }}
      </span>
      <img class="current-city__icon" src="/images/arrow-down.svg" alt="arrow" />
    </div>
    <ul class="cities-list" v-if="visible && cities.length">
      <li v-for="city in cities" :key="city" @click="getCity">
        <span>{{ city }}</span>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: 'single',
    },
    without: {
      type: String,
      default: '0',
    },
    id: {
      type: Number,
      default: 0,
    },
    cities: {
      type: Array,
      default: () => [],
    },
    userCity: {
      type: String,
      default: () => '',
    },
    flightId: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    order() {
      return this.$store.getters['order']
    },
  },

  data: () => ({
    visible: false,
    currentCity: 'Город',
  }),

  methods: {
    getCity(e) {
      const city = e.target.textContent
      this.currentCity = city
      this.visible = false
      if (this.order) {
        this.$store.dispatch('patchOrder', {
          flightId: this.flightId,
          type: this.type,
          without: this.without,
          id: this.id,
          params: { delivery_city: city },
        })
      } else {
        this.$emit('currentCity', city)
      }
    },
  },

  created() {
    window.addEventListener('click', (e) => {
      if (!this.$el.contains(e.target)) {
        this.visible = false
      }
    })
  },
}
</script>
