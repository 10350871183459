<template>
  <v-container fluid>
    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Предупреждение на главной странице</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field label="Заголовок" v-model="mainPageWarning.title"></v-text-field>
        <v-text-field label="Текст" v-model="mainPageWarning.description"></v-text-field>
        <v-checkbox label="Включено" v-model="mainPageWarning.visible"></v-checkbox>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="saveMainPageWarning()" color="info">Сохранить</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Преимущества</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-text-field
          v-for="(plus, index) in pluses"
          :key="index"
          :label="`Строка ${index + 1}`"
          @click:append-outer="removePlus(plus)"
          append-outer-icon="cancel"
          v-model="plus.plus"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="savePluses()" color="info">Сохранить</v-btn>
        <v-btn @click="addPlus()" color="success">Добавить</v-btn>
      </v-card-actions>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Популярные категории</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="info" outline @click="cancelPopularCategories">Отмена</v-btn>
        <v-btn color="info" @click="savePopularCategories" :loading="savingPopularCategories">Сохранить</v-btn>
      </v-toolbar>
      <v-card-text>
        <v-container fluid grid-list-md>
          <v-layout row wrap>
            <v-flex v-for="i in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]" :key="i" xs12 sm6 md4 lg3>
              <v-card>
                <div class="img-placeholder" v-if="!imgSrc[i]">
                  <v-btn fab dark color="grey" @click="openFileDialog(i)">
                    <v-icon dark>add</v-icon>
                  </v-btn>
                  <input v-show="false" :ref="`file-${i}`" type="file" @change="setCategoryImage(i, $event)" />
                </div>
                <v-img v-if="imgSrc[i]" :src="imgSrc[i]" height="150px"></v-img>
                <v-btn v-if="imgSrc[i]" flat icon color="red" @click="deleteCategoryImage(i)" class="img-del-button">
                  <v-icon>clear</v-icon>
                </v-btn>
                <v-card-actions>
                  <category-autocomplete v-model="selectedCategory[i]" />
                </v-card-actions>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-card-text>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Фоновые изображения</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-layout>
          <v-card class="mr-5">
            <v-card-title><h3 class="headline">Десктоп</h3></v-card-title>
            <v-card-text>
              <div class="slide" v-for="(slide, index) in desktopSlides" :key="index">
                <img :src="slide.slide_path" width="170" height="100" alt="" />
                <div class="controls-and-name">
                  <div class="name">{{ slide.name }}</div>
                  <div class="controls">
                    <span class="control" v-on:click="remove(slide, 'desktop')">
                      <i class="fa fa-times"></i>
                    </span>
                    <span class="control" v-show="index > 0" v-on:click="move(slide, 'desktop', index, index - 1)">
                      <i class="fa fa-arrow-up"></i>
                    </span>

                    <span
                      class="control"
                      v-show="index < desktopSlides.length - 1"
                      v-on:click="move(slide, 'desktop', index, index + 1)"
                    >
                      <i class="fa fa-arrow-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              <input
                type="file"
                ref="addFileDesktop"
                class="addFile"
                accept="image/*"
                v-on:change="upload($event, 'desktop')"
              />
            </v-card-text>
          </v-card>

          <v-card>
            <v-card-title><h3 class="headline">Мобильные</h3></v-card-title>
            <v-card-text>
              <div class="slide" v-for="(slide, index) in mobileSlides" :key="index">
                <img :src="slide.slide_path" width="66" height="100" alt="" />
                <div class="controls-and-name">
                  <div class="name">{{ slide.name }}</div>
                  <div class="controls">
                    <span class="control" v-on:click="remove(slide, 'mobile')">
                      <i class="fa fa-times"></i>
                    </span>
                    <span class="control" v-show="index > 0" v-on:click="move(slide, 'mobile', index, index - 1)">
                      <i class="fa fa-arrow-up"></i>
                    </span>

                    <span
                      class="control"
                      v-show="index < mobileSlides.length"
                      v-on:click="move(slide, 'mobile', index, index + 1)"
                    >
                      <i class="fa fa-arrow-down"></i>
                    </span>
                  </div>
                </div>
              </div>
              <input
                type="file"
                ref="addFileMobile"
                class="addFile"
                accept="image/*"
                v-on:change="upload($event, 'mobile')"
              />
            </v-card-text>
          </v-card>
        </v-layout>
      </v-card-text>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Найденные товары для добавления в хедер/главная</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
          @change="searchProduct()"
          label="Поиск"
          append-icon="search"
          v-model="searchProductField"
          single-line
          hide-details
        ></v-text-field>
      </v-toolbar>
      <v-data-table
        :loading="loading"
        :headers="headers_search"
        :items="productsShowForMain"
        :rows-per-page-items="[10, 15, 20]"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.vendor_code }}</td>
          <td>
            <img :src="props.item.photo" style="width: 70px" />
          </td>
          <td>
            <v-list class="transparent">
              <v-list-tile class="item_info">
                <v-list-tile-content>
                  <v-list-tile-title>
                    <a target="_blank" :href="'/catalog/product/' + props.item.vendor_code">
                      {{ props.item.name }}
                    </a>
                  </v-list-tile-title>
                  <v-list-tile-sub-title>Тип: {{ props.item.type }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>Цвет: {{ props.item.color }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>Длина: {{ props.item.length }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </td>
          <td>
            <v-flex d-flex justify-end>
              <v-icon @click="addToShowProduct(props.item, 'header')">add</v-icon>
            </v-flex>
          </td>
          <td>
            <v-flex d-flex justify-end>
              <v-icon @click="addToShowProduct(props.item, 'main')">add</v-icon>
            </v-flex>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Товары в хедере</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        class="box-shadow"
        :loading="loading"
        :headers="headers_show"
        :items="products_top"
        v-bind:pagination.sync="paginationSettings"
        :disable-pagination="true"
        :rows-per-page-items="[1000]"
        items-per-page="1000"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.vendor_code }}</td>
          <td>
            <img :src="props.item.photo" style="width: 70px" loading="lazy" />
          </td>
          <td>
            <v-list class="transparent">
              <v-list-tile class="item_info">
                <v-list-tile-content>
                  <v-list-tile-title>
                    <a target="_blank" :href="'/catalog/product/' + props.item.vendor_code">{{ props.item.name }}</a>
                  </v-list-tile-title>
                  <v-list-tile-sub-title>Тип: {{ props.item.type }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>Цвет: {{ props.item.color }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>Длина: {{ props.item.length }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </td>
          <td>{{ props.item.show_order }}</td>
          <td>
            <v-flex d-flex justify-end>
              <v-icon @click="moveItem(props.item, props.index, 'header', 'down', 'top')">fas fa-arrow-down</v-icon>
              <v-icon @click="removeFromShowProduct(props.item, 'header')">delete_forever</v-icon>
              <v-icon @click="moveItem(props.item, props.index, 'header', 'up', 'top')">fas fa-arrow-up</v-icon>
            </v-flex>
          </td>
        </template>
      </v-data-table>
    </v-card>

    <v-card class="mb-5">
      <v-toolbar flat>
        <v-toolbar-title>Товары на главной странице</v-toolbar-title>
      </v-toolbar>
      <v-data-table
        class="box-shadow"
        :loading="loading"
        :headers="headers_show"
        v-bind:pagination.sync="paginationSettings"
        :disable-pagination="true"
        :items="products_main"
        :rows-per-page-items="[1000]"
        items-per-page="1000"
      >
        <template slot="items" slot-scope="props">
          <td>{{ props.item.vendor_code }}</td>
          <td>
            <img :src="props.item.photo" style="width: 70px" loading="lazy" />
          </td>
          <td>
            <v-list class="transparent">
              <v-list-tile class="item_info">
                <v-list-tile-content>
                  <v-list-tile-title>
                    <a target="_blank" :href="'/catalog/product/' + props.item.vendor_code">
                      {{ props.item.name }}
                    </a>
                  </v-list-tile-title>
                  <v-list-tile-sub-title>Тип: {{ props.item.type }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>Цвет: {{ props.item.color }}</v-list-tile-sub-title>
                  <v-list-tile-sub-title>Длина: {{ props.item.length }}</v-list-tile-sub-title>
                </v-list-tile-content>
              </v-list-tile>
            </v-list>
          </td>
          <td>{{ props.item.show_order }}</td>
          <td>
            <v-flex d-flex justify-end>
              <v-icon @click="moveItem(props.item, props.index, 'main', 'down', 'main')">fas fa-arrow-down</v-icon>
              <v-icon @click="removeFromShowProduct(props.item, 'main')">delete_forever</v-icon>
              <v-icon @click="moveItem(props.item, props.index, 'main', 'up', 'main')">fas fa-arrow-up</v-icon>
            </v-flex>
          </td>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import CategoryAutocomplete from '@/components/inputs/CategoryAutocomplete'

export default {
  components: { CategoryAutocomplete },

  data: function () {
    return {
      loading: false,
      mainPageWarning: {},
      desktopSlides: [],
      mobileSlides: [],
      pluses: [],
      searchProductField: '',
      productsShowForMain: [],
      products_main: [],
      products_top: [],
      paginationSettings: {
        sortBy: 'show_order',
        descending: false,
        rowsPerPage: -1,
      },
      headers_search: [
        { text: 'Артикул', sortable: true, value: 'vendor_code' },
        { text: 'Фото', value: 'photo', sortable: false },
        { text: 'Описание', sortable: true, value: 'description' },
        {
          text: 'Добавить в хедер',
          align: 'center',
          value: 'delete_forever',
          sortable: true,
        },
        {
          text: 'Добавить на главную',
          align: 'center',
          value: 'delete_forever',
          sortable: true,
        },
      ],
      headers_show: [
        { text: 'Артикул', sortable: true, value: 'vendor_code' },
        { text: 'Фото', value: 'photo', sortable: false },
        { text: 'Описание', sortable: true, value: 'description' },
        { text: 'Порядок', sortable: true, value: 'show_order' },
        {
          text: 'Действие',
          align: 'center',
          value: 'delete_forever',
          sortable: true,
        },
      ],
      selectedCategory: [],
      imgSrc: [],
      imgFile: [],
      popularCategories: [],
      savingPopularCategories: false,
    }
  },

  mounted() {
    this.getMainPageWarning()
    this.getPluses()
    this.getPopularCategroies()
    this.getSlides('desktop')
    this.getSlides('mobile')

    this.refresh_top()
    this.refresh_main()
  },

  methods: {
    searchProduct() {
      this.axios
        .get('/api/products/search', {
          params: { search: this.searchProductField },
        })
        .then((response) => {
          this.productsShowForMain = response.data
        })
    },

    removeFromShowProduct(item, place) {
      this.axios
        .post('/api/products/delete-from-main', {
          vendor_code: item.vendor_code,
          place: place,
        })
        .then(() => {
          this.refresh()
        })
    },

    moveItem(item, currentIndex, place, direction, type) {
      let newIndex
      let that = this
      let collection = that['products_' + type]
      let pairedItem
      let pairedItemIndex
      let pairedItemNewIndex

      if (currentIndex == 0 && direction == 'top') {
        return
      }

      if (currentIndex == collection.count && direction == 'down') {
        return
      }

      switch (direction) {
        case 'up': {
          pairedItemIndex = currentIndex - 1
          pairedItem = collection[pairedItemIndex]
          pairedItemNewIndex = pairedItemIndex + 1
          newIndex = currentIndex - 1
          break
        }
        case 'down': {
          pairedItemIndex = currentIndex + 1
          pairedItem = collection[currentIndex + 1]
          pairedItemNewIndex = pairedItemIndex - 1
          newIndex = currentIndex + 1
          break
        }
        default: {
          console.error('wrong new index submitted')
          return false
        }
      }

      if (!pairedItem) {
        return
      }

      this.axios
        .post('/api/products/show/order', {
          place: place,
          vendor_code: item.vendor_code,
          paired_vendor_code: pairedItem.vendor_code,
          show_order: newIndex,
          paired_show_order: pairedItemNewIndex,
        })
        .then(() => {
          if (type == 'top') {
            that.refresh_top()
          }

          if (type == 'main') {
            that.refresh_main()
          }
        })
    },

    refresh_top() {
      this.axios.get('/api/products/get-main', { params: { place: 'header' } }).then((response) => {
        this.products_top = response.data
      })
    },

    refresh_main() {
      this.axios.get('/api/products/get-main', { params: { place: 'main' } }).then((response) => {
        this.products_main = response.data
      })
    },

    getMainPageWarning() {
      this.axios.get('/api/admin/warning/get').then((response) => {
        this.mainPageWarning = response.data
      })
    },

    saveMainPageWarning() {
      this.axios.post('/api/admin/warning/save', this.mainPageWarning).then((response) => {
        this.mainPageWarning = response.data
      })
    },

    getPluses() {
      this.axios.get('/api/admin/pluses/list').then((response) => {
        this.pluses = response.data
      })
    },

    async getPopularCategroies() {
      const response = await this.axios.get('/api/admin/categories/popular')
      this.popularCategories = response.data.data
      this.setDefaultPopularCategories()
    },

    setDefaultPopularCategories() {
      for (let i = 0; i <= 9 && i < this.popularCategories.length; i++) {
        this.$set(this.selectedCategory, i, this.popularCategories[i].cat_id)
        this.$set(this.imgSrc, i, this.popularCategories[i].image_url)
      }
    },

    removePlus(plus) {
      if (plus.id && !window.confirm('Вы уверены?')) {
        return
      }

      if (plus.id) {
        this.axios.post('/api/admin/pluses/delete', { id: plus.id }).then(() => {
          this.getPluses()
        })
      } else {
        this.getPluses()
      }
    },

    addPlus() {
      this.pluses.push({
        plus: '',
        position: 100,
      })
    },

    savePluses() {
      this.axios
        .post('/api/admin/pluses/save', {
          pluses: this.pluses,
        })
        .then(() => {
          this.getPluses()
        })
    },

    getSlides(type) {
      this.axios.post('/api/admin/slides/list', { type: type }).then((response) => {
        this[type + 'Slides'] = response.data
      })
    },

    remove(slide, type) {
      if (!window.confirm('Вы уверены?')) {
        return
      }

      this.axios.post('/api/admin/slides/delete', { id: slide.id }).then(() => {
        this.getSlides(type)
      })
    },

    move(slide, type, index, newIndex) {
      let pairedSlide

      pairedSlide = this[type + 'Slides'][newIndex]

      this.axios
        .post('/api/admin/slides/move', {
          id: slide.id,
          position: newIndex,
          paired_id: pairedSlide.id,
          paired_position: index,
        })
        .then(() => {
          this.getSlides(type)
        })
    },

    upload(event, type) {
      let file = event.target.files[0]

      let formData = new FormData()
      formData.append('file', file)
      formData.append('type', type)

      this.axios
        .post('/api/admin/slides/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(() => {
          this.getSlides(type)
          this.$refs.addFileDesktop.files = null
          this.$refs.addFileMobile.files = null
        })
    },

    openFileDialog(i) {
      this.$refs[`file-${i}`][0].click()
    },

    setCategoryImage(i, event) {
      this.$set(this.imgFile, i, event.target.files[0])
      this.$set(this.imgSrc, i, URL.createObjectURL(event.target.files[0]))
    },

    deleteCategoryImage(i) {
      this.$set(this.imgFile, i, null)
      this.$set(this.imgSrc, i, null)
    },

    async savePopularCategories() {
      this.savingPopularCategories = true
      for (let i = 0; i <= 9; i++) {
        if (this.selectedCategory[i]) {
          const formData = new FormData()
          if (this.popularCategories[i]) {
            formData.append('category', this.selectedCategory[i])
            if (this.imgFile[i]) {
              formData.append('image', this.imgFile[i], this.imgFile[i].name)
            }
            await this.axios.post(`/api/admin/categories/popular/${this.popularCategories[i].id}`, formData)
          } else {
            if (this.selectedCategory[i] && this.imgFile[i]) {
              formData.append('category', this.selectedCategory[i])
              formData.append('image', this.imgFile[i], this.imgFile[i].name)
              await this.axios.post('/api/admin/categories/popular', formData)
            }
          }
        }
      }
      this.getPopularCategroies()
      this.savingPopularCategories = false
    },

    cancelPopularCategories() {
      this.selectedCategory = []
      this.imgFile = []
      this.imgSrc = []
      this.setDefaultPopularCategories()
    },
  },
}
</script>

<style scoped>
.img-placeholder {
  display: flex;
  flex-direction: column;
  height: 150px;
  align-items: center;
  justify-content: center;
}
.img-del-button {
  position: absolute !important;
  top: 0;
  right: 0;
  z-index: 1;
}
</style>
