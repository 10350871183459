import Vue from 'vue'

const state = () => ({
  flight_id: 0,
  waybills: [],
  wayLoader: false,
  waybillOrders: [],
  allOrders: [],
})

const getters = {
  waybillsData: (state) => state.waybills,

  wayLoader: (state) => state.wayLoader,

  waybillOrders: (state) => state.waybillOrders,

  allOrders: (state) => state.allOrders,
}

const actions = {
  async getWaybills({ commit }, id) {
    const link = `/api/admin/waybills?flight_id=${id}`

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_WAYBILLS', res.data.data)
      })
      .catch((err) => console.log(err.message))
  },

  // todo {}
  async swapOrders(data) {
    const first = data.first
    const second = data.second
    const link = `/api/admin/waybills/orders/swap?first_id=${first.id}&first_index=${first.index}&second_id=${second.id}&second_index=${second.index}`

    await Vue.axios
      .patch(link, data)
      .then((res) => console.log(res.data))
      .catch((err) => console.log(err))
  },

  async createWaybill({ dispatch, state, commit }, params) {
    commit('TOGGLE_LOADER', true)
    const link = `/api/admin/waybills/create`

    await Vue.axios
      .post(link, params)
      .then(() => {
        dispatch('getWaybills', state.flight_id)
        commit('TOGGLE_LOADER', false)
      })
      .catch((err) => {
        console.log(err.message)
        commit('TOGGLE_LOADER', false)
      })
  },

  async updateWaybill({ dispatch }, data) {
    const link = `/api/admin/waybills/update?id=${data.id}&${data.query}=${data.value}`
    try {
      await Vue.axios.patch(link)
      dispatch('getWaybills', data.flight_id)
    } catch (err) {
      console.log(err.message)
    }
  },

  // todo { dispatch }
  async orderSetColor(data) {
    const link = `/api/admin/orders/${data.id}?${data.query}=${data.value}`
    await Vue.axios.patch(link).catch((err) => console.log(err))
  },

  async destroyWaybill({ dispatch, state }, data) {
    const link = `/api/admin/waybills/delete?id=${data.id}`
    const orders = state.waybillOrders

    for await (const order of orders) {
      const statuses = `/api/admin/orders/${order.order_id}/status?status=2`
      await Vue.axios.patch(statuses).catch((err) => console.log(err))
    }

    await Vue.axios
      .delete(link)
      .then(() => dispatch('getWaybills', data.flight_id))
      .catch((err) => console.log(err))
  },

  async destroyOrder({ dispatch }, data) {
    const link = `/api/admin/waybills/order/${data.product_id}/delete`

    await Vue.axios
      .delete(link)
      .then(() => {
        dispatch('getWaybills', data.flight_id)
        dispatch('getWaybillOrders', data.waybill_id)
      })
      .catch((err) => console.log(err))
  },

  async waybillOrderUpdate({ dispatch }, data) {
    const link = `/api/admin/waybills/order/update?id=${data.id}&${data.query}=${data.value}`

    await Vue.axios
      .patch(link)
      .then(() => {
        dispatch('getWaybillOrders', data.waybill_id)
      })
      .catch((err) => console.log(err.message))
  },

  clearWaybillOrders({ commit }) {
    commit('CLEAR_WAYBILL_ORDERS')
  },

  async getWaybillOrders({ commit }, waybillId) {
    const link = `/api/admin/waybills/${waybillId}`

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_WAYBILL_ORDERS', res.data.data.orders || [])
      })
      .catch((err) => console.log(err.message))
  },

  async getWaybillAllOrders({ commit }, flight_id) {
    const link = `/api/admin/flights/${flight_id}/orders?withCountableFields=1&orderByDesc=1&statuses[]=4&statuses[]=2`

    await Vue.axios
      .get(link)
      .then((res) => {
        commit('SAVE_ALL_ORDERS', res.data.data)
      })
      .catch((err) => console.log(err))
  },

  saveFlightId({ commit }, id) {
    commit('SAVE_FLIGHT_ID', id)
  },
}

const mutations = {
  SAVE_WAYBILL_ORDERS: (state, orders) => {
    state.waybillOrders = orders
  },

  SAVE_ALL_ORDERS: (state, orders) => {
    state.allOrders = orders
  },

  SAVE_WAYBILLS: (state, data) => {
    state.waybills = data
  },

  SAVE_FLIGHT_ID: (state, id) => {
    state.flight_id = id
  },

  TOGGLE_LOADER: (state, status) => {
    state.wayLoader = status
  },

  CLEAR_WAYBILL_ORDERS: (state) => {
    state.waybillOrders = []
  },

  SWAP_ORDERS: (state, swapped) => {
    state.waybillOrders = swapped
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
}
