export const CLICKHOUSE_WEBSITE_URL = process.env.VUE_APP_MIX_FRONTEND_HOST
export const API_URL = process.env.VUE_APP_MIX_SERVER_API_HOST
export const TEL_CODE_BY = '+375'

export const CHARGE_TYPE = [
  { text: 'fix', value: 0 },
  { text: '%', value: 1 },
]

export const CURRENCIES = {
  RUB: { name: 'RUB', description: 'Рос. рубль', value: 0 },
  BYN: { name: 'BYN', description: 'Бел. рубль', value: 1 },
  PLN: { name: 'PLN', description: 'Польский злотый', value: 2 },
}

export const CURRENCIES_SELECT = []

for (const v of Object.values(CURRENCIES)) {
  CURRENCIES_SELECT.push({
    text: v.name,
    value: v.value,
  })
}

export const EXCHANGE_RATE_TYPE = [
  { text: 'Внутренний', value: 0 },
  { text: 'НБ РБ', value: 1 },
]
