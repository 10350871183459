<template>
  <div class="ya-table__element">
    <div :class="['ya-table__header', { reserve: type === 'reserve' }]">
      <div style="flex: 65%" class="ya-table__header-col" ref="element" @click="getOrders">
        <img :class="['ya-table__arrow', { active: search }]" src="/images/arrow-down.svg" alt="arrow" />
        <h2 class="ya-table__title" v-if="flight.is_reserve">Резерв</h2>
        <h2 class="ya-table__title" v-else>Рейс №{{ flight.id }}</h2>
        <span class="ya-table__date" v-if="!flight.is_reserve">{{ flight.date }}</span>
        <span class="ya-table__status" v-if="!flight.is_reserve">
          <template v-if="flight.status === 0">(Ожидающий)</template>
          <template v-if="flight.status === 1">(Открытый)</template>
          <template v-if="flight.status === 2">(Закрытый)</template>
        </span>
      </div>
      <div class="ya-table__header-col">
        <h4 class="ya-table__price">{{ splitPrice(flight.cost_price || 0) }} BYN</h4>
        <v-btn
          v-if="flight.status === 1"
          color="error"
          :disabled="flight.is_reserve === 2"
          @click="setFlightStatus(flight.id, 0)"
        >
          Остановить приём
        </v-btn>
        <v-btn v-if="flight.status === 0" color="warning" @click="setFlightStatus(flight.id, 2)">Закрыть рейс</v-btn>
        <v-btn v-if="flight.status === 2" color="success" @click="setFlightStatus(flight.id, 1)"
          >Возобновить приём</v-btn
        >
      </div>
    </div>
    <div :class="['ya-table__grid', { active: searchProp }]">
      <div class="ya-table__item">
        <h3>Кол-во заказов</h3>
        <p class="flight-info-p">
          <span>{{ flight.orders_count_accepted || 0 }}</span> /
          <span>{{ flight.orders_count_processing || 0 }}</span> /
          <span>{{ flight.orders_count_rejected || 0 }}</span> /
          <span>{{ flight.orders_count_in_waybill || 0 }}</span> /
          <span>{{ flight.orders_count_completed || 0 }}</span>
        </p>
      </div>
      <div class="ya-table__item">
        <div>
          <h3>Себестоимость товаров</h3>
          <p>{{ splitPrice(flight.cost_price || 0) }} BYN</p>
        </div>
      </div>
      <div class="ya-table__item">
        <div>
          <h3>Из них в наличии</h3>
          <p>{{ splitPrice(flight.cost_price_availible || 0) }} BYN</p>
        </div>
      </div>
      <div class="ya-table__item">
        <h3>Средняя комиссия</h3>
        <p>{{ flight.average_commission ? flight.average_commission.toFixed(2) : 0 }}%</p>
      </div>
      <div class="ya-table__item">
        <h3>Отмененных товаров</h3>
        <p>{{ splitPrice(flight.cost_price_cancelled || 0 + '') }} BYN</p>
      </div>
      <div class="ya-table__item">
        <h3>Наценка</h3>
        <p>{{ splitPrice(flight.charge || 0 + '') }} BYN</p>
      </div>
      <div class="ya-table__item">
        <div>
          <span class="ya-table__ref" @click="openModal('cities')">Населенные пункты</span>
        </div>
        <div>
          <span class="ya-table__ref" @click="openModal('suppliers')">Бренды</span>
        </div>
      </div>
    </div>
    <div :class="['ya-table__bottom', { active: searchProp }]">
      <div class="ya-container">
        <div class="ya-table__bottom-wrap">
          <div class="row">
            <TableSearch :flightId="parseInt(flight.id)" @searchByFlight="setSearchStatus" />
            <TableFilter :element="flight" :flightId="flight.id"></TableFilter>
          </div>
          <div class="row">
            <button class="button outlined routes" v-if="!flight.is_reserve" @click="createRoutes(flight.id)">
              Маршруты
            </button>
            <button class="button outlined" @click="createExcel(flight.id)">Создать список</button>
            <button class="button primary" @click="createOrder(flight.id)">Создать заказ</button>
          </div>
        </div>
      </div>
    </div>
    <div :class="['voyage-table', { active: searchProp }]">
      <VoyageTable
        :reserve="flight.is_reserve"
        :flightId="parseInt(flight.id)"
        :table="flightData"
        :query="query"
        :search="searchProp"
        v-if="flightData"
      />
    </div>
    <Modal v-if="visibleModal && modalType === 'cities'" @closePopup="closeModal">
      <div class="modal-cities">
        <span v-for="city in flight.orders_localities" :key="city"> {{ city }}, </span>
      </div>
    </Modal>
    <Modal v-if="visibleModal && modalType === 'suppliers'" @closePopup="closeModal">
      <div class="modal-supplier">
        <span v-for="supplier in flight.orders_suppliers" :key="supplier"> {{ supplier }}, </span>
      </div>
    </Modal>
    <div class="blocker" v-if="visibleModal" @click="closeModal"></div>
  </div>
</template>

<script>
import TableSearch from '@/components/table/TableSearch.vue'
import TableFilter from '@/components/table/TableFilter.vue'
import VoyageTable from '@/components/table/VoyageTable.vue'
import Modal from '@/components/tools/Modal.vue'

export default {
  components: {
    TableSearch,
    TableFilter,
    VoyageTable,
    Modal,
  },

  props: {
    openFlight: {
      type: Number,
      default: 0,
    },

    searchProp: {
      type: Boolean,
      default: false,
    },

    flight: {
      type: Object,
      default: () => {},
    },

    type: {
      type: String,
      default: () => 'voyage',
    },

    query: {
      type: String,
      default: '',
    },
  },

  computed: {
    flightData() {
      if (!this.searchProp) {
        return this.$store.getters['flightOrders']
      } else {
        return { data: this.flight.orders }
      }
    },

    flightFilters() {
      return this.$store.getters['flightFilters']
    },
  },

  data: () => ({
    visible: false,
    visibleModal: false,
    disabled: false,
    modalType: 'filter',
    search: false,
  }),

  mounted() {
    if (this.flight.id === this.openFlight) {
      this.getOrders()
    }
  },

  methods: {
    // получаем заказы рейса
    getOrders() {
      if (this.searchProp) return
      this.$emit('getFlightOrders', this.flight.id)
      this.toggle()
    },

    createRoutes(flightId) {
      window.open(`/waybills/${flightId}`, '_blank')
    },

    setSearchStatus(status) {
      this.search = status
    },

    setFlightStatus(flightId, status) {
      this.axios
        .patch(`/api/admin/flights/${flightId}/status?status=${status}`)
        .then(() => {
          if (!this.searchProp) {
            this.$store.dispatch('getFlights')
          } else {
            this.$store.dispatch('getSearchQuery', this.query)
          }
        })
        .catch((err) => console.log(err.message))
    },

    createOrder(flightId) {
      window.open(`/orders_create/${flightId}`, '_blank')
    },

    createExcel(flightId) {
      const filters = this.flightFilters

      let params = ''

      if (filters.byDiapasons.boolean) {
        filters.byDiapasons.data.forEach((diapason, i) => {
          params += `&ranges[${i}][0]=${diapason.first}&ranges[${i}][1]=${diapason.second}`
        })
      }

      if (filters.byStatus.boolean) {
        filters.byStatus.data.forEach((status) => {
          params += `&statuses[]=${status}`
        })
      }

      if (filters.byNumbers.boolean) {
        filters.byNumbers.data.forEach((num, index) => {
          params += `&numbers[${index}]=${num.trim()}`
        })
      }

      if (params) {
        params = '?' + params.substring(1)
      }

      window.open(`${this.axios.defaults.baseURL}/api/admin/flights/${flightId}/products/excel${params}`, '_blank')
    },

    openModal(type) {
      this.visibleModal = true
      this.modalType = type
    },

    closeModal() {
      this.visibleModal = false
    },

    closeAll(not) {
      const arrow = document.querySelectorAll('.ya-table__arrow')
      const grid = document.querySelectorAll('.ya-table__grid')
      const bottom = document.querySelectorAll('.ya-table__bottom')
      const table = document.querySelectorAll('.voyage-table')

      arrow.forEach((el) => {
        if (el !== not[0]) {
          el.classList.remove('active')
        }
      })

      grid.forEach((el) => {
        if (el !== not[1]) {
          el.classList.remove('active')
        }
      })

      bottom.forEach((el) => {
        if (el !== not[2]) {
          el.classList.remove('active')
        }
      })

      table.forEach((el) => {
        if (el !== not[3]) {
          el.classList.remove('active')
        }
      })
    },

    toggle() {
      const parent = this.$refs.element.parentElement.parentElement
      const arrow = parent.querySelector('.ya-table__arrow')
      const grid = parent.querySelector('.ya-table__grid')
      const bottom = parent.querySelector('.ya-table__bottom')
      const table = parent.querySelector('.voyage-table')

      this.closeAll([arrow, grid, bottom, table])

      if (arrow.classList[1] !== 'active') {
        arrow.classList.add('active')
        grid.classList.add('active')
        bottom.classList.add('active')
        table.classList.add('active')
        return
      } else {
        arrow.classList.remove('active')
        grid.classList.remove('active')
        bottom.classList.remove('active')
        table.classList.remove('active')
      }
    },

    splitPrice(x) {
      x = x + ''
      if (typeof x === 'string') {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      } else {
        return x
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.row {
  margin: 0 !important;
}
.routes {
  border-color: #04b200;
  color: #04b200;
  transition: all 0.2s ease-in-out;
  &:hover {
    transition: all 0.2s ease-in-out;
    border-color: #038000;
    color: #038000;
  }
}
.flight-info-p {
  span {
    &:first-child {
      color: #04b200;
    }
    &:nth-child(2) {
      color: #0984e3;
    }
    &:nth-child(3) {
      color: #ff4544;
    }
    &:nth-child(4) {
      color: #f39c12;
    }
    &:nth-child(5) {
      color: #95a5a6;
    }
  }
}
</style>
